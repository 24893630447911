import * as React from "react";
import * as Api from "../api/api";
import * as Colors from "../styles/colors";
import { connect } from "react-redux";
import * as ContactBoxStore from "../store/ContactBox";
import SelectionFeedback from "./SelectionFeedBack";
import { ApplicationState } from "../store";
import { getText } from "../utils/langTexts";
import ExpansionPanel from "./ExpansionPanel";

type ContactBoxProps = ContactBoxOwnProps
    & ContactBoxStore.ContactBoxState
    & typeof ContactBoxStore.actionCreators;

interface ContactBoxOwnProps {
    client: Api.ClientModel;
}

class ContactBox extends React.Component<ContactBoxProps, {}> {

    get subOkargo(): Api.SubscriptionModel {
        return this.props.client
            ? this.props.client.subscriptions
                .find(x => x.subscriptionType === "Okargo")
            : null;
    }

    get contactEmail(): string {
        let subOkargo = this.subOkargo;
        if (subOkargo) {
            return subOkargo.agency.okargoEmail
                || subOkargo.agency.company.okargoEmail
                || (subOkargo.agency.company.companyGroup && subOkargo.agency.company.companyGroup.okargoEmail)
                || "support@okargo.freshdesk.com";
        }

        return "support@okargo.freshdesk.com";
    }

    get contactPhone(): string {
        let subOkargo = this.subOkargo;
        if (subOkargo) {
            return subOkargo.agency.okargoPhone
                || subOkargo.agency.company.okargoPhone
                || (subOkargo.agency.company.companyGroup && subOkargo.agency.company.companyGroup.okargoPhone)
                || "+33977555326";
        }

        return "+33977555326";
    }

    public render() {
        return (<div style={{
            zIndex: 99998,
            position: "fixed",
            bottom: 0,
            right: 100,
            width: 300
        }}>
            
            <ExpansionPanel
                onChange={(v) => { }}
                style={{ borderRadius: 10 }}
                head={<div onClick={(e) => {
                    if (this.props.isOpen) {
                        this.props.closeContactBox();
                    } else {
                        this.props.openContactBox();
                    }
                }}
                    style={{
                        color: Colors.baseColors.lightBlue,
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        padding: "5px 10px",
                        cursor: "pointer",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        borderTop: `1px solid ${Colors.baseColors.darkGray}`,
                        borderRight: `1px solid ${Colors.baseColors.darkGray}`,
                        borderLeft: `1px solid ${Colors.baseColors.darkGray}`,
                    }}>
                    <div style={{ flex: 1, fontSize: 16, fontWeight: "bold", padding: "0px 6px" }}>
                        {getText("GenContactUs")}
                    </div>
                    <div style={{ flex: "0 0 auto", fontSize: 16 }}>
                        {this.props.isOpen && <i className="glyphicon glyphicon-chevron-down"></i>}
                        {!this.props.isOpen && <i className="glyphicon glyphicon-chevron-up"></i>}
                    </div>
                </div>}
                expanded={this.props.isOpen}>
                <div style={{
                    padding: 6,
                    backgroundColor: "white",
                    color: Colors.baseColors.darkGray,
                    borderRight: `1px solid ${Colors.baseColors.darkGray}`,
                    borderLeft: `1px solid ${Colors.baseColors.darkGray}`,
                }}>
                    <div style={{
                        borderRadius: 5, padding: "5px 15px"
                    }}>
                        {getText("GenContactUsAt")} <br />
                        {this.contactEmail} <br />
                        {this.contactPhone}
                        {/*this.props.feedback.isOpen && <div>{getText("GenContactUseForm")}</div>*/}
                    </div>
                    <div>
                        <SelectionFeedback />
                    </div>
                </div>
            </ExpansionPanel>
        </div>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.contactBox,
    client: state.account.currentUser
        ? state.account.currentUser.clientModel
        : null
} as any), ContactBoxStore.actionCreators)(ContactBox) as any