import * as React from 'react';
import * as Api from '../api/api';
import Moment from 'moment';
import * as SelectionState from '../store/Selection';
import OfferPrice from './OfferPrice';
import { OfferScope } from './OfferScope';
import OfferRatesName from './OfferRatesName';
import OfferContacts from './OfferContacts';
import DialogModal from './DialogModal';
import Button from './Button';
import { RatesCalculator } from '../utils/ratesUtils';
import { getCarrierOfferKey, getLocationName } from '../utils/utils';
import { chargeAgentsToMoficiations } from '../utils/mapping';
import * as Colors from '../styles/colors';
import { getText } from '../utils/langTexts';
import { frtId } from '../utils/constants';
import { getRouteConfig, getSelectedDepart } from '../utils/routeUtils';
import * as _ from 'lodash';
import Linkify from "react-linkify";
import { ChargeListDisplay } from './ChargeListDisplay';
import RouteServiceSelect from './RouteServiceSelect';
import { ArrowRight, Remove, Add, ArrowDropDown, ArrowDropUp, AttachMoney, Phone, Warning } from '@material-ui/icons';
import ExpensionPanel from './ExpansionPanel';
import { IconButton } from '@material-ui/core';
import Tooltip from "./Tooltip";
import { canUseQuotation } from '../security/userRights';

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20
};

const labelStyle: React.CSSProperties = {
    minWidth: 200
    , textAlign: "right"
    , marginRight: 5
    , fontWeight: 700
}
const ratesTitleStyle: React.CSSProperties = {
    fontWeight: 700
}

const ratesDetailsField: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 16
}

const ratesDetailsLabel: React.CSSProperties = {
    textAlign: "right",
    marginRight: 6,
    fontWeight: 700,
    minWidth: 200
}

const ratesDetailsValue: React.CSSProperties = {
    marginLeft: 30
}

interface CarrierOfferProps {
    criteriaType: Api.CriteriaModelTypeEnum;
    className?: string;
    currencyId: number;
    carrierOfferFocused: string;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    currencies: { [id: number]: Api.CurrencyModel };
    chargeNames: { [id: number]: Api.ChargeNameModel };
    carriers: { [id: number]: Api.CarrierModel };
    markets: { [id: number]: Api.MarketModel };
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
    carrierOffer: Api.CarrierOfferModel;
    carrierOfferState: SelectionState.CarrierOfferState;
    criteriaModel: Api.CriteriaModel;
    criteriaLoaded: Api.CriteriaModel;
    subscription: Api.SubscriptionModel;
    sellingRates: Array<Api.SellingRatesModel>;
    selectDepart: (departId: number) => void;
    selectService: (routeServiceId: number) => void;
    toggleDetails: (value: boolean) => void;
    toggleSizeTypeDetails: (value: boolean, sizeTypeId: number) => void;
    toggleDocumentInfoDetails: (value: boolean, documentInfoId: number) => void;
    toggleBlDetails: (value: boolean) => void;
    toggleLclDetails: (value: boolean) => void;
    toggleRatesDetails: (value: boolean) => void;
    toggleRatesOfferDescription: (value: boolean) => void;
    toggleContacts: (value: boolean) => void;
    openAskBooking: () => void;
    openAskRates: () => void;
    openTransferData: () => void;
    goToQuotation: () => void;
    requestSendAgentQuotation: () => void;
    requestDownloadRatesDocument: () => void;
    requestGoToCarrierWebSite: () => void;
}

interface CarrierOfferState {
    isLogoVisible: boolean;
}

export default class CarrierOffer extends React.Component<CarrierOfferProps, CarrierOfferState> {

    private ratesCalculator: RatesCalculator;

    constructor(props: CarrierOfferProps) {
        super(props);
        this.ratesCalculator = new RatesCalculator(this.props.currencies, this.props.sizeTypes, this.props.criteriaModel);
        this.state = {
            isLogoVisible: true
        };
    }

    componentWillReceiveProps(nextProps: CarrierOfferProps) {
        this.ratesCalculator = new RatesCalculator(this.props.currencies, this.props.sizeTypes, this.props.criteriaModel);
    }

    get departs(): Array<Api.DepartModel> {
        return (this.props.carrierOffer.chargeSet
        && this.props.carrierOffer.chargeSet.departs
        && this.props.carrierOffer.chargeSet.departs.length
            ? this.props.carrierOffer.chargeSet.departs
            : getRouteConfig(this.props.carrierOffer, this.props.carrierOfferState)?.departs) || [];
    }

   formatTransshipment(transshipment: Api.TransshipmentModel): string {

       if (transshipment == null) {
           return null;
       }
       var ret = `${transshipment.port?.name}`;
       if(transshipment.depart?.vessel != null)
       {
           ret = `${ret} - ${(transshipment.depart?.service  != null ? `(${transshipment.depart.service}) ` : "")  } ${transshipment.depart?.vessel?.name} ${(transshipment.depart?.uid != null ? `(${ transshipment.depart.uid })` : "")  }`;
       }
       return  `${ret}`;
    }


    showEta(dp: Api.DepartModel, transitTime:number): string {

        if (dp.eta == dp.etd) {    
           return Moment(dp.eta).add(transitTime, 'days').format('DD/MM/YYYY');
        }
        return Moment(dp.eta).format('DD/MM/YYYY');
    }

    get selectedDepart(): Api.DepartModel {
        return getSelectedDepart(this.props.carrierOffer, this.props.carrierOfferState);
    }

    get currency(): Api.CurrencyModel {
        return this.props.currencies[this.props.currencyId];
    }

    get transferDisabled(): boolean {
        return this.props.subscription.subscriptionType !== null //skedules for now
            && ((this.props.carrierOffer.chargeSet
            && this.props.carrierOffer.chargeSet.invalidated)
                || (this.props.carrierOfferState && this.props.carrierOfferState.isLoading))
    }

    get allDetailsOpened(): boolean {
        if (this.props.criteriaType === "Fcl") {
            return this.props.carrierOfferState
                && this.props.carrierOfferState.blDetailsOpened
                && this.props.carrierOfferState.ratesDetailsOpened
                && !this.props.criteriaModel.criteriaSizeTypes
                    .filter(cst => cst.number > 0
                        && this.props.criteriaType === "Fcl"
                        && (!this.props.carrierOffer.chargeSet
                            || this.ratesCalculator.findChargesToApply(this.props.carrierOffer.chargeSet,
                                cst,
                                null).some(x => x.chargeNameId === frtId)))
                    .some(cst => !this.props.carrierOfferState.sizeTypeDetailsOpened[cst.sizeTypeId]);
        } else if (this.props.criteriaType === "Lcl") {
            return this.props.carrierOfferState
                && this.props.carrierOfferState.blDetailsOpened
                && this.props.carrierOfferState.lclDetailsOpened
                && this.props.carrierOfferState.ratesDetailsOpened;
        }
       
    }

    handleGoToQuotation() {
        if (this.props.subscription.subscriptionType === "Agent") {
            this.props.requestSendAgentQuotation();
        } else {
            this.props.goToQuotation();
        }
    }

    getSellingRatesBySizeTypeId(id: number): Api.SellingRatesValueModel {
        let frt = this.getFrtBySizeTypeId(id);
        if (!frt
            || !this.props.criteriaModel.includeSellingRates
            || (this.props.carrierOffer.ratesOffer
                && this.props.carrierOffer.ratesOffer.type === 'Special'
                && !this.props.carrierOffer.chargeSet.ratesFetchedRecordId))
            return undefined;

        return this.props.sellingRates.length
            ? this.props.sellingRates[0].sellingRatesValues
                .find(x => x.currencyId === frt.currencyId
                    && (x.sizeTypeId === id || !x.sizeTypeId))
            : undefined;
    }

    getFrtBySizeTypeId(id: number): Api.ChargeModel {
        return this.props.carrierOffer.chargeSet
            ? this.props.carrierOffer.chargeSet.charges
                .find(x => x.chargeNameId === frtId && (x.sizeTypeId === id || !x.sizeTypeId))
            : undefined;
    }

    public render() {
        let routeConfig = getRouteConfig(this.props.carrierOffer, this.props.carrierOfferState);


        let isPolDoor = this.props.carrierOffer.chargeSet
            && (this.props.carrierOffer.chargeSet.flags.some(x => x == "PolDoor"));
        let isPodDoor = this.props.carrierOffer.chargeSet
            && (this.props.carrierOffer.chargeSet.flags.some(x => x == "PodDoor"));

        return <div id={getCarrierOfferKey(this.props.carrierOffer)}
            style={{ display: "flex", flexDirection: "column", alignItems: "stretch", minWidth: 1170 }}>
            <div style={{
                flex: "0 0 auto",
                height: 64,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderTop: "1px solid " + Colors.baseColors.darkGray,
                borderRight: "1px solid " + Colors.baseColors.darkGray,
                borderBottom: "1px solid " + Colors.baseColors.darkGray,
                borderLeft: "1px solid " + Colors.baseColors.darkGray,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                padding: "0px 20px",
                ...(this.props.carrierOfferFocused === getCarrierOfferKey(this.props.carrierOffer)
                    ? { backgroundColor: Colors.baseColors.lightBlue, color: "white" }
                    : {}),
                ...(this.props.carrierOfferState.detailsOpened
                    ? {
                        borderBottom: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0
                    }
                    : {})
            }}>
                <div style={{ flex: 1 }}>
                    {this.props.carrierOffer.chargeSet
                        && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ flex: "0 0 auto", minWidth: 75 }}>
                                <OfferPrice
                                    style={{}}
                                    inline={false}
                                    subscriptionType={this.props.subscription.subscriptionType}
                                    sellingRates={this.props.sellingRates}
                                    carrierOffer={this.props.carrierOffer}
                                    criteriaModel={this.props.criteriaModel}
                                    currencies={this.props.currencies}
                                    sizeTypes={this.props.sizeTypes}
                                    currencyId={this.props.currencyId}>
                                </OfferPrice>
                            </div>
                            <div style={{ flex: "0 0 auto" }}>
                                <OfferScope carrierOffer={this.props.carrierOffer}
                                    iconStyle={this.props.carrierOfferFocused === getCarrierOfferKey(this.props.carrierOffer) ? { color: "white" } : {}}
                                    criteria={this.props.criteriaModel}
                                    carrierRatesPlatforms={ this.props.carrierRatesPlatforms}
                                ></OfferScope>
                            </div>
                        </div>}
                </div>
                <div style={{ flex: 1, fontWeight: "bold", textAlign: "center" }}>
                    {this.props.carriers[this.props.carrierOffer.carrierId].name}
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {this.props.carrierOffer.chargeSet && <OfferRatesName
                            iconStyle={this.props.carrierOfferFocused === getCarrierOfferKey(this.props.carrierOffer) ? { color: "white" } : {}}
                            focused ={this.props.carrierOfferFocused === getCarrierOfferKey(this.props.carrierOffer)}
                            criteria={this.props.criteriaModel}
                            chargeSet={this.props.carrierOffer.chargeSet}
                            ratesOffer={this.props.carrierOffer.ratesOffer}
                            offerInformations={this.props.carrierOffer.offerInformations}
                            subscriptionType={this.props.subscription.subscriptionType}
                            />}
                    </div>
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                    {getLocationName(this.props.carrierOffer.originTerminal
                        || this.props.carrierOffer.originPort)}
                    {" "}&gt;{" "}
                    {getLocationName(this.props.carrierOffer.destinationTerminal
                        || this.props.carrierOffer.destinationPort)}
                </div>
            </div>
            <div style={{
                flex: "0 0 auto",
                borderLeft: "1px solid " + Colors.baseColors.lightGray,
                borderRight: "1px solid " + Colors.baseColors.lightGray,
                borderBottom: "1px solid " + Colors.baseColors.lightGray,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                padding: "20px 20px 30px 20px",
                ...(this.props.carrierOfferState.detailsOpened
                    ? {
                        borderLeft: "1px solid " + Colors.baseColors.darkGray,
                        borderRight: "1px solid " + Colors.baseColors.darkGray,
                        borderBottom: "1px solid " + Colors.baseColors.darkGray,
                    }
                    : {})
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    minHeight: 150,
                }}>
                    <div style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <div style={{ marginRight: 12 }}>{getText("SlcService")}</div>
                        <div>
                            {this.props.carrierOffer?.chargeSet?.routeService != null ? this.props.carrierOffer.chargeSet.routeService.code : (

                                _.uniqBy(this.props.carrierOffer.routeConfigs || [], x => x.routeServiceId).length > 1
                                ? <RouteServiceSelect carrierOffer={this.props.carrierOffer}
                                                      carrierOfferState={this.props.carrierOfferState}
                                                      selectRouteSerice={(routeServiceId) => this.props.selectService(routeServiceId)} />
                                : (this.props.carrierOffer.chargeSet?.routeService != null ? this.props. carrierOffer.chargeSet.routeService.code :
                                    routeConfig?.routeService && routeConfig.routeService.code))}
                        </div>
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ marginRight: 6, marginLeft: "1em" }}>{getText("SlcOfferTransShipments")}</div>
                        <div style={{ color: Colors.baseColors.lightBlue, fontWeight: "bold" }}>
                            {this.props.carrierOffer.chargeSet
                                && this.props.carrierOffer.chargeSet.transShipments
                                && this.props.carrierOffer.chargeSet.transShipments.length > 0
                                ? (this.props.carrierOffer.chargeSet
                                    .transShipments.map((lo, index) => <div key={index}>{lo?.port?.name || getText("GenUnknown")}</div>))
                                : ((this.props.carrierOffer.chargeSet?.routeServiceId && routeConfig?.routeServiceId !==  this.props.carrierOffer.chargeSet?.routeServiceId) 
                                    ? "" 
                                    : (!routeConfig || !routeConfig.transShipments || routeConfig.transShipments.length === 0 
                                    ? getText("SlcOfferTsDirect")
                                    : routeConfig.transShipments.map((lo, index) => <div key={index}>{lo?.port?.name || getText("GenUnknown")}</div>)))}
                        </div>
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ marginRight: 6 }}>{getText("SlcOfferAvgTransitTime")}</div>
                        <div style={{ color: Colors.baseColors.lightBlue, fontWeight: "bold" }}>
                            {this.props.carrierOffer.chargeSet && this.props.carrierOffer.chargeSet.transitTime
                                ? this.props.carrierOffer.chargeSet.transitTime
                                : (this.props.carrierOffer.chargeSet?.routeServiceId && routeConfig?.routeServiceId !== this.props.carrierOffer.chargeSet.routeServiceId ? "" : routeConfig?.transitTime)}
                        </div>
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ marginRight: 12 }}>{"Departs:"}</div>
                        <div style={{
                            border: "1px solid " + Colors.baseColors.lightGray,
                            borderRadius: 2.5,
                            minWidth: 250
                        }}>
                            {this.departs.map((dp, dpIndex) => <div key={dp.departId}
                                style={{
                                    minHeight: 30,
                                    minWidth: 200,
                                    cursor: "pointer",
                                    padding: "0px 30px 0px 20px"
                                }}
                                className={dpIndex % 2 === 0 ? "bghover-blue" : "bghover-blue bglightgray"}
                                onClick={() => this.props.selectDepart(dp.departId)}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{
                                        flex: "0 0 auto",
                                        minWidth: 24,
                                        color: this.selectedDepart.departId === dp.departId
                                            ? Colors.baseColors.lightBlue
                                            : "white",
                                        visibility: this.selectedDepart.departId === dp.departId
                                            ? "visible"
                                            : "hidden",
                                    }}>
                                        <ArrowRight />
                                    </div>
                                    <div style={{
                                        flex: 1, textAlign: "center",
                                        ...(this.selectedDepart.departId === dp.departId
                                            ? { fontWeight: "bold", color: Colors.baseColors.lightBlue }
                                            : {})
                                    }}>
                                        {Moment(dp.etd).format('DD/MM/YYYY')} &gt; {this.showEta(dp, this.props.carrierOffer.chargeSet && this.props.carrierOffer.chargeSet.transitTime   ? this.props.carrierOffer.chargeSet.transitTime
                                            : routeConfig?.transitTime )}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                    <div style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.state.isLogoVisible && <img height={40}
                                style={{}}
                                onError={() => this.setState({ isLogoVisible: false })}
                                src={'/images/carriers/' + this.props.carriers[this.props.carrierOffer.carrierId].code.toLowerCase() + '.png'} />}
                            {!this.state.isLogoVisible && this.props.carriers[this.props.carrierOffer.carrierId].name}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {this.props.subscription.allowAskRates
                                && <Tooltip overlay={<div>{getText("SlcAllOfferAskRatesBtn")}</div>}>
                                    <IconButton aria-label={"Ask rates"}
                                        onClick={(e) => this.props.openAskRates()}>
                                        <AttachMoney style={{ fontSize: 45, color: Colors.baseColors.lightBlue }} />
                                    </IconButton>
                                </Tooltip>}
                            <Tooltip overlay={<div>{getText("SlcOfferContacts")}</div>}>
                                <IconButton aria-label={"Contacts"}
                                onClick={(e) => this.props.toggleContacts(true)}>
                                <Phone style={{ fontSize: 38, color: Colors.baseColors.lightBlue }} />
                            </IconButton>
                            </Tooltip>
                            <Tooltip overlay={<div>{getText("SlcOfferCarrierWebsite")}</div>}>
                                    <IconButton aria-label={"Carrier website"}
                                onClick={(e) => this.props.requestGoToCarrierWebSite()}>
                                <img src={"/images/iconWww.png"} height={38} />
                            </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <ExpensionPanel expanded={this.props.carrierOfferState.detailsOpened}
                    onChange={() => { /*manage from store*/ }}
                    head={<div></div>}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        width: "100%"
                    }}>
                        <div style={{ flex: "0 0 auto", paddingRight: 100 }}>
                            <div style={{
                                fontWeight: 500,
                                fontSize: 20,
                                marginBottom: 30
                            }}>Voyage Details</div>
                            <div style={{ ...fieldStyle }}>
                                <div style={{ ...labelStyle, flex: "0 0 auto", }}>{getText("SlcOfferPol")}</div>
                                <div style={{ flex: "0 0 auto" }}>{this.props.carrierOffer.originPort.name}  {isPolDoor && <img src={"/images/iconDoor.png"} height={16} />} </div>
                            </div>
                            {this.props.carrierOffer.originTerminal
                            && <div style={{ ...fieldStyle }}>
                                <div style={{ ...labelStyle, flex: "0 0 auto" }}>Terminal of loading:</div>
                                <div style={{ flex: "0 0 auto" }}>{this.props.carrierOffer.originTerminal.name}</div>
                            </div>}
                            <div style={{ ...fieldStyle }}>
                                <div style={{ ...labelStyle, flex: "0 0 auto" }}>{getText("SlcOfferPod")}</div>
                                <div style={{ flex: "0 0 auto" }}>{this.props.carrierOffer.destinationPort.name} {isPodDoor && <img src={"/images/iconDoor.png"} height={16} />}</div>
                            </div>
                            {this.props.carrierOffer.destinationTerminal
                            && <div style={{ ...fieldStyle }}>
                                <div style={{ ...labelStyle, flex: "0 0 auto" }}>Terminal of discharge</div>
                                <div style={{ flex: "0 0 auto" }}>{this.props.carrierOffer.destinationTerminal.name}</div>
                            </div>}
                            <div style={{ ...fieldStyle }}>
                                <div style={{ ...labelStyle, flex: "0 0 auto" }}>{getText("SlcOfferVessel")}</div>
                                <div style={{ flex: "0 0 auto" }}>
                                    {this.selectedDepart?.vessel
                                        ? this.selectedDepart.vessel.name
                                        + (this.selectedDepart.vessel.imo
                                            ? " / " + this.selectedDepart.vessel.imo
                                            : "")
                                        : <i>{getText("GenUnknown")}</i>}
                                </div>
                            </div>
                            <div style={{ ...fieldStyle }}>
                                <div style={{ ...labelStyle, flex: "0 0 auto" }}>{getText("SlcOfferTransShipments")}</div>
                                <div style={{ flex: "0 0 auto" }}>
                                    {this.props.carrierOffer.chargeSet
                                        && this.props.carrierOffer.chargeSet.transShipments
                                        && this.props.carrierOffer.chargeSet.transShipments.length > 0
                                        ? (this.props.carrierOffer.chargeSet
                                            .transShipments.map((lo, index) => <div key={index}>{this.formatTransshipment(lo) || getText("GenUnknown")}</div>))
                                        : (!routeConfig || !routeConfig.transShipments || routeConfig.transShipments.length === 0
                                            ? getText("SlcOfferTsDirect")
                                            : routeConfig.transShipments.map((lo, index) => <div key={index}>{  this.formatTransshipment( lo) || getText("GenUnknown")}</div>))}
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1 }}></div>
                        <div style={{  }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 30
                            }}>
                                <div style={{ fontWeight: 500, fontSize: 20, marginRight: 40 }}>{getText("SlcOfferRatesDetails")}</div>
                                <Button style={{ minWidth: 150, height: 30 }}
                                    onClick={() => {
                                        if (this.allDetailsOpened) {
                                            this.props.criteriaModel.criteriaSizeTypes
                                                .filter(cst => cst.number > 0
                                                    && this.props.criteriaType === "Fcl"
                                                    && (!this.props.carrierOffer.chargeSet
                                                        || this.ratesCalculator.findChargesToApply(this.props.carrierOffer.chargeSet,
                                                            cst,
                                                            null).some(x => x.chargeNameId === frtId)))
                                                .forEach(cst => {
                                                    this.props.toggleSizeTypeDetails(false, cst.sizeTypeId);
                                                });
                                            this.props.toggleBlDetails(false);
                                            this.props.toggleRatesDetails(false);
                                            this.props.toggleLclDetails(false);
                                        } else {
                                            this.props.criteriaModel.criteriaSizeTypes
                                                .filter(cst => cst.number > 0
                                                    && this.props.criteriaType === "Fcl"
                                                    && (!this.props.carrierOffer.chargeSet
                                                        || this.ratesCalculator.findChargesToApply(this.props.carrierOffer.chargeSet,
                                                            cst,
                                                            null).some(x => x.chargeNameId === frtId)))
                                                .forEach(cst => {
                                                    this.props.toggleSizeTypeDetails(true, cst.sizeTypeId);
                                                });
                                            this.props.toggleBlDetails(true);
                                            this.props.toggleRatesDetails(true);
                                            this.props.toggleLclDetails(true);
                                        }
                                        
                                    }}>{this.allDetailsOpened
                                        ? getText("SlcOfferCloseAllDetails")
                                        : getText("SlcOfferOpenAllDetails")}</Button>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", flexWrap: "wrap", rowGap: 16, columnGap: 60 }}>
                                <div style={{ flex: "0 0 auto" }}>
                                    {/*FCL charges details*/}
                                    {this.props.criteriaModel.criteriaSizeTypes
                                        .filter(cst => cst.number > 0
                                            && this.props.criteriaType === "Fcl"
                                            && (!this.props.carrierOffer.chargeSet
                                                || this.ratesCalculator.findChargesToApply(this.props.carrierOffer.chargeSet,
                                                    cst,
                                                    null).some(x => x.chargeNameId === frtId))).map(cst =>
                                                        <div key={cst.sizeTypeId} style={{ marginBottom: 16 }}>
                                                            <ExpensionPanel expanded={this.props.carrierOfferState.sizeTypeDetailsOpened[cst.sizeTypeId] ? true : false}
                                                                onChange={() => this.props.toggleSizeTypeDetails(
                                                                    !this.props.carrierOfferState.sizeTypeDetailsOpened[cst.sizeTypeId]
                                                                    , cst.sizeTypeId)}
                                                                head={<div style={{
                                                                    ...ratesTitleStyle,
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    cursor: "pointer",
                                                                    color: Colors.baseColors.lightBlue,
                                                                    marginBottom: 16
                                                                }}>
                                                                    <div style={{ flex: 1 }}>
                                                                        {getText("SlcOfferRatesCtr", {
                                                                            containerName: this.props.sizeTypes[cst.sizeTypeId].name,
                                                                            value: this.props.carrierOffer.chargeSet
                                                                                && !this.props.carrierOffer.chargeSet.invalidated
                                                                                ? (Math.round(this.ratesCalculator.calculatePrice(
                                                                                    this.ratesCalculator.findChargesToApply(
                                                                                        this.props.carrierOffer.chargeSet,
                                                                                        cst, null,
                                                                                        chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)),
                                                                                    this.props.sizeTypes[cst.sizeTypeId].teu).totalUsd /
                                                                                    this.currency.value) + " " + this.currency.code)
                                                                                : (!this.props.carrierOffer.chargeSet ? getText("SlcOfferRatesNoRates") : "Disabled")
                                                                        })}
                                                                    </div>
                                                                    <div style={{ flex: "0 0 auto" }}>
                                                                        {this.props.carrierOfferState.sizeTypeDetailsOpened[cst.sizeTypeId]
                                                                            ? <ArrowDropUp />
                                                                            : <ArrowDropDown />}
                                                                    </div>
                                                                </div>}>
                                                                {this.props.carrierOffer.chargeSet
                                                                    && !this.props.carrierOffer.chargeSet.invalidated
                                                                    ? <div>
                                                                        {this.props.criteriaModel.loadingCharge
                                                                            && <div>
                                                                                <div style={{ marginBottom: 10 }}>
                                                                                    {getText("SlcOfferRatesOrigin", {
                                                                                        value: Math.round(this.ratesCalculator.calculatePrice(
                                                                                            this.ratesCalculator.findChargesToApply(
                                                                                                this.props.carrierOffer.chargeSet, cst, "Origin",
                                                                                                chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)),
                                                                                            this.props.sizeTypes[cst.sizeTypeId].teu)
                                                                                            .totalUsd / this.currency.value) + " " + this.currency.code
                                                                                    })}
                                                                                </div>
                                                                                <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                                                    charges={this.ratesCalculator.findChargesToApply(
                                                                                        this.props.carrierOffer.chargeSet, cst, "Origin",
                                                                                        chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents))}
                                                                                    sizeType={this.props.sizeTypes[cst.sizeTypeId]}
                                                                                    chargeSet={this.props.carrierOffer.chargeSet}
                                                                                    currencies={this.props.currencies}
                                                                                    chargeNames={this.props.chargeNames}
                                                                                    ratesCalculator={this.ratesCalculator} />
                                                                            </div>}
                                                                        <div style={{ marginBottom: 10 }}>
                                                                            {getText("SlcOfferRatesFreight", {
                                                                                value: Math.round(this.ratesCalculator.calculatePrice(
                                                                                    this.ratesCalculator.findChargesToApply(
                                                                                        this.props.carrierOffer.chargeSet, cst, "Freight",
                                                                                        chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)),
                                                                                    this.props.sizeTypes[cst.sizeTypeId].teu)
                                                                                    .totalUsd / this.currency.value) + " " + this.currency.code
                                                                            }).replace(')', this.getSellingRatesBySizeTypeId(cst.sizeTypeId) ? '' : ')')}
                                                                            {this.getSellingRatesBySizeTypeId(cst.sizeTypeId) && <span style={{ fontWeight: 'bold' }}>
                                                                                {' / '}
                                                                                {this.getSellingRatesBySizeTypeId(cst.sizeTypeId).sellingMargin
                                                                                    + this.getFrtBySizeTypeId(cst.sizeTypeId).amount}
                                                                                {' '}
                                                                                {this.props.currencies[this.getSellingRatesBySizeTypeId(cst.sizeTypeId).currencyId].code}
                                                                                {')'}
                                                                            </span>}
                                                                        </div>
                                                                        <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                                            charges={this.ratesCalculator.findChargesToApply(
                                                                                this.props.carrierOffer.chargeSet, cst, "Freight",
                                                                                chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents))}
                                                                            sellingRatesValue={this.getSellingRatesBySizeTypeId(cst.sizeTypeId)}
                                                                            sizeType={this.props.sizeTypes[cst.sizeTypeId]}
                                                                            chargeSet={this.props.carrierOffer.chargeSet}
                                                                            currencies={this.props.currencies}
                                                                            chargeNames={this.props.chargeNames}
                                                                            ratesCalculator={this.ratesCalculator} />

                                                                        {this.props.criteriaModel.unLoadingCharge
                                                                            && <div>
                                                                                <div style={{ marginBottom: 10 }}>{getText("SlcOfferRatesDestination", {
                                                                                    value: Math.round(this.ratesCalculator.calculatePrice(
                                                                                        this.ratesCalculator.findChargesToApply(
                                                                                            this.props.carrierOffer.chargeSet, cst, "Destination",
                                                                                            chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)),
                                                                                        this.props.sizeTypes[cst.sizeTypeId].teu)
                                                                                        .totalUsd / this.currency.value) + " " + this.currency.code
                                                                                })}</div>
                                                                                <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                                                    charges={this.ratesCalculator.findChargesToApply(
                                                                                        this.props.carrierOffer.chargeSet, cst, "Destination",
                                                                                        chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents))}
                                                                                    sizeType={this.props.sizeTypes[cst.sizeTypeId]}
                                                                                    chargeSet={this.props.carrierOffer.chargeSet}
                                                                                    currencies={this.props.currencies}
                                                                                    chargeNames={this.props.chargeNames}
                                                                                    ratesCalculator={this.ratesCalculator} />
                                                                            </div>}

                                                                    </div>
                                                                    : <div>{getText("SlcOfferRatesNoRates")}</div>}
                                                            </ExpensionPanel>
                                                        </div>)}
                                    {/*LCL charges details*/}
                                    {this.props.criteriaType === "Lcl"
                                        && <div style={{ margin: "16px 0px" }}>
                                            <ExpensionPanel expanded={this.props.carrierOfferState.lclDetailsOpened}
                                                onChange={() => this.props.toggleLclDetails(!this.props.carrierOfferState.lclDetailsOpened)}
                                                head={<div style={{
                                                    ...ratesTitleStyle,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    color: Colors.baseColors.lightBlue,
                                                    marginBottom: 16
                                                }}>
                                                    <div style={{ flex: 1 }}>
                                                        Charges per Wm: {this.props.carrierOffer.chargeSet
                                                            && !this.props.carrierOffer.chargeSet.invalidated
                                                            ? Math.round(this.ratesCalculator.calculatePrice(
                                                                this.ratesCalculator.findChargesToApply(
                                                                    this.props.carrierOffer.chargeSet, null, null,
                                                                    chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)), 1).totalUsd /
                                                                this.currency.value) + " " + this.currency.code
                                                            : (!this.props.carrierOffer.chargeSet ? getText("SlcOfferRatesNoRates") : "Disabled")}
                                                    </div>
                                                    <div style={{ flex: "0 0 auto" }}>
                                                        {this.props.carrierOfferState.lclDetailsOpened
                                                            ? <ArrowDropUp />
                                                            : <ArrowDropDown />}
                                                    </div>
                                                </div>}>
                                                {this.props.carrierOffer.chargeSet
                                                    && !this.props.carrierOffer.chargeSet.invalidated
                                                    ? <div>
                                                        {this.props.criteriaModel.loadingCharge
                                                            && <div>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    {getText("SlcOfferRatesOrigin", {
                                                                        value: Math.round(this.ratesCalculator.calculatePrice(
                                                                            this.ratesCalculator.findChargesToApply(
                                                                                this.props.carrierOffer.chargeSet, null, "Origin",
                                                                                chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)), 1)
                                                                            .totalUsd / this.currency.value) + " " + this.currency.code
                                                                    })}
                                                                </div>
                                                                <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                                    charges={this.ratesCalculator.findChargesToApply(
                                                                        this.props.carrierOffer.chargeSet, null, "Origin")}
                                                                    chargeSet={this.props.carrierOffer.chargeSet}
                                                                    currencies={this.props.currencies}
                                                                    chargeNames={this.props.chargeNames}
                                                                    ratesCalculator={this.ratesCalculator} />
                                                            </div>}
                                                        <div style={{ marginBottom: 10 }}>
                                                            {getText("SlcOfferRatesFreight", {
                                                                value: Math.round(this.ratesCalculator.calculatePrice(
                                                                    this.ratesCalculator.findChargesToApply(
                                                                        this.props.carrierOffer.chargeSet, null, "Freight",
                                                                        chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)), 1)
                                                                    .totalUsd / this.currency.value) + " " + this.currency.code
                                                            })}
                                                        </div>
                                                        <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                            charges={this.ratesCalculator.findChargesToApply(
                                                                this.props.carrierOffer.chargeSet, null, "Freight",
                                                                chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents))}
                                                            currencies={this.props.currencies}
                                                            chargeSet={this.props.carrierOffer.chargeSet}
                                                            chargeNames={this.props.chargeNames}
                                                            ratesCalculator={this.ratesCalculator} />

                                                        {this.props.criteriaModel.unLoadingCharge
                                                            && <div>
                                                                <div style={{ marginBottom: 10 }}>{getText("SlcOfferRatesDestination", {
                                                                    value: Math.round(this.ratesCalculator.calculatePrice(
                                                                        this.ratesCalculator.findChargesToApply(
                                                                            this.props.carrierOffer.chargeSet, null, "Destination",
                                                                            chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)), 1)
                                                                        .totalUsd / this.currency.value) + " " + this.currency.code
                                                                })}</div>
                                                                <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                                    charges={this.ratesCalculator.findChargesToApply(
                                                                        this.props.carrierOffer.chargeSet, null, "Destination",
                                                                        chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents))}
                                                                    chargeSet={this.props.carrierOffer.chargeSet}
                                                                    currencies={this.props.currencies}
                                                                    chargeNames={this.props.chargeNames}
                                                                    ratesCalculator={this.ratesCalculator} />
                                                            </div>}

                                                    </div>
                                                    : <div>{getText("SlcOfferRatesNoRates")}</div>}
                                            </ExpensionPanel>
                                        </div>}
                                </div>
                                <div style={{ flex: "0 0 auto" }}>
                                    {/*BL DETAILS*/}
                                    <div style={{ marginBottom: 16 }}>
                                        <ExpensionPanel expanded={this.props.carrierOfferState.blDetailsOpened}
                                            onChange={(value) => this.props.toggleBlDetails(value)}
                                            head={<div style={{
                                                ...ratesTitleStyle,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                color: Colors.baseColors.lightBlue,
                                                marginBottom: 16
                                            }}>
                                                <div style={{ flex: 1 }}>
                                                    {getText(this.props.criteriaType === "Lcl" 
                                                        ? "SlcOfferRatesBlLcl" 
                                                        : "SlcOfferRatesBl", {
                                                        value: this.props.carrierOffer.chargeSet
                                                                && !this.props.carrierOffer.chargeSet.invalidated
                                                            ? Math.round(this.ratesCalculator.calculatePrice(
                                                                this.ratesCalculator.findBlChargesToApply(
                                                                    this.props.carrierOffer.chargeSet, null,
                                                                    chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)), 1).totalUsd /
                                                                this.currency.value) + " " + this.currency.code
                                                            : (!this.props.carrierOffer.chargeSet ? getText("SlcOfferRatesNoRates") : "Disabled")
                                                    })}
                                                </div>
                                                <div style={{ flex: "0 0 auto" }}>
                                                    {this.props.carrierOfferState.blDetailsOpened
                                                        ? <ArrowDropUp />
                                                        : <ArrowDropDown />}
                                                </div>
                                            </div>}>
                                            {this.props.carrierOffer.chargeSet
                                                && !this.props.carrierOffer.chargeSet.invalidated
                                                ? <div>
                                                    {this.props.criteriaModel.loadingCharge
                                                        && <div>
                                                            <div style={{ marginBottom: 10 }}>
                                                                {getText("SlcOfferRatesOrigin", {
                                                                    value: Math.round(this.ratesCalculator.calculatePrice(
                                                                        this.ratesCalculator.findBlChargesToApply(
                                                                            this.props.carrierOffer.chargeSet, "Origin",
                                                                            chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)), 1)
                                                                        .totalUsd / this.currency.value) + " " + this.currency.code
                                                                })}
                                                            </div>
                                                            <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                                showUnit={this.props.criteriaType === "Lcl"}
                                                                charges={this.ratesCalculator.findBlChargesToApply(
                                                                    this.props.carrierOffer.chargeSet, "Origin")}
                                                                chargeSet={this.props.carrierOffer.chargeSet}
                                                                currencies={this.props.currencies}
                                                                chargeNames={this.props.chargeNames}
                                                                ratesCalculator={this.ratesCalculator} />
                                                        </div>}
                                                    <div style={{ marginBottom: 10 }}>
                                                        {getText("SlcOfferRatesFreight", {
                                                            value: Math.round(this.ratesCalculator.calculatePrice(
                                                                this.ratesCalculator.findBlChargesToApply(
                                                                    this.props.carrierOffer.chargeSet, "Freight",
                                                                    chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)), 1)
                                                                .totalUsd / this.currency.value) + " " + this.currency.code
                                                        })}
                                                    </div>
                                                    <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                                       showUnit={this.props.criteriaType === "Lcl"}
                                                        charges={this.ratesCalculator.findBlChargesToApply(
                                                            this.props.carrierOffer.chargeSet, "Freight",
                                                            chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents))}
                                                        currencies={this.props.currencies}
                                                        chargeSet={this.props.carrierOffer.chargeSet}
                                                        chargeNames={this.props.chargeNames}
                                                        ratesCalculator={this.ratesCalculator} />

                                                    {this.props.criteriaModel.unLoadingCharge
                                                        && <div>
                                                            <div style={{ marginBottom: 10 }}>{getText("SlcOfferRatesDestination", {
                                                                value: Math.round(this.ratesCalculator.calculatePrice(
                                                                    this.ratesCalculator.findBlChargesToApply(
                                                                        this.props.carrierOffer.chargeSet, "Destination",
                                                                        chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents)), 1)
                                                                    .totalUsd / this.currency.value) + " " + this.currency.code
                                                            })}</div>
                                                            <ChargeListDisplay style={{ margin: "16px 0px" }}
                                                                               showUnit={this.props.criteriaType === "Lcl"}
                                                                charges={this.ratesCalculator.findBlChargesToApply(
                                                                    this.props.carrierOffer.chargeSet, "Destination",
                                                                    chargeAgentsToMoficiations(this.props.carrierOffer.chargeAgents))}
                                                                chargeSet={this.props.carrierOffer.chargeSet}
                                                                currencies={this.props.currencies}
                                                                chargeNames={this.props.chargeNames}
                                                                ratesCalculator={this.ratesCalculator} />
                                                        </div>}

                                                </div>
                                                : <div>{getText("SlcOfferRatesNoRates")}</div>}
                                        </ExpensionPanel>
                                    </div>
                                    {/*RATES INFO DETAILS*/}
                                    <div style={{ margin: "16px 0px" }}>
                                        <ExpensionPanel expanded={this.props.carrierOfferState.ratesDetailsOpened}
                                            onChange={(value) => this.props.toggleRatesDetails(value)}
                                            head={<div>
                                                {this.props.carrierOffer.chargeSet
                                                    && (this.props.carrierOffer.offerInformations.some(x => x.level === "High") || (this.props.carrierOffer.ratesOffer && this.props.carrierOffer.ratesOffer.level === "High"))
                                                    && <div style={{}}><Warning style={{ color: Colors.baseColors.lightBlue, height: 14 }} /> {getText("SlcOfferRatesInfoWarning")}</div>}
                                                <div style={{
                                                    ...ratesTitleStyle,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    color: Colors.baseColors.lightBlue,
                                                    marginBottom: 16
                                                }}>
                                                    <div style={{ flex: 1 }}>
                                                        {getText("SlcOfferRatesInfo")}
                                                    </div>
                                                    <div style={{ flex: "0 0 auto" }}>
                                                        {this.props.carrierOfferState.ratesDetailsOpened
                                                            ? <ArrowDropUp />
                                                            : <ArrowDropDown />}
                                                    </div>
                                                </div>
                                            </div>}>
                                            {this.props.carrierOffer.chargeSet
                                                && !this.props.carrierOffer.chargeSet.invalidated
                                                ? <div style={{ margin: "16px 0px", maxWidth: 700 }}>
                                                    {this.props.carrierOffer.chargeSet.source
                                                        && this.props.carrierOffer.chargeSet.ratesFetchedRecordId
                                                        && <div style={{ ...ratesDetailsField }}>
                                                            <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesOcqSource")}</div>
                                                        <div style={{ ...ratesDetailsValue }}>
                                                            {this.props.carrierOffer.chargeSet.source}
                                                            </div>
                                                        </div>}
                                                    {!(this.props.carrierOffer.chargeSet.ratesPriceType === "Spot")
                                                        && <div style={{ ...ratesDetailsField }}>
                                                            <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesValidity")}</div>
                                                            <div style={{ ...ratesDetailsValue }}>
                                                                {getText("GenDateFrom")}
                                                                <span> {Moment(this.props.carrierOffer.chargeSet.dateBegin).format("DD/MM/YYYY")} </span>
                                                                {getText("GenDateTo")}
                                                                <span> {Moment(this.props.carrierOffer.chargeSet.dateEnd).format("DD/MM/YYYY")} </span>
                                                            </div>
                                                        </div>}
                                                    {!(this.props.carrierOffer.chargeSet.ratesPriceType === "Spot")
                                                        && <div style={{ ...ratesDetailsField }}>
                                                            <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesFrtValidity")}</div>
                                                        <div style={{ ...ratesDetailsValue }}>
                                                            {Moment(this.props.carrierOffer.chargeSet.freightValidity ||
                                                                this.props.carrierOffer.chargeSet.dateEnd).format("DD/MM/YYYY")}
                                     
                                                        </div>
                                                        </div>}
                                                    {this.props.carrierOffer.chargeSet.quotValidity
                                                        && <div style={{ ...ratesDetailsField }}>
                                                        <div style={{ ...ratesDetailsLabel }}>{getText("SlcQuoteValidity")}</div>
                                                            <div style={{ ...ratesDetailsValue }}>
                                                                {Moment(this.props.carrierOffer.chargeSet.quotValidity &&
                                                                    this.props.carrierOffer.chargeSet.quotValidity).format("DD/MM/YYYY")}
                                                            </div>
                                                        </div>}
                                                    {this.props.carrierOffer.chargeSet.ratesPriceType === "Spot"
                                                        && <div style={{ ...ratesDetailsField }}>
                                                            <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesValidity")}</div>
                                                            <div style={{ ...ratesDetailsValue }}>
                                                                {"Quoted the"}
                                                                {" "}<span style={{ fontWeight: "bold" }}>{Moment(this.props.carrierOffer.chargeSet.quotedDate).format('DD/MM/YYYY')}</span>
                                                                {" and valid for ETD"}
                                                                {" "}<span className={""}
                                                                    style={{ fontWeight: "bold" }}>{Moment(this.selectedDepart.etd).format("DD/MM/YYYY")}</span>
                                                            </div>
                                                        </div>}
                                                    {this.props.subscription.subscriptionType !== "Agent" &&
                                                        <div style={{ ...ratesDetailsField }}>
                                                            <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesReference")}</div>
                                                            <div style={{ ...ratesDetailsValue }}>
                                                                {this.props.carrierOffer.chargeSet.carrierReference || <i>No reference</i>}
                                                            </div>
                                                        </div>}
                                                    {this.props.subscription.subscriptionType !== "Agent" &&
                                                        <div style={{ ...ratesDetailsField }}>
                                                            <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesMarket")}</div>
                                                            <div style={{ ...ratesDetailsValue }}>
                                                                {this.props.carrierOffer.chargeSet.marketId
                                                                    ? this.props.markets[this.props.carrierOffer.chargeSet.marketId].name
                                                                    : getText("GenNone")}
                                                            </div>
                                                        </div>}
                                                    {this.props.subscription.subscriptionType !== "Agent" &&
                                                        <div style={{ ...ratesDetailsField }}>
                                                            <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesShortName")}</div>
                                                            <div style={{ ...ratesDetailsValue }}>
                                                                {(this.props.carrierOffer.ratesOffer &&
                                                                    this.props.carrierOffer.ratesOffer.shortName) || <i>Not defined</i>}
                                                            </div>
                                                        </div>}
                                                    {this.props.subscription.subscriptionType !== "Agent" 
                                                    && this.props.carrierOffer.ratesOffer 
                                                    && this.props.carrierOffer.ratesOffer.commodity
                                                    && <div style={{ ...ratesDetailsField }}>
                                                        <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesCommodity")}</div>
                                                        <div style={{ ...ratesDetailsValue }}>
                                                            {this.props.carrierOffer.ratesOffer.commodity}
                                                        </div>
                                                    </div>}
                                                    {this.props.subscription.subscriptionType !== "Agent"
                                                    && this.props.carrierOffer.ratesOffer
                                                    && this.props.carrierOffer.ratesOffer.nac
                                                    && <div style={{ ...ratesDetailsField }}>
                                                        <div style={{ ...ratesDetailsLabel }}>{getText("SlcOfferRatesNac")}</div>
                                                        <div style={{ ...ratesDetailsValue }}>
                                                            {this.props.carrierOffer.ratesOffer.nac}
                                                        </div>
                                                    </div>}
                                                    {this.props.subscription.subscriptionType !== "Agent" &&
                                                        <div style={{ ...ratesDetailsField, alignItems: "start" }}>
                                                        <div style={{ ...ratesDetailsLabel }}>
                                                            {this.props.carrierOffer &&  this.props.carrierOffer.ratesOffer && this.props.carrierOffer.ratesOffer.level === "High" &&
                                                                <Warning style={{ marginRight: 3, fontSize: 12, color: Colors.baseColors.lightBlue }} />
                                                            }
                                                            {getText("SlcOfferRatesDescription")}
                                                        </div>
                                                            <div style={{ ...ratesDetailsValue }}>
                                                                <ExpensionPanel expanded={this.props.carrierOfferState.ratesOfferDescriptionOpened}
                                                                    onChange={(value) => this.props.toggleRatesOfferDescription(value)}
                                                                    head={<div><u>{getText("SlcOfferRatesDetails")}</u></div>}>
                                                                    <p style={{ whiteSpace: "pre-wrap" }}>
                                                                        {this.props.carrierOffer.ratesOffer
                                                                            && this.props.carrierOffer.ratesOffer.description
                                                                            ? this.props.carrierOffer.ratesOffer.description
                                                                            : <i>Not defined</i>}
                                                                    </p>
                                                                </ExpensionPanel>
                                                            </div>
                                                        </div>}
                                                    {this.props.subscription.subscriptionType !== "Agent"
                                                        && this.props.carrierOffer.offerInformations
                                                        .filter(x => x.chargeSetId)
                                                        .map(di => <div key={di.offerInformationId} style={{ marginTop: 6 }}>
                                                            <div style={{ ...ratesDetailsField, alignItems: "start" }}>
                                                                <div style={{ ...ratesDetailsLabel }}>
                                                                    {di.level === "High"
                                                                        && <Warning style={{ color: Colors.baseColors.lightBlue, height: 14 }} />}
                                                                    {getText("SlcOfferRatesSpecificInfoTitle")}
                                                                </div>
                                                                <div style={{ ...ratesDetailsValue }}>
                                                                    <ExpensionPanel expanded={this.props.carrierOfferState.documentInfoDetailsOpened[di.offerInformationId] ? true : false}
                                                                        onChange={(value) => this.props.toggleDocumentInfoDetails(value, di.offerInformationId)}
                                                                        head={<div><u>{getText("SlcOfferRatesInfoDetails")}</u></div>}>
                                                                        <p style={{ whiteSpace: "pre-wrap" }}>
                                                                            <Linkify properties={{ target: "_blank" }}>{di.content}</Linkify>
                                                                        </p>
                                                                    </ExpensionPanel>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    {this.props.subscription.subscriptionType !== "Agent"
                                                        && this.props.carrierOffer.offerInformations
                                                        .filter(x => x.workDocumentsId)
                                                        .map(di => <div key={di.offerInformationId} style={{ marginTop: 6 }}>
                                                            <div style={{ ...ratesDetailsField, alignItems: "start" }}>
                                                                <div style={{ ...ratesDetailsLabel }}>
                                                                    {di.level === "High"
                                                                        && <Warning style={{ color: Colors.baseColors.lightBlue, height: 14 }} />}
                                                                    {getText("SlcOfferRatesInfoTitle")}
                                                                </div>
                                                                <div style={{ ...ratesDetailsValue }}>
                                                                    <ExpensionPanel expanded={this.props.carrierOfferState.documentInfoDetailsOpened[di.offerInformationId] ? true : false}
                                                                        onChange={(value) => this.props.toggleDocumentInfoDetails(value, di.offerInformationId)}
                                                                        head={<div><u>{getText("SlcOfferRatesInfoDetails")}</u></div>}>
                                                                        <p style={{ whiteSpace: "pre-wrap" }}>
                                                                            <Linkify properties={{ target: "_blank" }}>{di.content}</Linkify>
                                                                        </p>
                                                                    </ExpensionPanel>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    {this.props.subscription.subscriptionType !== "Agent"
                                                        && this.props.carrierOffer.offerInformations
                                                            .filter(x => !x.chargeSetId && !x.workDocumentsId)
                                                            .map(di => <div key={di.offerInformationId} style={{ marginTop: 6 }}>
                                                            <div style={{ ...ratesDetailsField, alignItems: "start" }}>
                                                                <div style={{ ...ratesDetailsLabel }}>
                                                                    {di.level === "High"
                                                                        && <Warning style={{ color: Colors.baseColors.lightBlue, height: 14 }} />}
                                                                    {getText("SlcOfferRatesSpecificInfoTitle")}
                                                                </div>
                                                                <div style={{ ...ratesDetailsValue }}>
                                                                    <ExpensionPanel expanded={this.props.carrierOfferState.documentInfoDetailsOpened[di.offerInformationId] ? true : false}
                                                                        onChange={(value) => this.props.toggleDocumentInfoDetails(value, di.offerInformationId)}
                                                                        head={<div><u>{getText("SlcOfferRatesInfoDetails")}</u></div>}>
                                                                        <p style={{ whiteSpace: "pre-wrap" }}>
                                                                            <Linkify properties={{ target: "_blank" }}>{di.content}</Linkify>
                                                                        </p>
                                                                    </ExpensionPanel>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    {this.props.subscription.subscriptionType !== "Agent"
                                                        && !this.props.carrierOffer.chargeSet.ratesFetchedRecordId
                                                        && <div style={{
                                                            marginTop: 12,
                                                            color: Colors.baseColors.lightBlue,
                                                            textDecoration: "underline",
                                                            fontSize: 18,
                                                            cursor: "pointer",
                                                        textAlign: "right",
                                                        maxWidth: 400
                                                        }}
                                                            onClick={(e) => {
                                                                if (!this.props.carrierOfferState.downloadDocumentState.isLoading) {
                                                                    this.props.requestDownloadRatesDocument();
                                                                }
                                                            }}>
                                                            {getText("SlcOfferRatesDownloadSheet")}
                                                        </div>}
                                                    {this.props.carrierOffer.chargeSet.ratesFetchedRecordId
                                                        && this.props.carrierRatesPlatforms[this.props.carrierOffer.carrierRatesPlatformId].onlineQuoteLink
                                                    && <div style={{
                                                        marginTop: 12,
                                                        color: Colors.baseColors.lightBlue,
                                                        textDecoration: "underline",
                                                        fontSize: 18,
                                                        cursor: "pointer",
                                                        textAlign: "right",
                                                        maxWidth: 400
                                                    }}
                                                            onClick={(e) => {
                                                                window.open(this.props.carrierRatesPlatforms[this.props.carrierOffer.carrierRatesPlatformId].onlineQuoteLink, "_blank");
                                                            }}>
                                                        {getText("SlcOfferGoToOcqWebsite")}
                                                    </div>}
                                                </div>
                                                : <div style={{ margin: "16px 0px" }}>{getText("SlcOfferRatesNoRates")}</div>}
                                        </ExpensionPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 3 }}></div>
                    </div>
                </ExpensionPanel>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                <div style={{ flex: 5 }}></div>
                <div style={{ flex: 3, textAlign: "center" }}>
                    <Button style={{
                        height: 36,
                        position: "relative",
                        bottom: 16,
                        backgroundColor: "white"
                    }}
                        onClick={(e) => this.props.toggleDetails(!this.props.carrierOfferState.detailsOpened)}>
                        {getText("SlcOfferDetails")} {this.props.carrierOfferState.detailsOpened
                            ? <Remove style={{ fontSize: 15, fontWeight: "bold" }} />
                            : <Add style={{ fontSize: 15, fontWeight: "bold" }} />}
                    </Button>
                </div>
                <div style={{ flex: 1 }}></div>
                <div style={{ flex: "0 0 auto", textAlign: "center" }}>
                    <Button style={{
                        minWidth: 146,
                        height: 36,
                        position: "relative",
                        bottom: 16,
                        backgroundColor: Colors.baseColors.lightBlue,
                        color: "white"
                    }}
                        onClick={(e) => this.props.openTransferData()}>
                        {getText("SlcOfferTransfer")}
                    </Button>
                </div>
                <div style={{ flex: 1 }}></div>
                <div style={{ flex: "0 0 auto", textAlign: "center" }}>
                    <Button style={{
                        height: 36,
                        position: "relative",
                        bottom: 16,
                        backgroundColor: canUseQuotation(this.props.subscription) ? Colors.baseColors.lightBlue : Colors.baseColors.lightGray,
                        color: "white"
                    }}
                       disabled = {!canUseQuotation(this.props.subscription)}
                        onClick={(e) => this.props.goToQuotation()}>
                        {getText("SlcOfferQuotation")}
                    </Button>
                </div>
                <div style={{ flex: 1 }}></div>
                <div style={{ flex: "0 0 auto", textAlign: "center" }}>
                    <Button style={{
                        height: 36,
                        position: "relative",
                        bottom: 16,
                        backgroundColor: Colors.baseColors.lightBlue,
                        color: "white"
                    }}
                        onClick={(e) => this.props.openAskBooking()}>
                        Book
                    </Button>
                </div>
                <div style={{ flex: 1 }}></div>
            </div>
            <DialogModal
                contentLabel="Contacts"
                maxWidth={"md"}
                isOpen={this.props.carrierOfferState && this.props.carrierOfferState.contactsOpened}
                onRequestClose={() => this.props.toggleContacts(false)}>
                <OfferContacts
                    carrierOffer={this.props.carrierOffer}
                    carriers={this.props.carriers}
                    carrierOfferState={this.props.carrierOfferState}
                    subscriptionType={this.props.subscription.subscriptionType}
                />
            </DialogModal>
        </div>
    }
}