/**
 * Okargo API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

import * as isomorphicFetch from "isomorphic-fetch";
import * as assign from "core-js/library/fn/object/assign";

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

//const BASE_PATH = "https://okargoreact-staging.azurewebsites.net".replace(/\/+$/, "");
const BASE_PATH = "https://app.okargo.com".replace(/\/+$/, "");
//const BASE_PATH = "http://okargo.guiennet.com:8090".replace(/\/+$/, "");
//const BASE_PATH = "http://okargo.guiennet.com:8090/".replace(/\/+$/, "");


export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface AccountModel {
    "accountId"?: number;
    "email"?: string;
    "enabled"?: boolean;
    "creationDate"?: Date;
    "expirationDate"?: Date;
}

export interface ActionModel {
    "type"?: string;
    "payload"?: string;
    "timestamp"?: number;
}

export interface ActionsLogModel {
    "username"?: string;
    "timestamp"?: number;
    "actions"?: Array<ActionModel>;
}

export interface AddressModel {
    "addressId"?: number;
    "postalCode"?: number;
    "city"?: string;
    "streetNumber"?: string;
    "street"?: string;
    "countryId"?: number;
    "country"?: LocationModel;
}

export interface AgencyMarketModel {
    "agencyMarketId"?: number;
    "agencyId"?: number;
    "marketId"?: number;
}

export interface AgentSearchModel {
    "clientActionId"?: number;
    "criteria"?: CriteriaModel;
    "agentEmail"?: string;
    "creationDate"?: Date;
}

export interface ApiResultBoolean {
    "result"?: boolean;
    "message"?: string;
}

export interface ApiResultFormModel {
    "result"?: FormModel;
    "message"?: string;
}

export interface ApiResultMultiPortInstanceModel {
    "result"?: MultiPortInstanceModel;
    "message"?: string;
}

export interface ApiResultString {
    "result"?: string;
    "message"?: string;
}

export interface CarrierCarrierRatingModel {
    "carrierCarrierRatingId"?: number;
    "carrierId"?: number;
    "ratesType"?: CarrierCarrierRatingModelRatesTypeEnum;
    "ratesOffer"?: RatesOfferModel;
    "ratesOfferId"?: number;
    "carrierRatingId"?: number;
    "firmId"?: number;
    "fromPortsZoneId"?: number;
    "toPortsZoneId"?: number;
}

export type CarrierCarrierRatingModelRatesTypeEnum = "Fak" | "Special";
export interface CarrierGroupModel {
    "carrierGroupId"?: number;
    "name"?: string;
}

export interface CarrierModel {
    "carrierId"?: number;
    "enabled"?: boolean;
    "type"?: CarrierModelTypeEnum;
    "carrierGroupId"?: number;
    "name"?: string;
    "code"?: string;
    "scac"?: string;
    "contactMessage"?: string;
    "bookingMessage"?: string;
    "scheduleState"?: CarrierModelScheduleStateEnum;
    "bookingState"?: CarrierModelBookingStateEnum;
    "askRatesState"?: CarrierModelAskRatesStateEnum;
    "schedulesFetchedRecordId"?: number;
    "askRatesLink"?: string;
    "onlineQuoteLink"?: string;
    "askRatesByEmail"?: boolean;
    "intra"?: boolean;
    "main"?: boolean;
}

export type CarrierModelTypeEnum = "None" | "Ocean" | "FclNvocc" | "LclNvocc";
export type CarrierModelScheduleStateEnum = "Display" | "Hide" | "Home";
export type CarrierModelBookingStateEnum = "Display" | "Hide" | "Home";
export type CarrierModelAskRatesStateEnum = "Display" | "Hide" | "Home";
export interface CarrierModelExport {
    "carrierId"?: number;
    "enabled"?: boolean;
    "type"?: Array<CarrierModelExportTypeEnum>;
    "carrierGroupId"?: number;
    "name"?: string;
    "code"?: string;
    "scac"?: string;
    "contactMessage"?: string;
    "bookingMessage"?: string;
    "scheduleState"?: CarrierModelExportScheduleStateEnum;
    "bookingState"?: CarrierModelExportBookingStateEnum;
    "askRatesState"?: CarrierModelExportAskRatesStateEnum;
    "schedulesFetchedRecordId"?: number;
    "askRatesLink"?: string;
    "onlineQuoteLink"?: string;
    "askRatesByEmail"?: boolean;
    "intra"?: boolean;
    "main"?: boolean;
}

export type CarrierModelExportTypeEnum = "None" | "Ocean" | "FclNvocc" | "LclNvocc";
export type CarrierModelExportScheduleStateEnum = "Display" | "Hide" | "Home";
export type CarrierModelExportBookingStateEnum = "Display" | "Hide" | "Home";
export type CarrierModelExportAskRatesStateEnum = "Display" | "Hide" | "Home";
export interface CarrierOfferModel {
    "subscriptionId"?: number;
    "carrierId"?: number;
    "routeId"?: number;
    "workDocumentClientUploadId"?: number;
    "carrierRatesPlatformId"?: number;
    "chargeSet"?: ChargeSetModel;
    "originPort"?: LocationModel;
    "destinationPort"?: LocationModel;
    "originTerminal"?: LocationModel;
    "destinationTerminal"?: LocationModel;
    "ratesAvailabilitys"?: Array<RatesAvailabilityModel>;
    "ratesOffer"?: RatesOfferModel;
    "routeLclConfig"?: RouteLclConfigModel;
    "ddSet"?: DdSetModel;
    "chargeAgents"?: Array<ChargeAgentModel>;
    "offerInformations"?: Array<OfferInformationModel>;
    "routeConfigs"?: Array<RouteConfigModel>;
    "contacts"?: Array<ContactModel>;
}

export interface CarrierOffersWithErrors {
    "carrierOffers"?: Array<CarrierOfferModel>;
    "errorByFetcherCode"?: { [key: string]: Array<RatesFetcherErrorModel>; };
}

export interface CarrierRatesOfferInformationModel {
    "carrierRatesOfferInformationId"?: number;
    "content"?: string;
    "level"?: CarrierRatesOfferInformationModelLevelEnum;
    "creationDate"?: Date;
    "carrierId"?: number;
    "firmId"?: number;
    "authorId"?: number;
}

export type CarrierRatesOfferInformationModelLevelEnum = "Low" | "Normal" | "High";
export type CarrierPlatformType = "Fcl" | "Extension" | "Lcl";
/**
 * Returns information about Carrier Rates Platform
 */
export interface CarrierRatesPlatformModel {
    /**
     * Id of platform
     */
    "carrierRatesPlatformId"?: number;
    /**
     * Name of platform
     */
    "name"?: string;
    /**
     * Okargo internal code of platform
     */
    "code"?: string;
    /**
     * Web page for booking
     */
    "onlineQuoteLink"?: string;
    /**
     * Type of authentification
     */
    "authenticationType"?: CarrierRatesPlatformModelAuthenticationTypeEnum;
    /**
     * Status of platform
     */
    "status"?: CarrierRatesPlatformModelStatusEnum;
    /**
    * Type of platform
    */
    "type"?: CarrierPlatformType;
    /**
     *
     */
    "enabled"?: boolean;
    /**
     * Carrier of platform
     */
    "carrierId"?: number;
    /**
     * Inland carrier of platform
     */
    "inlandCarrierId"?: number;
    /**
     *
     */
    "creationDate"?: Date;
}

export type CarrierRatesPlatformModelAuthenticationTypeEnum = "Credentials" | "Token" | "NoAuth";
export type CarrierRatesPlatformModelStatusEnum = "Ok" | "BetaTest" | "NotStable" | "NotWorking";
export interface CarrierRatesPlatformsStatusModel {
    "originPortId"?: number;
    "destinationPortId"?: number;
}

export interface CarrierRatesSettingsModel {
    "carrierId"?: number;
    "firmId"?: number;
    "subOkargoId"?: number;
    /**
     * ID of setting
     */
    "carrierRatesSettingsId"?: number;
    /**
     * Login of user
     */
    "login"?: string;
    /**
     * Password
     */
    "password"?: string;
    /**
     * Token for platform
     */
    "token"?: string;
    /**
     * Additional platform parameter, f.e. Personl key
     */
    "parameters"?: string;
    /**
     * Creation date
     */
    "creationDate"?: Date;
    /**
     * Carrier platform id
     */
    "carrierRatesPlatformId"?: number;
    /**
     * WHo sets setting
     */
    "source"?: CarrierRatesSettingsModelSourceEnum;
}

export type CarrierRatesSettingsModelSourceEnum = "Admin" | "Client";
export interface CarrierRatesSettingsModelBase {
    /**
     * ID of setting
     */
    "carrierRatesSettingsId"?: number;
    /**
     * Login of user
     */
    "login"?: string;
    /**
     * Password
     */
    "password"?: string;
    /**
     * Token for platform
     */
    "token"?: string;
    /**
     * Additional platform parameter, f.e. Personl key
     */
    "parameters"?: string;
    /**
     * Creation date
     */
    "creationDate"?: Date;
    /**
     * Carrier platform id
     */
    "carrierRatesPlatformId"?: number;
    /**
     * WHo sets setting
     */
    "source"?: CarrierRatesSettingsModelBaseSourceEnum;
}

export type CarrierRatesSettingsModelBaseSourceEnum = "Admin" | "Client";
export interface CarrierRatingModel {
    "carrierRatingId"?: number;
    "label"?: string;
    "icon"?: string;
    "firmId"?: number;
    "carrierCarrierRatings"?: Array<CarrierCarrierRatingModel>;
}

export interface CarrierRoutesModel {
    "carrier"?: CarrierModel;
    "routes"?: Array<RouteExportModel>;
}

export interface ChargeAgentModel {
    "chargeAgentId"?: number;
    "chargeAgentType"?: ChargeAgentModelChargeAgentTypeEnum;
    "enabled"?: boolean;
    "amount"?: number;
    "currencyId"?: number;
    "originId"?: number;
    "origin"?: LocationModel;
    "destinationId"?: number;
    "destination"?: LocationModel;
    "authorEmail"?: string;
    "firmId"?: number;
    "sizeTypeId"?: number;
    "creationDate"?: Date;
    "name"?: string;
    "criteria"?: ChargeAgentModelCriteriaEnum;
    "unit"?: ChargeAgentModelUnitEnum;
    "application"?: ChargeAgentModelApplicationEnum;
    "modificationType"?: ChargeAgentModelModificationTypeEnum;
    "chargeNameId"?: number;
}

export type ChargeAgentModelChargeAgentTypeEnum = "Value" | "Modification";
export type ChargeAgentModelCriteriaEnum = "Hazardous" | "Owned" | "Goh" | "GohDouble" | "Nor";
export type ChargeAgentModelUnitEnum = "Specific" | "Ctr" | "Teu" | "Bl" | "Cbm" | "Ton" | "Wm" | "Decl" | "Trans";
export type ChargeAgentModelApplicationEnum = "Origin" | "Freight" | "Destination" | "General" | "Road";
export type ChargeAgentModelModificationTypeEnum = "ReplaceAmount" | "AddAmount" | "Exclude" | "Add";
export interface ChargeModel {
    "chargeId"?: number;
    "chargeType"?: ChargeModelChargeTypeEnum;
    "name"?: string;
    "amount"?: number;
    "criteria"?: Array<ChargeModelCriteriaEnum>;
    "containerType"?: ChargeModelContainerTypeEnum;
    "unit"?: ChargeModelUnitEnum;
    "application"?: ChargeModelApplicationEnum;
    "paymentOption"?: ChargeModelPaymentOptionEnum;
    "chargeSetId"?: number;
    "chargeNameId"?: number;
    "sizeTypeId"?: number;
    "currencyId"?: number;
    "type"?: ChargeModelTypeEnum;
    "sourceChargeNameId"?: number;
    "minWeight"?: number;
    "maxWeight"?: number;
    "minUnit"?: number;
    "maxUnit"?: number;
}

export type ChargeModelChargeTypeEnum = "Value" | "Source" | "Agent";
export type ChargeModelCriteriaEnum = "Hazardous" | "Owned" | "Goh" | "GohDouble" | "Nor";
export type ChargeModelContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export type ChargeModelUnitEnum = "Specific" | "Ctr" | "Teu" | "Bl" | "Cbm" | "Ton" | "Wm" | "Decl" | "Trans";
export type ChargeModelApplicationEnum = "Origin" | "Freight" | "Destination" | "General" | "Road";
export type ChargeModelPaymentOptionEnum = "Freight" | "Prepaid" | "Collect";
export type ChargeModelTypeEnum = "Incl" | "Percentage";
/**
 * Defines charge in offer
 */
export interface ChargeModelExport {
    "chargeId"?: number;
    /**
     * Type of charge
     */
    "chargeType"?: ChargeModelExportChargeTypeEnum;
    /**
     * Okargo code of charge
     */
    "name"?: string;
    "amount"?: number;
    /**
     * Defines application for a specific container
     */
    "criteria"?: Array<ChargeModelExportCriteriaEnum>;
    /**
     * Type of container
     */
    "containerType"?: ChargeModelExportContainerTypeEnum;
    /**
     * Unit of charge
     */
    "unit"?: ChargeModelExportUnitEnum;
    /**
     * Aplication of charge
     */
    "application"?: ChargeModelExportApplicationEnum;
    "chargeSetId"?: number;
    "chargeNameId"?: number;
    /**
     * Okargo charge name
     */
    "chargeName"?: string;
    /**
     * Size type of charge. Null for CTR/TEU/BL
     */
    "sizeTypeId"?: number;
    /**
     * Okargo size type code
     */
    "sizeType"?: string;
    "currencyId"?: number;
    /**
     * Okargo currency code
     */
    "currency"?: string;
    "type"?: ChargeModelExportTypeEnum;
    /**
     * Type of source charge
     */
    "sourceChargeNameId"?: number;
    /**
     * Source charge where this applied
     */
    "sourceChargeName"?: string;
    "minWeight"?: number;
    "maxWeight"?: number;
    "minUnit"?: number;
    "maxUnit"?: number;
}

export type ChargeModelExportChargeTypeEnum = "Value" | "Source" | "Agent";
export type ChargeModelExportCriteriaEnum = "Hazardous" | "Owned" | "Goh" | "GohDouble" | "Nor";
export type ChargeModelExportContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export type ChargeModelExportUnitEnum = "Specific" | "Ctr" | "Teu" | "Bl" | "Cbm" | "Ton" | "Wm" | "Decl" | "Trans";
export type ChargeModelExportApplicationEnum = "Origin" | "Freight" | "Destination" | "General" | "Road";
export type ChargeModelExportTypeEnum = "Incl" | "Percentage";
export interface ChargeNameModel {
    "chargeNameId"?: number;
    "code"?: string;
    "name"?: string;
    "shortName"?: string;
    "affectation"?: ChargeNameModelAffectationEnum;
    "application"?: ChargeNameModelApplicationEnum;
    "type"?: ChargeNameModelTypeEnum;
    "description"?: string;
    "aliases"?: Array<string>;
}

export type ChargeNameModelAffectationEnum = "BillOfLading" | "Container" | "PercentageOfTotal";
export type ChargeNameModelApplicationEnum = "Origin" | "Freight" | "Destination" | "General" | "Road";
export type ChargeNameModelTypeEnum = "Ocean" | "Inland";
export type ChargeSetFlagEnum = "None" | "PolDoor" | "PodDoor" | "WithTare";

export interface ChargeSetModel {
    "chargeSetId"?: number;
    "enabled"?: boolean;
    "ratesUnitType"?: ChargeSetModelRatesUnitTypeEnum;
    "ratesPriceType"?: ChargeSetModelRatesPriceTypeEnum;
    "invalidated"?: boolean;
    "author"?: string;
    "comment"?: string;
    "deepLink"?: string;
    "transitTime"?: number;
    "bestTransitTime"?: number;
    "quotedDate"?: Date;
    "quotValidity"?: Date;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "freightValidity"?: Date;
    "reference"?: string;
    "carrierReference"?: string;
    "clientSpot"?: boolean;
    "ratesOfferId"?: number;
    "freetime"?: number;
    "firmId"?: number;
    "containerType"?: ChargeSetModelContainerTypeEnum;
    "trafficType"?: ChargeSetModelTrafficTypeEnum;
    "carrierId"?: number;
    "routeSuggestedCalcId"?: number;
    "ratesFetchedRecordId"?: number;
    "source"?: string;
    "routeServiceId"?: number;
    "routeService"?: RouteServiceModel;
    "ratesServiceRelation"?: ChargeSetModelRatesServiceRelationEnum;
    "workDocumentsId"?: number;
    "receptionDate"?: Date;
    "creationDate"?: Date;
    "marketId"?: number;
    "carrierInfo"?: string;
    "routeId"?: number;
    "charges"?: Array<ChargeModel>;
    "departs"?: Array<DepartModel>;
    "transShipments"?: Array<TransshipmentModel>;
    "offerInformations"?: Array<OfferInformationModel>;
    "flags"?: Array<ChargeSetFlagEnum>;
}

export type ChargeSetModelRatesUnitTypeEnum = "Fcl" | "Lcl";
export type ChargeSetModelRatesPriceTypeEnum = "Contract" | "Spot";
export type ChargeSetModelContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export type ChargeSetModelTrafficTypeEnum = "Export" | "Import" | "CrossTrade";
export type ChargeSetModelRatesServiceRelationEnum = "NotAttached" | "Attached";
/**
 * Represent rates
 */
export interface ChargeSetModelExport {
    "chargeSetId"?: number;
    "enabled"?: boolean;
    /**
     * Type of units
     */
    "ratesUnitType"?: ChargeSetModelExportRatesUnitTypeEnum;
    /**
     * Rates price type
     */
    "ratesPriceType"?: ChargeSetModelExportRatesPriceTypeEnum;
    "invalidated"?: boolean;
    "author"?: string;
    "comment"?: string;
    /**
     * Booking link if it provided
     */
    "deepLink"?: string;
    "source"?: string;
    /**
     * Validity begin for charges
     */
    "dateBegin"?: Date;
    /**
     * Validity end for charges
     */
    "dateEnd"?: Date;
    /**
     * Validity of freight rates
     */
    "freightValidity"?: Date;
    /**
     * Time of quotation creation
     */
    "quotedDate"?: Date;
    /**
     * Validity of quote
     */
    "quotValidity"?: Date;
    /**
     * Reference of rates
     */
    "reference"?: string;
    /**
     * Estimated transit time
     */
    "transitTime"?: number;
    /**
     * Best transit time for route
     */
    "bestTransitTime"?: number;
    "carrierReference"?: string;
    "clientSpot"?: boolean;
    /**
     *
     */
    "ratesOfferId"?: number;
    "ddSetId"?: number;
    "freetime"?: number;
    "firmId"?: number;
    /**
     * Type of container
     */
    "containerType"?: ChargeSetModelExportContainerTypeEnum;
    /**
     * Type of traffic
     */
    "trafficType"?: ChargeSetModelExportTrafficTypeEnum;
    "carrierId"?: number;
    "routeSuggestedCalcId"?: number;
    "workDocumentsId"?: number;
    "receptionDate"?: Date;
    "creationDate"?: Date;
    "marketId"?: number;
    /**
     * Charges for offer
     */
    "charges"?: Array<ChargeModelExport>;
}

export type ChargeSetModelExportRatesUnitTypeEnum = "Fcl" | "Lcl";
export type ChargeSetModelExportRatesPriceTypeEnum = "Contract" | "Spot";
export type ChargeSetModelExportContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export type ChargeSetModelExportTrafficTypeEnum = "Export" | "Import" | "CrossTrade";
export interface ClientModel {
    "clientId"?: number;
    "cgu"?: boolean;
    "firstName"?: string;
    "lastName"?: string;
    "clientType"?: ClientModelClientTypeEnum;
    "phone1"?: string;
    "phone2"?: string;
    "creationDate"?: Date;
    "defaultLoading"?: boolean;
    "defaultUnLoading"?: boolean;
    "defaultMultiPorts"?: boolean;
    "defaultLastRates"?: boolean;
    "defaultSuggested"?: boolean;
    "defaultContainerPrice"?: boolean;
    "defaultByRatesOffer"?: boolean;
    "defaultAllMarkets"?: boolean;
    "defaultCarrierRates"?: boolean;
    "defaultHideNoRates"?: boolean;
    "defaultFreetime"?: boolean;
    "downloadMemo"?: boolean;
    "currencyId"?: number;
    "inlandCurrencyId"?: number;
    "languageId"?: number;
    "defaultQuotationContactId"?: number;
    "accountId"?: number;
    "account"?: AccountModel;
    "subscriptionId"?: number;
    "quotationClientSettings"?: QuotationClientSettingsModel;
    "subscriptions"?: Array<SubscriptionModel>;
    "agencies"?: Array<FirmModel>;
}

export type ClientModelClientTypeEnum = "Ff" | "Carrier" | "Other";
export interface ContactModel {
    "contactId"?: number;
    "contactType"?: ContactModelContactTypeEnum;
    "carrierId"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "email1"?: string;
    "email2"?: string;
    "phone1"?: string;
    "phone2"?: string;
    "comment"?: string;
    "type"?: ContactModelTypeEnum;
    "firmId"?: number;
    "carrierAgency"?: string;
    "carrierAgencyId"?: number;
}

export type ContactModelContactTypeEnum = "Carrier" | "Agent";
export type ContactModelTypeEnum = "Sales" | "Booking" | "Both";
export interface CreateMultiPortOffersModel {
    "multiPortCarrierOffers"?: Array<MultiPortCarrierOfferModel>;
    "criteriaCode"?: string;
    "currencyId"?: number;
}

export interface CriteriaAgentModel {
    "criteriaAgentId"?: number;
    "shipperName"?: string;
    "consigneeName"?: string;
    "isSpotOperation"?: boolean;
    "volume"?: string;
    "incoterm"?: string;
    "cargoDescription"?: string;
    "comment"?: string;
}

export type CriteriaModelLocationShippingTypeEnum = "Cy" | "Door";

export interface CriteriaModel {
    "criteriaId"?: number;
    "code"?: string;
    "type"?: CriteriaModelTypeEnum;
    "containerType"?: CriteriaModelContainerTypeEnum;
    "origin"?: LocationModel;
    "destination"?: LocationModel;
    "originShippingType"?: CriteriaModelLocationShippingTypeEnum;
    "destinationShippingType"?: CriteriaModelLocationShippingTypeEnum;
    "loadingCharge"?: boolean;
    "prepaidCharge"?: boolean;
    "unLoadingCharge"?: boolean;
    "collectCharge"?: boolean;
    "containerPrice"?: boolean;
    "allDeparts"?: boolean;
    "lastRates"?: boolean;
    "suggested"?: boolean;
    "allMarkets"?: boolean;
    "showFreeTime"?: boolean;
    "includeSellingRates"?: boolean;
    "includeCarrierRates"?: boolean;
    "cbm"?: number;
    "ton"?: number;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "creationDate"?: Date;
    "criteriaAgent"?: CriteriaAgentModel;
    "criteriaSizeTypes"?: Array<CriteriaSizeTypeModel>;
    "criteriaSubscriptions"?: Array<CriteriaSubscriptionModel>;
    "chargeCriterias"?: Array<CriteriaModelChargeCriteriasEnum>;
}

export type CriteriaModelTypeEnum = "Fcl" | "Lcl";
export type CriteriaModelContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export type CriteriaModelChargeCriteriasEnum = "Hazardous" | "Owned" | "Goh" | "GohDouble" | "Nor";
export interface CriteriaSizeTypeModel {
    "criteriaSizeTypeId"?: number;
    "number"?: number;
    "weight"?: number;
    "weightUnit"?: CriteriaSizeTypeModelWeightUnitEnum;
    "criteriaId"?: number;
    "sizeTypeId"?: number;
}

export type CriteriaSizeTypeModelWeightUnitEnum = "Kg" | "Ton";
export interface CriteriaSubscriptionModel {
    "criteriaSubscriptionId"?: number;
    "criteriaId"?: number;
    "subscriptionId"?: number;
}

export interface CurrencyModel {
    "currencyId"?: number;
    "name"?: string;
    "code"?: string;
    "symbol"?: string;
    "value"?: number;
    "primary"?: boolean;
}

export interface CurrentUserModel {
    "expiration"?: Date;
    "clientModel"?: ClientModel;
}

export interface DdChargeModel {
    "ddChargeId"?: number;
    "enabled"?: boolean;
    "amount"?: number;
    "amountType"?: DdChargeModelAmountTypeEnum;
    "currencyId"?: number;
    "containerType"?: DdChargeModelContainerTypeEnum;
    "unit"?: DdChargeModelUnitEnum;
    "application"?: DdChargeModelApplicationEnum;
    "criteria"?: Array<DdChargeModelCriteriaEnum>;
    "ddSetId"?: number;
    "ddChargeNameId"?: number;
    "dayCountCategory"?: DdChargeModelDayCountCategoryEnum;
    "sizeTypeId"?: number;
    "fromDay"?: number;
    "untilDay"?: number;
}

export type DdChargeModelAmountTypeEnum = "Value" | "Free";
export type DdChargeModelContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export type DdChargeModelUnitEnum = "Specific" | "Ctr" | "Teu" | "Bl" | "Cbm" | "Ton" | "Wm" | "Decl" | "Trans";
export type DdChargeModelApplicationEnum = "Origin" | "Freight" | "Destination" | "General" | "Road";
export type DdChargeModelCriteriaEnum = "Hazardous" | "Owned" | "Goh" | "GohDouble" | "Nor";
export type DdChargeModelDdChargeNameIdEnum = "Detention" | "Demurage" | "DetAndDemComplete" | "PlugIn" | "Bt" | "Chassis";
export type DdChargeModelDayCountCategoryEnum = "Cal" | "Wrk";
export interface DdChargeNameModel {
    "ddChargeNameId"?: number;
    "name"?: string;
    "code"?: string;
}

/**
 * Freetime representation
 */
export interface DdSetModel {
    "ddSetId"?: number;
    "enabled"?: boolean;
    "creationDate"?: Date;
    "carrierId"?: number;
    /**
     * Contract reference
     */
    "reference"?: string;
    "ratesOfferId"?: number;
    /**
     * Origin port where are charges applied
     */
    "originPortId"?: number;
    /**
     * Origin country where are charges applied
     */
    "originCountryId"?: number;
    /**
     * Destination port where are charges applied
     */
    "destinationPortId"?: number;
    /**
     * Destination country where are charges applied
     */
    "destinationCountryId"?: number;
    /**
     * Validity start
     */
    "dateBegin"?: Date;
    /**
     * Validity end
     */
    "dateEnd"?: Date;
    "firmId"?: number;
    "charges"?: Array<DdChargeModel>;
}

export interface DepartModel {
    "departId"?: number;
    "enabled"?: boolean;
    "etd"?: Date;
    "transitTime"?: number;
    "uid"?: string;
    "defaultTransitTime"?: number;
    "vessel"?: VesselModel;
    "vesselId"?: number;
    "service"?: string;
    "source"?: DepartModelSourceEnum;
    "chargeSetId"?: number;
    "eta"?: Date;
}

export type DepartModelSourceEnum = "Okargo" | "RatesApi" | "SchedulesApi" | "AutoGenerated" | "Guessed" | "Virtual";
export interface DownloadRecentMultiPortModel {
    "multiPortInstanceId"?: number;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
}

export interface EditPasswordModel {
    "oldPassword"?: string;
    "newPassword"?: string;
    "newPasswordConf"?: string;
}

export interface ErrorReportModel {
    "lastAction"?: ActionModel;
    "message"?: string;
    "state"?: any;
}

export interface ExportBulkModel {
    /**
     * Start of the date range used to filter the offers
     */
    "dateBegin"?: Date;
    /**
     * End of the date range used to filter the offers
     */
    "dateEnd"?: Date;
    /**
     * Un/locode of the port you want to export your rates from
     */
    "portCode"?: string;
    /**
     * Type of traffic to filter the rates: 'Export' or 'Import'
     */
    "trafficType"?: ExportBulkModelTrafficTypeEnum;
    /**
     * How many routes to skip to fragment the export in multiple request (if needed)
     */
    "skip"?: number;
    /**
     * How many routes to take to fragment the export in multiple request (if needed)
     */
    "take"?: number;
    /**
     * What type of rates you want: 'Lcl' or 'Fcl' (default Fcl)
     */
    "ratesUnitType"?: ExportBulkModelRatesUnitTypeEnum;
}

export type ExportBulkModelTrafficTypeEnum = "Export" | "Import" | "CrossTrade";
export type ExportBulkModelRatesUnitTypeEnum = "Fcl" | "Lcl";
export interface ExportBulkResultModel {
    "port"?: LocationModel;
    "trafficType"?: ExportBulkResultModelTrafficTypeEnum;
    "carrierRoutes"?: Array<CarrierRoutesModel>;
    "routesCount"?: number;
}

export type ExportBulkResultModelTrafficTypeEnum = "Export" | "Import" | "CrossTrade";
export interface ExportDataResultModel {
    "success"?: boolean;
    "message"?: string;
}

export interface ExportOfferModel {
    "identification"?: string;
    "additionalData1"?: string;
    "additionalData2"?: string;
    "departId"?: number;
    "chargeSetId"?: number;
    "currencyId"?: number;
    "criteriaCode"?: string;
}

export interface ExportOfferToTmsModel {
    "departId"?: number;
    "chargeSetId"?: number;
    "currencyId"?: number;
    "criteriaCode"?: string;
}

export interface ExternalChargeModel {
    "chargeName"?: string;
    "chargeCode"?: string;
    "amount"?: number;
    "currencyCode"?: string;
    "currencyName"?: string;
    "sourceChargeName"?: string;
    "sourceChargeCode"?: string;
    "sourceType"?: ExternalChargeModelSourceTypeEnum;
    "application"?: ExternalChargeModelApplicationEnum;
    "unit"?: ExternalChargeModelUnitEnum;
    "containerSize"?: ExternalChargeModelContainerSizeEnum;
    "containerType"?: ExternalChargeModelContainerTypeEnum;
    "paymentOption"?: ExternalChargeModelPaymentOptionEnum;
}

export type ExternalChargeModelSourceTypeEnum = "Incl" | "Percentage";
export type ExternalChargeModelApplicationEnum = "Origin" | "Freight" | "Destination" | "General" | "Road";
export type ExternalChargeModelUnitEnum = "Specific" | "Ctr" | "Teu" | "Bl" | "Cbm" | "Ton" | "Wm" | "Decl" | "Trans";
export type ExternalChargeModelContainerSizeEnum = "S20" | "S40" | "S40H" | "S45";
export type ExternalChargeModelContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export type ExternalChargeModelPaymentOptionEnum = "Freight" | "Prepaid" | "Collect";
export interface ExternalChargeSetModel {
    "reference"?: string;
    "isSpot"?: boolean;
    "startValidity"?: Date;
    "endValidity"?: Date;
    "offerInformations"?: Array<OfferInformationModel>;
    "charges"?: Array<ExternalChargeModel>;
    "departs"?: Array<ExternalDepartModel>;
}

export interface ExternalDepartModel {
    "etd"?: Date;
    "vesselName"?: string;
    "vesselCode"?: string;
}

export interface ExternalDocumentsUpload {
    "externalPartner"?: string;
    "externalKey"?: string;
    "carrierScac"?: string;
    "created"?: Date;
    "description"?: string;
    "files"?: Array<UploadedFile>;
}

export interface ExternalFetchLocationModel {
    "code"?: string;
    "countryCode"?: string;
    "countryName"?: string;
    "name"?: string;
}

export interface ExternalFetchRouteModel {
    "routeId"?: number;
    "pol"?: ExternalFetchLocationModel;
    "pod"?: ExternalFetchLocationModel;
    "weight"?: number;
}

export interface ExternalRatesModel {
    "routeId"?: number;
    "carrierCode"?: string;
    "fetcherCode"?: string;
    "chargeSets"?: Array<ExternalChargeSetModel>;
}

export interface FirmDocumentModel {
    "firmDocumentId"?: number;
    "name"?: string;
    "carrierId"?: number;
    "firmId"?: number;
    "firm"?: FirmModel;
    "source"?: FirmDocumentModelSourceEnum;
    "fileName"?: string;


}

export type FirmDocumentModelSourceEnum = "Client" | "Admin";
export interface FirmModel {
    "firmType"?: FirmModelFirmTypeEnum;
    "firmId"?: number;
    "status"?: FirmModelStatusEnum;
    "enabled"?: boolean;
    "name"?: string;
    "timePlanned"?: number;
    "serviceUrl"?: string;
    "ftpLogin"?: string;
    "ftpPassword"?: string;
    "okargoPhone"?: string;
    "okargoEmail"?: string;
    "dataFormat"?: FirmModelDataFormatEnum;
    "quotationSettingses"?: Array<QuotationSettingsModel>;
    "type"?: FirmModelTypeEnum;
    "companyGroupId"?: number;
    "quotationsCount"?: number;
    "prenium"?: boolean;
    "carrierAccess"?: boolean;
    "suggestedRates"?: boolean;
    "carrierRates"?: boolean;
    "allowQuotation"?: boolean;
    "allowInland"?: boolean;
    "allowLcl"?: boolean;
    "allowRatesExtraction"?: boolean;
    "companyGroup"?: FirmModel;
    "companyId"?: number;
    "company"?: FirmModel;
    "address"?: AddressModel;
    "agencyMarkets"?: Array<AgencyMarketModel>;
}

export type FirmModelFirmTypeEnum = "CompanyGroup" | "Company" | "Agency";
export type FirmModelStatusEnum = "Normal" | "Vip" | "Tester";
export type FirmModelDataFormatEnum = "Xml" | "Csv" | "Json";
export type FirmModelTypeEnum = "Normal";
export interface FirmNewsModel {
    "firmNewsId"?: number;
    "title"?: string;
    "content"?: string;
    "level"?: FirmNewsModelLevelEnum;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "creationDate"?: Date;
    "firmId"?: number;
    "firm"?: FirmModel;
    "originZoneId"?: number;
    "destinationZoneId"?: number;
}

export type FirmNewsModelLevelEnum = "Low" | "Normal" | "Important";
export interface FormModel {
    "action"?: string;
    "method"?: string;
    "contentType"?: string;
    "parameters"?: Array<KeyValuePairStringString>;
}

export interface FrequencyModel {
    "frequencyId"?: number;
    "name"?: string;
    "code"?: number;
    "monday"?: boolean;
    "tuesday"?: boolean;
    "wednesday"?: boolean;
    "thursday"?: boolean;
    "friday"?: boolean;
    "saturday"?: boolean;
    "sunday"?: boolean;
}

export interface GetOnlineCarrierOffersModel {
    "criteria"?: CriteriaModel;
    "ratesFetchers"?: Array<string>;
}

export interface GetOnlineInlandCarrierOffersModel {
    "inlandCriteria"?: InlandCriteriaModel;
    "ratesFetchers"?: Array<string>;
}

export interface InlandCarrierModel {
    "inlandCarrierId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "code"?: string;
    "scacCode"?: string;
    "type"?: InlandCarrierModelTypeEnum;
}

export type InlandCarrierModelTypeEnum = "Normal";
export interface InlandCarrierOfferModel {
    "subscriptionId"?: number;
    "inlandCarrierId"?: number;
    "pickUpFound"?: string;
    "dropOffFound"?: string;
    "inlandChargeSet"?: InlandChargeSetModel;
    "inlandRatesOffer"?: InlandRatesOfferModel;
    "inlandRoute"?: InlandRouteModel;
    "offerInformations"?: Array<OfferInformationModel>;
}

export interface InlandCarrierOffersWithErrors {
    "inlandCarrierOffers"?: Array<InlandCarrierOfferModel>;
    "errorByFetcherCode"?: { [key: string]: Array<RatesFetcherErrorModel>; };
}

export interface InlandChargeSetModel {
    "inlandChargeSetId"?: number;
    "enabled"?: boolean;
    "reference"?: string;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "transportValidity"?: Date;
    "creationDate"?: Date;
    "firmId"?: number;
    "inlandRouteId"?: number;
    "inlandWorkDocumentsId"?: number;
    "pickupEmptyName"?: string;
    "charges"?: Array<ChargeModel>;
}

export interface InlandCriteriaModel {
    "inlandCriteriaId"?: number;
    "code"?: string;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "creationDate"?: Date;
    "includeCarrierRates"?: boolean;
    "priceDisplayMode"?: InlandCriteriaModelPriceDisplayModeEnum;
    "containerType"?: InlandCriteriaModelContainerTypeEnum;
    "originId"?: number;
    "origin"?: LocationModel;
    "destinationId"?: number;
    "destination"?: LocationModel;
    "inlandCriteriaSizeTypes"?: Array<InlandCriteriaSizeTypeModel>;
    "chargeCriterias"?: Array<InlandCriteriaModelChargeCriteriasEnum>;
}

export type InlandCriteriaModelPriceDisplayModeEnum = "AllIn" | "ByCtr";
export type InlandCriteriaModelContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export type InlandCriteriaModelChargeCriteriasEnum = "Hazardous" | "Owned" | "Goh" | "GohDouble" | "Nor";
export interface InlandCriteriaSizeTypeModel {
    "inlandCriteriaSizeTypeId"?: number;
    "number"?: number;
    "inlandCriteriaId"?: number;
    "sizeTypeId"?: number;
}

export interface InlandRatesOfferModel {
    "inlandRatesOfferId"?: number;
    "enabled"?: boolean;
    "type"?: InlandRatesOfferModelTypeEnum;
    "shortName"?: string;
    "reference"?: string;
    "comment"?: string;
    "inlandCarrierId"?: number;
    "firmId"?: number;
}

export type InlandRatesOfferModelTypeEnum = "AllCtr" | "Special";
export interface InlandRouteModel {
    "inlandRouteId"?: number;
    "enabled"?: boolean;
    "type"?: InlandRouteModelTypeEnum;
    "pickupType"?: InlandRouteModelPickupTypeEnum;
    "inlandCarrierId"?: number;
    "pickupEmptyLocationId"?: number;
    "pickupEmptyLocation"?: LocationModel;
    "pickupEmptyName"?: string;
    "pickupLocationId"?: number;
    "pickupLocation"?: LocationModel;
    "dropOffLocationId"?: number;
    "dropOffLocation"?: LocationModel;
    "creationDate"?: Date;
}

export type InlandRouteModelTypeEnum = "TruckRoundTrip" | "TruckOneWay" | "BargeTruckOneWay" | "RailTruckOneWay";
export type InlandRouteModelPickupTypeEnum = "Cargo" | "Empty";
export interface InlandSelectionModel {
    "clientActionId"?: number;
    "code"?: string;
    "inlandCarrierOffers"?: Array<InlandCarrierOfferModel>;
}

export interface KeyValuePairStringString {
    "key"?: string;
    "value"?: string;
}

export interface LanguageModel {
    "languageId"?: number;
    "name"?: string;
    "code"?: string;
    "supported"?: boolean;
}

export interface LocationDistanceModel {
    "location"?: LocationModel;
    "distance"?: number;
}

export interface TransshipmentModel {
    "port": LocationModel,
    "depart": DepartModel,
}


/**
 * Extended location
 */
export interface LocationModel {
    /**
     * UnLocode of location
     */
    "unLocode"?: string;
    /**
     * Type of location
     */
    "locationType"?: LocationModelLocationTypeEnum;
    "type"?: LocationModelTypeEnum;
    "status"?: LocationModelStatusEnum;
    "name"?: string;
    /**
     * Location country
     */
    "country"?: LocationModel;
    "port"?: LocationModel;
    "portsZoneId"?: number;
    "latitude"?: number;
    "longitude"?: number;
    "currencyId"?: number;
    "googlePlaceId"?: string;
    "inlandPlaceZipCodes"?: Array<string>;
    "system"?: LocationModelSystemEnum;
    "portsInGroup"?: Array<LocationModel>;
    "systemCode"?: string;
    /**
     * Id of location
     */
    "locationId"?: number;
    /**
     * Okargo code of location
     */
    "code"?: string;
}

export type LocationModelLocationTypeEnum = "Port" | "Terminal" | "Country" | "InlandPlace" | "InlandLocationGroup" | "PortGroup";
export type LocationModelTypeEnum = "Water" | "Dry" | "Special";
export type LocationModelStatusEnum = "Secondary" | "Main";
export type LocationModelSystemEnum = "Lamy" | "Google";
/**
 * Base description of Okargo location
 */
export interface LocationModelBase {
    /**
     * Id of location
     */
    "locationId"?: number;
    /**
     * Okargo code of location
     */
    "code"?: string;
}

export interface LoginModel {
    "username"?: string;
    "password"?: string;
    "returnUrl"?: string;
    "toPostData"?: Array<KeyValuePairStringString>;
}

export interface MarketModel {
    "marketId"?: number;
    "name"?: string;
    "code"?: string;
    "country"?: LocationModel;
    "port"?: LocationModel;
}

export interface MonthStatsPriceModel {
    "year"?: number;
    "month"?: number;
    "sizeTypePriceAverages"?: Array<SizeTypePriceAverageModel>;
}

export interface MultiPortCarrierOfferModel {
    "routeConfigId"?: number;
    "chargeSetId"?: number;
    "ddSetId"?: number;
}

export interface MultiPortInstanceModel {
    "multiPortInstanceId"?: number;
    "name"?: string;
    "status"?: MultiPortInstanceModelStatusEnum;
    "creationDate"?: Date;
    "clientId"?: number;
    "multiPortOffersCount"?: number;
    "multiPortOffers"?: Array<MultiPortOfferModel>;
}

export type MultiPortInstanceModelStatusEnum = "Created" | "Selecting" | "Downloaded" | "Canceled";
export interface MultiPortOfferModel {
    "multiPortOfferId"?: number;
    "carrierId"?: number;
    "routeConfig"?: RouteConfigModel;
    "originPort"?: LocationModel;
    "destinationPort"?: LocationModel;
    "originTerminal"?: LocationModel;
    "destinationTerminal"?: LocationModel;
    "chargeSet"?: ChargeSetModel;
    "ratesOffer"?: RatesOfferModel;
    "criteria"?: CriteriaModel;
    "ddSet"?: DdSetModel;
    "currencyId"?: number;
}

/**
 * Additional information about workdocument/chargeSet
 */
export interface OfferInformationModel {
    "offerInformationId"?: number;
    "enabled"?: boolean;
    /**
     * Source of information
     */
    "source"?: OfferInformationModelSourceEnum;
    "content"?: string;
    /**
     * Level of priority
     */
    "level"?: OfferInformationModelLevelEnum;
    "creationDate"?: Date;
    "workDocumentsId"?: number;
    "chargeSetId"?: number;
}

export type OfferInformationModelSourceEnum = "Client" | "Admin" | "Carrier";
export type OfferInformationModelLevelEnum = "Low" | "Normal" | "High";
export interface OfferMemoModel {
    "offerMemoId"?: number;
    "carrierId"?: number;
    "chargeSetId"?: number;
    "chargeSet"?: ChargeSetModel;
    "departId"?: number;
    "depart"?: DepartModel;
    "routeConfig"?: RouteConfigModel;
    "originPort"?: LocationModel;
    "destinationPort"?: LocationModel;
    "originTerminal"?: LocationModel;
    "destinationTerminal"?: LocationModel;
    "criteria"?: CriteriaModel;
    "subscriptionId"?: number;
    "currencyId"?: number;
    "creationDate"?: Date;
}

export interface OfferSelectionModel {
    "chargeSetId"?: number;
    "departId"?: number;
    "currencyId"?: number;
    "criteriaCode"?: string;
}

export interface OkargoHubUserModel {
    "username"?: string;
    "connectionId"?: string;
}

/**
 * Represent rates for carrier
 */
export interface OnlineCarrierOfferExportModel {
    "carrier"?: CarrierModelExport;
    /**
     * List of offers
     */
    "offers"?: Array<OnlineOfferExportModel>;
}

/**
 * Route information
 */
export interface OnlineCarrierOfferRouteExportModel {
    /**
     * Code of service
     */
    "serviceCode"?: string;
    /**
     * Transit time in days
     */
    "transitTime"?: number;
    /**
     * Best transit time for route if is available
     */
    "bestTransitTime"?: number;
    /**
     * Origin location
     */
    "originPort"?: LocationModel;
    /**
     * Destination location
     */
    "destinationPort"?: LocationModel;
    /**
     * Origin terminal if is available
     */
    "originTerminal"?: LocationModel;
    /**
     * Destination terminal if is available
     */
    "destinationTerminal"?: LocationModel;
    /**
     * Departs for service
     */
    "departs"?: Array<DepartModel>;
    /**
     * Transhipment ports
     */
    "transShipments"?: Array<TransshipmentModel>;
}

/**
 * Request model for rates and online quotes
 */
export interface OnlineCarrierOffersExportCriteriaModel {
    /**
     * Definies port of loading
     */
    "origin"?: LocationModelBase;
    /**
     * Definies port of discharge
     */
    "destination"?: LocationModelBase;
    /**
     * Begin of validity
     */
    "dateBegin"?: Date;
    /**
     * End of validity
     */
    "dateEnd"?: Date;
    /**
     * Defines special application for a specific container
     */
    "chargeCriterias"?: Array<OnlineCarrierOffersExportCriteriaModelChargeCriteriasEnum>;
    /**
     * Definies if value of charge is applied on a specific equipment/container type
     */
    "containerType"?: OnlineCarrierOffersExportCriteriaModelContainerTypeEnum;
    "ratesFetchers"?: Array<string>;
    /**
     * Code for specific fetcher.
     */
    "ratesFetcher"?: string;
    /**
     * Returns free time if it is available
     */
    "freeTime"?: boolean;
    /**
     * Requested size types
     */
    "sizeTypes"?: Array<OnlineCarrierOffersExportSizeTypeModel>;
}

export type OnlineCarrierOffersExportCriteriaModelChargeCriteriasEnum = "Hazardous" | "Owned" | "Goh" | "GohDouble" | "Nor";
export type OnlineCarrierOffersExportCriteriaModelContainerTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
/**
 * Provides offers for specific get rates operation
 */
export interface OnlineCarrierOffersExportModel {
    /**
     * Created time
     */
    "created"?: Date;
    /**
     * Succes of operation
     */
    "success"?: boolean;
    /**
     * Global error message
     */
    "message"?: string;
    /**
     * Status code
     */
    "code"?: number;
    "carrierOffers"?: Array<OnlineCarrierOfferExportModel>;
    /**
     * Specific error message for fetchers
     */
    "errorByFetcherCode"?: { [key: string]: string; };
}

/**
 * Defines container size. Id or code required
 */
export interface OnlineCarrierOffersExportSizeTypeModel {
    /**
     * ID of sizetype
     */
    "sizeTypeId"?: number;
    /**
     * Name of size type
     */
    "name"?: string;
    /**
     * Weight of cargo
     */
    "weight"?: number;
    /**
     * Unit of weight
     */
    "weightUnit"?: OnlineCarrierOffersExportSizeTypeModelWeightUnitEnum;
}

export type OnlineCarrierOffersExportSizeTypeModelWeightUnitEnum = "Kg" | "Ton";
/**
 * Represent one offer from carrier
 */
export interface OnlineOfferExportModel {
    /**
     * Represent charges in offer
     */
    "chargeSet"?: ChargeSetModelExport;
    /**
     * Freetime
     */
    "ddSet"?: DdSetModel;
    /**
     * Avaibility of rates for sizes
     */
    "ratesAvailabilitys"?: Array<RatesAvailabilityModel>;
    /**
     *
     */
    "routes"?: Array<OnlineCarrierOfferRouteExportModel>;
    /**
     * Additional information about rates
     */
    "offerInformations"?: Array<OfferInformationModel>;
    /**
     * Information about contract
     */
    "ratesOffer"?: RatesOfferModel;
}

export interface PortsZoneModel {
    "portsZoneId"?: number;
    "name"?: string;
    "code"?: string;
}

export interface QuotationChargeAmountModel {
    "quotationChargeAmountId"?: number;
    "sizeTypeId"?: number;
    "amount"?: number;
    "level"?: number;
    "originalChargeId"?: number;
}

export interface QuotationChargeNameLevels {
    chargeName: ChargeNameModel;
    levels: number;
    currencyId: number;
}

export interface QuotationChargeModel {
    "quotationChargeId"?: number;
    "visible"?: boolean;
    "quotationId"?: number;
    "chargeNameId"?: number;
    "level": number;
    "application"?: QuotationChargeModelApplicationEnum;
    "quotationChargeAmounts"?: Array<QuotationChargeAmountModel>;
}

export type QuotationChargeModelApplicationEnum = "Origin" | "Freight" | "Destination" | "General" | "Road";
export interface QuotationClientSettingsModel {
    "quotationClientSettingsId"?: number;
    "clientId"?: number;
    "clientType"?: QuotationClientSettingsModelClientTypeEnum;
    "incoterm"?: string;
    "hideIncluded"?: boolean;
    "showCarrier"?: boolean;
}

export type QuotationClientSettingsModelClientTypeEnum = "Client" | "Prospect" | "Agent" | "None";
export interface QuotationContactModel {
    "quotationContactId"?: number;
    "type"?: QuotationContactModelTypeEnum;
    "clientType"?: QuotationContactModelClientTypeEnum;
    "company"?: string;
    "agency"?: string;
    "firstName"?: string;
    "lastName"?: string;
    "street1"?: string;
    "street2"?: string;
    "street3"?: string;
    "city"?: string;
    "postalCode"?: string;
    "country"?: string;
    "email"?: string;
    "phone1"?: string;
    "phone2"?: string;
}

export type QuotationContactModelTypeEnum = "FreightForwarder" | "Client" | "Okargo";
export type QuotationContactModelClientTypeEnum = "Client" | "Prospect" | "Agent" | "None";
export interface QuotationCurrencyModel {
    "quotationCurrencyId"?: number;
    "value"?: number;
    "currencyId"?: number;
    "quotationId"?: number;
    "displayType"?: QuotationCurrencyModelDisplayTypeEnum;
}

export type QuotationCurrencyModelDisplayTypeEnum = "ToUsd" | "FromUsd";
export interface QuotationDocumentModel {
    "quotationDocumentId"?: number;
    "quotationId"?: number;
    "documentId"?: number;
}

export interface QuotationFeeAmountModel {
    "quotationFeeAmountId"?: number;
    "baseAmount"?: number;
    "amount"?: number;
    "label"?: string;
    "currencyId"?: number;
    "sizeTypeId"?: number;
    "quotationFeeId"?: number;
}

export interface QuotationFeeLabelModel {
    "quotationChargeLabelId"?: number;
    "content"?: string;
    "quotationFeeId"?: number;
}

export interface QuotationFeeModel {
    "quotationFeeId"?: number;
    "name"?: string;
    "unit"?: string;
    "visible"?: boolean;
    "type"?: QuotationFeeModelTypeEnum;
    "quotationId"?: number;
    "quotationOfferId"?: number;
    "application"?: QuotationFeeModelApplicationEnum;
    "affectation"?: QuotationFeeModelAffectationEnum;
    "quotationFeeAmounts"?: Array<QuotationFeeAmountModel>;
    "quotationFeeLabels"?: Array<QuotationFeeLabelModel>;
}

export type QuotationFeeModelTypeEnum = "Normal" | "Origin" | "Freight" | "Destination" | "PreTransport" | "PostTransport" | "Bl" | "BlOrigin" | "BlFreight" | "BlDestination" | "BlPreTransport" | "BlPostTransport";
export type QuotationFeeModelApplicationEnum = "Origin" | "Freight" | "Destination" | "General" | "Road";
export type QuotationFeeModelAffectationEnum = "BillOfLading" | "Container" | "PercentageOfTotal";
export interface QuotationFilterModel {
    "reference"?: string;
    "dateBegin"?: Date;
    "dateEnd"?: Date;
    "client"?: string;
    "freightForwarder"?: string;
    "trafficType"?: QuotationFilterModelTrafficTypeEnum;
    "origin"?: LocationModel;
    "destination"?: LocationModel;
    "carrierId"?: number;
}

export type QuotationFilterModelTrafficTypeEnum = "Export" | "Import" | "CrossTrade";
export interface QuotationModel {
    "quotationId"?: number;
    "type"?: QuotationModelTypeEnum;
    "status"?: QuotationModelStatusEnum;
    "preview"?: boolean;
    "name"?: string;
    "reference"?: string;
    "showExchangeRates"?: boolean;
    "showCarrier"?: boolean;
    "byContainer"?: boolean;
    "terms"?: string;
    "addInfo"?: string;
    "detailsLevel"?: QuotationModelDetailsLevelEnum;
    "totalType"?: QuotationModelTotalTypeEnum;
    "originInfo"?: string;
    "destinationInfo"?: string;
    "fobOrigin"?: string;
    "fobDestination"?: string;
    "frtOrigin"?: string;
    "frtDestination"?: string;
    "destinationOrigin"?: string;
    "destinationDestination"?: string;
    "incoterm"?: string;
    "incotermLocation"?: string;
    "serviceName"?: string;
    "cargoDesc"?: string;
    "weight"?: string;
    "minTransitTime"?: number;
    "maxTransitTime"?: number;
    "transShipments"?: string;
    "frequency"?: QuotationModelFrequencyEnum;
    "validityBegin"?: Date;
    "validityEnd"?: Date;
    "creationDate"?: Date;
    "receptionDate"?: Date;
    "reminderDate"?: Date;
    "success"?: boolean;
    "comment"?: string;
    "firmScope"?: QuotationModelFirmScopeEnum;
    "languageId"?: number;
    "subscriptionId"?: number;
    "ffContactId"?: number;
    "ffContact"?: QuotationContactModel;
    "clientContactId"?: number;
    "clientContact"?: QuotationContactModel;
    "quotationTemplateSettings"?: QuotationTemplateSettingsModel;
    "criteria"?: CriteriaModel;
    "currencyId"?: number;
    "currencyOriginId"?: number;
    "currencyFreightId"?: number;
    "currencyDestinationId"?: number;
    "termsDocumentId"?: number;
    "previousVersionId"?: number;
    "nextVersionId"?: number;
    "author"?: string;
    "quotationOffers"?: Array<QuotationOfferModel>;
    "quotationCharges"?: Array<QuotationChargeModel>;
    "quotationCurrencies"?: Array<QuotationCurrencyModel>;
    "quotationFees"?: Array<QuotationFeeModel>;
    "quotationDocuments"?: Array<QuotationDocumentModel>;
    "previousQuotations"?: Array<QuotationModel>;
}

export type QuotationModelTypeEnum = "Normal" | "Template";
export type QuotationModelStatusEnum = "Created" | "Success" | "Failure";
export type QuotationModelDetailsLevelEnum = "Full" | "PartialTotal" | "None" | "PartialCharges";
export type QuotationModelTotalTypeEnum = "AllIn" | "ByCurrency" | "None";
export type QuotationModelFrequencyEnum = "Weekly" | "TwiceAWeek" | "Monthly";
export type QuotationModelFirmScopeEnum = "Agency" | "Company" | "Group" | "Personal";
export interface QuotationOfferModel {
    "carrierOffer"?: CarrierOfferModel;
    "departId"?: number;
    "routeId"?: number;
}

export interface QuotationSettingsModel {
    "quotationSettingsId"?: number;
    "quotationExpirationDays"?: number;
    "enabled"?: boolean;
    "defaultAdditionalInfo"?: string;
    "defaultTerms"?: string;
    "agentAdditionalInfo"?: string;
}

export interface QuotationTemplateSettingsModel {
    "quotationTemplateSettingsId"?: number;
    "templateType"?: QuotationTemplateSettingsModelTemplateTypeEnum;
    "includeAdditionalInfo"?: boolean;
    "includeTerms"?: boolean;
    "includeDetailsLevel"?: boolean;
    "includeTotalType"?: boolean;
    "includeShowExchangeRates"?: boolean;
    "includeExchangeRates"?: boolean;
    "includeLanguage"?: boolean;
    "quotationId"?: number;
}

export type QuotationTemplateSettingsModelTemplateTypeEnum = "Normal" | "Master";
export interface RatesAvailabilityModel {
    "ratesAvailabilityId"?: number;
    "agreedAllocation"?: number;
    "usedAllocation"?: number;
    "containerLeft"?: number;
    "status"?: RatesAvailabilityModelStatusEnum;
    "sizeTypeId"?: number;
    "departId"?: number;
    "firmId"?: number;
    "chargeSetId"?: number;
    "ratesOfferId"?: number;
}

export type RatesAvailabilityModelStatusEnum = "Allocation" | "Available" | "NotAvailable" | "FewAvailable";
export interface RatesFetcherErrorModel {
    "type"?: RatesFetcherErrorModelTypeEnum;
    "message"?: string;
    "carrierRatesPlatformId"?: number;
}

export type RatesFetcherErrorModelTypeEnum = "Unknown" | "MappingError" | "Unsuported" | "RouteError" | "Exception" | "NoConnection" | "AuthenticationError" | "ApiLimitReached" | "OkargoApiLimitReached" | "AntiBotStarted" | "InternalError" | "AdditionalChargesRequired" | "UnsuportedRoute" | "Mfa" | "BlackListed" | "AccountExpired" | "EmptyResults" | "CaptchaNoCredits" | "UnsuportedContainer" | "NoRightsForAccess" | "MfaSystem" | "DuplicateRequest" |"WrongCredentionals";
/** | "UnsuportedContainer"
 * Definition of contract
 */
export interface RatesOfferModel {
    "ratesOfferId"?: number;
    /**
     * Id firm where cotract belongs
     */
    "firmId"?: number;
    /**
     * Type of rates
     */
    "type"?: RatesOfferModelTypeEnum;
    /**
     * Commodity for contract
     */
    "commodity"?: string;
    /**
     * Name on named account
     */
    "nac"?: string;
    /**
     * Traffice direction
     */
    "trafficType"?: RatesOfferModelTrafficTypeEnum;
    /**
     * List of references under contract
     */
    "references"?: Array<ReferenceModel>;
    "description"?: string;
    /**
     * Level of importance for description
     */
    "level"?: RatesOfferModelLevelEnum;
    "shortName"?: string;
    /**
     * List of carriers which belongs to contract
     */
    "carriers"?: Array<CarrierModel>;
    "enabled"?: boolean;
    "owner"?: string;
    "creationDate"?: Date;
    "lastUpdate"?: Date;
}

export type RatesOfferModelTypeEnum = "Fak" | "Special";
export type RatesOfferModelTrafficTypeEnum = "Export" | "Import" | "CrossTrade";
export type RatesOfferModelLevelEnum = "Low" | "Normal" | "High";
export interface ReferenceModel {
    "referenceId"?: number;
    "value"?: string;
}

export interface RequestResetPasswordModel {
    "email"?: string;
}

export interface RequestStatsPriceModel {
    "origin"?: LocationModel;
    "destination"?: LocationModel;
}

export interface ResetPasswordModel {
    "token"?: string;
    "password"?: string;
    "passwordConfirmation"?: string;
}

export interface RouteConfigModel {
    "routeConfigId"?: number;
    "routeId"?: number;
    "carrierId"?: number;
    "lastUpdate"?: Date;
    "source"?: RouteConfigModelSourceEnum;
    "transitTime"?: number;
    "bestTransitTime"?: number;
    "freeTimeOrigin"?: number;
    "freeTimeDestination"?: number;
    "schedulesFetchedRecordId"?: number;
    "frequency"?: FrequencyModel;
    "routeService"?: RouteServiceModel;
    "routeServiceId"?: number;
    "accuracy"?: RouteConfigModelAccuracyEnum;
    "transShipments"?: Array<TransshipmentModel>;
    "departs"?: Array<DepartModel>;
}

export type RouteConfigModelSourceEnum = "Manual" | "ScheduleSystem" | "CarrierRates" | "ManualFromGrid";
export type RouteConfigModelAccuracyEnum = "Good" | "Bad";
export interface RouteExportModel {
    "transitTime"?: number;
    "bestTransitTime"?: number;
    "frequency"?: FrequencyModel;
    "originPort"?: LocationModel;
    "destinationPort"?: LocationModel;
    "originTerminal"?: LocationModel;
    "destinationTerminal"?: LocationModel;
    "transShipments"?: Array<LocationModel>;
    "chargeSets"?: Array<ChargeSetModel>;
    "ratesOffers"?: { [key: string]: RatesOfferModel; };
}

export interface RouteLclConfigModel {
    "routeLclConfigId"?: number;
    "enabled"?: boolean;
    "lastUpdateDate"?: Date;
    "creationDate"?: Date;
    "routeId"?: number;
    "originCfs"?: LocationModel;
    "originCfsId"?: number;
    "destinationCfs"?: LocationModel;
    "destinationCfsId"?: number;
}

export interface RouteRatesPlatformDataModel {
    "routeRatesPlatformDataId"?: number;
    "carrierId"?: number;
    "originPortId"?: number;
    "originPort"?: LocationModel;
    "originCountryId"?: number;
    "originPortsZoneId"?: number;
    "destinationPortId"?: number;
    "destinationPort"?: LocationModel;
    "destinationCountryId"?: number;
    "destinationPortsZoneId"?: number;
}

export interface RouteServiceModel {
    "routeServiceId"?: number;
    "code"?: string;
    "name"?: string;
    "source"?: RouteConfigModelSourceEnum;
    "routeId"?: number;
    "creationDate"?: Date;
}

export interface SearchAgentSearchModel {
    "name"?: string;
    "fromDate"?: Date;
    "origin"?: LocationModel;
    "destination"?: LocationModel;
}

export interface SearchByAddressModel {
    "address"?: string;
}

export interface SearchByAddressResult {
    "locationDistances"?: Array<LocationDistanceModel>;
    "addressFound"?: string;
    "lat"?: number;
    "lng"?: number;
}

export interface SearchCriteriaHistoryModel {
    "fromDate"?: Date;
}

export interface SearchInlandRatesOffersModel {
    "inlandCarrierId"?: number;
    "term"?: string;
}

export interface SearchMultiPortsModel {
    "fromDate"?: Date;
}

export interface SearchOfferMemoHistoryModel {
    "fromDate"?: Date;
}

export interface SearchRatesOffersModel {
    "searchTerm"?: string;
    "carrierId"?: number;
}

export interface SearchWorkDocumentsModel {
    "carrierId"?: number;
    "trafficType"?: SearchWorkDocumentsModelTrafficTypeEnum;
    "origin"?: LocationModel;
    "destination"?: LocationModel;
    "reference"?: string;
}

export type SearchWorkDocumentsModelTrafficTypeEnum = "Export" | "Import" | "CrossTrade";
export interface SeedModel {
    "currencies"?: { [key: string]: CurrencyModel; };
    "languages"?: { [key: string]: LanguageModel; };
    "sizeTypes"?: { [key: string]: SizeTypeModel; };
    "chargeNames"?: { [key: string]: ChargeNameModel; };
    "ddChargeNames"?: { [key: string]: DdChargeNameModel; };
    "carriers"?: { [key: string]: CarrierModel; };
    "carrierGroups"?: { [key: string]: CarrierGroupModel; };
    "portsZones"?: { [key: string]: PortsZoneModel; };
    "markets"?: { [key: string]: MarketModel; };
    "inlandCarriers"?: { [key: string]: InlandCarrierModel; };
    "carrierRatesPlatforms"?: { [key: string]: CarrierRatesPlatformModel; };
    "countries"?: { [key: string]: LocationModel; };
}

export interface SelectionModel {
    "clientActionId"?: number;
    "carrierRatings"?: { [key: string]: CarrierRatingModel; };
    "carrierOffers"?: Array<CarrierOfferModel>;
    "firmNewses"?: Array<FirmNewsModel>;
    "errors"?: Array<string>;
}

export interface SellingRatesModel {
    "sellingRatesId"?: number;
    "creationDate"?: Date;
    "author"?: string;
    "firmId"?: number;
    "firm"?: FirmModel;
    "originPortId"?: number;
    "originPort"?: LocationModel;
    "destinationPortId"?: number;
    "destinationPort"?: LocationModel;
    "originPortsZoneId"?: number;
    "originPortsZone"?: PortsZoneModel;
    "destinationPortsZoneId"?: number;
    "destinationPortsZone"?: PortsZoneModel;
    "sellingRatesValues"?: Array<SellingRatesValueModel>;
}

export interface SellingRatesValueModel {
    "sellingRatesValueId"?: number;
    "sellingMargin"?: number;
    "sellingMarginType"?: SellingRatesValueModelSellingMarginTypeEnum;
    "sellingRatesId"?: number;
    "sizeTypeId"?: number;
    "currencyId"?: number;
}

export type SellingRatesValueModelSellingMarginTypeEnum = "Flat" | "Percentage";
export interface SendAgentQuotationModel {
    "departId"?: number;
    "chargeSetId"?: number;
    "criteriaCode"?: string;
}

export interface SendAskBookingModel {
    "body"?: string;
    "to"?: string;
    "subject"?: string;
    "departId"?: number;
    "chargeSetId"?: number;
}

export interface SendAskRatesModel {
    "departId"?: number;
    "to"?: string;
    "subject"?: string;
    "body"?: string;
}

export interface ServicesOptionsModel {
    "environement"?: number;
    "intraUrl"?: string;
    "actionsLogEnabled"?: boolean;
    "schedulesApiEnabled"?: boolean;
}

export interface SetSatisfiedModel {
    "level"?: SetSatisfiedModelLevelEnum;
    "reason"?: string;
    "clientActionId"?: number;
}

export type SetSatisfiedModelLevelEnum = "Good" | "Normal" | "Bad";
export interface SizeTypeModel {
    "sizeTypeId"?: number;
    "name"?: string;
    "teu"?: number;
    "primary"?: boolean;
    "type"?: SizeTypeModelTypeEnum;
    "tare"?: number;
    "position"?: number;
}

export type SizeTypeModelTypeEnum = "Dry" | "Rf" | "Fl" | "Ot" | "Tk";
export interface SizeTypePriceAverageModel {
    "sizeTypeId"?: number;
    "frtAvg"?: number;
    "originAvg"?: number;
    "destinationAvg"?: number;
}

export interface StatsPriceModel {
    "origin"?: LocationModel;
    "destination"?: LocationModel;
    "monthStatsPrices"?: Array<MonthStatsPriceModel>;
}

export interface SubscriptionModel {
    "subscriptionId"?: number;
    "subscriptionType"?: SubscriptionModelSubscriptionTypeEnum;
    "enabled"?: boolean;
    "main"?: boolean;
    "creationDate"?: Date;
    "clientId"?: number;
    "title"?: string;
    "type"?: SubscriptionModelTypeEnum;
    "allowInformUser"?: boolean;
    "allowAgentManagement"?: boolean;
    "allowUserManagement"?: boolean;    
    "allowRatesControl"?: boolean;
    "allowRatesManagement"?: boolean;
    "allowExportManagement"?: boolean;
    "allowClientUploadRates"?: boolean;
    "allowQuotSpot"?: boolean;
    "allowAskRates"?: boolean;
    "allowQuotationManagement"?: boolean;
    "allowCarrierRatesManagement"?: boolean;
    "allowApiBlacklistManagement"?: boolean;
    "receiveIntegrationMailExport"?: boolean;
    "receiveIntegrationMailImport"?: boolean;
    "receiveExpirityMailExport"?: boolean;
    "receiveExpirityMailImport"?: boolean;
    "dailyRequest"?: number;
    "premium"?: boolean;
    "carrierAccess"?: boolean;
    "suggestedRates"?: boolean;
    "iphoneAccess"?: boolean;
    "androidAccess"?: boolean;
    "agencyId"?: number;
    "agency"?: FirmModel;
    "hasExportParameters"?: boolean;
    "hasTmsSettings"?: boolean;
    "firmId"?: number;
    "firm"?: FirmModel;
    "interfaceAccess"?: boolean;
    "okargoAccess"?: boolean;
    "statisticAccess"?: boolean;
    "carrierId"?: number;
    "carrier"?: CarrierModel;
}

export type SubscriptionModelSubscriptionTypeEnum = "Okargo" | "Carrier" | "Agent";
export type SubscriptionModelTypeEnum = "Casual" | "Frequent";
export interface TokenModel {
    "token"?: string;
    "username"?: string;
    "expirationDate"?: Date;
    "isValid"?: boolean;
}

export interface TokenResultModel {
    "token"?: TokenModel;
    "message"?: string;
}

export interface TutorialModel {
    "tutorialId"?: number;
    "name"?: string;
    "status"?: TutorialModelStatusEnum;
}

export type TutorialModelStatusEnum = "NotDone" | "Done";
export interface UpdateMultiPortModel {
    "multiPortInstanceId"?: number;
    "name"?: string;
}

export interface UploadedFile {
    "fileName"?: string;
    "description"?: string;
    "content"?: string;
    "contentLength"?: number;
}

export interface UriResult {
    "uri"?: string;
}

export interface UserScreenModel {
    "width"?: number;
    "height"?: number;
}

export interface VesselModel {
    "vesselId"?: number;
    "name"?: string;
    "code"?: string;
    "type"?: VesselModelTypeEnum;
    "mmsi"?: string;
    "imo"?: string;
    "carrierId"?: number;
    "creationDate"?: Date;
}

export type VesselModelTypeEnum = "ContainerShip";
export interface WorkDocumentsModel {
    "workDocumentsId"?: number;
    "firmId"?: number;
    "enabled"?: boolean;
    "invalidated"?: boolean;
    "carriers"?: Array<CarrierModel>;
    "carrierAgencyId"?: number;
    "validityStart"?: Date;
    "validityEnd"?: Date;
    "ratesOffer"?: RatesOfferModel;
    "carrierReference"?: string;
    "trafficType"?: WorkDocumentsModelTrafficTypeEnum;
    "receptionDate"?: Date;
    "doneDate"?: Date;
    "owner"?: string;
    "priceLists"?: number;
    "mainPorts"?: Array<LocationModel>;
    "portsZones"?: Array<PortsZoneModel>;
    "offerInformations"?: Array<OfferInformationModel>;
}


export interface WorkDocumentsClientUploadDisplayModel {
    "workDocumentClientUploadId"?: number;
    "workdocumentId"?: number;
    "firmId"?: number;
    "carrierId"?: number;
    "doneDate"?: Date;
    "state"?: String;
    "creationDate"?: Date;
    "integratedBy"?: string;
    "invalidated"?: boolean;
    "validityBegin"?: Date;
    "validityEnd"?: Date;
    "ratesOffer"?: RatesOfferModel;
    "reference"?: string;
    "trafficType"?: WorkDocumentsModelTrafficTypeEnum;
    "ratesType"?: RatesOfferModelTypeEnum;
    "containerType"?: ChargeModelContainerTypeEnum;
    "commodity"?: string;
    "nac"?: string;
    "additionalInfo"?: string;

}



export interface WorkDocumentsClientUploadFilesModel {
    "sourceFile": string;
    "file": string;
}



export interface WorkDocumentsClientUploadModel {
    "workDocumentClientUploadId"?: number;
    "firmId"?: number;
    "carrierId"?: number;
    "validityBegin"?: Date;
    "validityEnd"?: Date;
    "ratesOffer"?: RatesOfferModel;
    "reference"?: string;
    "trafficType"?: WorkDocumentsModelTrafficTypeEnum;
    "ratesType"?: RatesOfferModelTypeEnum;
    "commodity"?: string;
    "nac"?: string;
    "additionalInfo"?: string;
}



export type WorkDocumentsModelTrafficTypeEnum = "Export" | "Import" | "CrossTrade";


/**
 * AccountApi - fetch parameter creator
 */
export const AccountApiFetchParamCreator = {
    /**
     *
     */
    acceptGcu(options?: any): FetchArgs {
        const baseUrl = `/api/Account/AcceptGcu`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * <remark>              The token is a jwt token that should be included in the Authorization header as so: Authorization: Bearer [token]              </remark>  <remark>              The token should be stored somewhere secured or in memory, it's TTL is around a typical day of work              </remark>
     * @summary Create and return a token that can be used to authenticate you
     * @param model Model containing an username and a password, the return Url is not used in this context
     */
    createToken(params: {  "model"?: LoginModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/CreateToken`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    editClient(params: {  "model"?: ClientModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/EditClient`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param currencyId
     */
    editCurrency(params: {  "currencyId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/EditCurrency`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "currencyId": params["currencyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param defaultQuotationContactId
     */
    editDefaultQuotationContact(params: {  "defaultQuotationContactId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/EditDefaultQuotationContact`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "defaultQuotationContactId": params["defaultQuotationContactId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param currencyId
     */
    editInlandCurrency(params: {  "currencyId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/EditInlandCurrency`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "currencyId": params["currencyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    editPassword(params: {  "model"?: EditPasswordModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/EditPassword`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    editQuotationClientSettings(params: {  "model"?: QuotationClientSettingsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/EditQuotationClientSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Get the current user as well as it's token expiration date
     */
    getCurrentUser(options?: any): FetchArgs {
        const baseUrl = `/api/Account/GetCurrentUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    logout(options?: any): FetchArgs {
        const baseUrl = `/api/Account/Logout`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    requestResetPassword(params: {  "model"?: RequestResetPasswordModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/RequestResetPassword`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    resetPassword(params: {  "model"?: ResetPasswordModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/ResetPassword`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AccountApi - functional programming interface
 */
export const AccountApiFp = {
    /**
     *
     */
    acceptGcu(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.acceptGcu(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * <remark>              The token is a jwt token that should be included in the Authorization header as so: Authorization: Bearer [token]              </remark>  <remark>              The token should be stored somewhere secured or in memory, it's TTL is around a typical day of work              </remark>
     * @summary Create and return a token that can be used to authenticate you
     * @param model Model containing an username and a password, the return Url is not used in this context
     */
    createToken(params: { "model"?: LoginModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenResultModel> {
        const fetchArgs = AccountApiFetchParamCreator.createToken(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    editClient(params: { "model"?: ClientModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientModel> {
        const fetchArgs = AccountApiFetchParamCreator.editClient(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param currencyId
     */
    editCurrency(params: { "currencyId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.editCurrency(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param defaultQuotationContactId
     */
    editDefaultQuotationContact(params: { "defaultQuotationContactId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.editDefaultQuotationContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param currencyId
     */
    editInlandCurrency(params: { "currencyId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.editInlandCurrency(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    editPassword(params: { "model"?: EditPasswordModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.editPassword(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    editQuotationClientSettings(params: { "model"?: QuotationClientSettingsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationClientSettingsModel> {
        const fetchArgs = AccountApiFetchParamCreator.editQuotationClientSettings(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Get the current user as well as it's token expiration date
     */
    getCurrentUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrentUserModel> {
        const fetchArgs = AccountApiFetchParamCreator.getCurrentUser(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    logout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.logout(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    requestResetPassword(params: { "model"?: RequestResetPasswordModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.requestResetPassword(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    resetPassword(params: { "model"?: ResetPasswordModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.resetPassword(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AccountApi - object-oriented interface
 */
export class AccountApi extends BaseAPI {
    /**
     *
     */
    acceptGcu(options?: any) {
        return AccountApiFp.acceptGcu(options)(this.fetch, this.basePath);
    }
    /**
     * <remark>              The token is a jwt token that should be included in the Authorization header as so: Authorization: Bearer [token]              </remark>  <remark>              The token should be stored somewhere secured or in memory, it's TTL is around a typical day of work              </remark>
     * @summary Create and return a token that can be used to authenticate you
     * @param model Model containing an username and a password, the return Url is not used in this context
     */
    createToken(params: {  "model"?: LoginModel; }, options?: any) {
        return AccountApiFp.createToken(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    editClient(params: {  "model"?: ClientModel; }, options?: any) {
        return AccountApiFp.editClient(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param currencyId
     */
    editCurrency(params: {  "currencyId"?: number; }, options?: any) {
        return AccountApiFp.editCurrency(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param defaultQuotationContactId
     */
    editDefaultQuotationContact(params: {  "defaultQuotationContactId"?: number; }, options?: any) {
        return AccountApiFp.editDefaultQuotationContact(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param currencyId
     */
    editInlandCurrency(params: {  "currencyId"?: number; }, options?: any) {
        return AccountApiFp.editInlandCurrency(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    editPassword(params: {  "model"?: EditPasswordModel; }, options?: any) {
        return AccountApiFp.editPassword(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    editQuotationClientSettings(params: {  "model"?: QuotationClientSettingsModel; }, options?: any) {
        return AccountApiFp.editQuotationClientSettings(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get the current user as well as it's token expiration date
     */
    getCurrentUser(options?: any) {
        return AccountApiFp.getCurrentUser(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    logout(options?: any) {
        return AccountApiFp.logout(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    requestResetPassword(params: {  "model"?: RequestResetPasswordModel; }, options?: any) {
        return AccountApiFp.requestResetPassword(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    resetPassword(params: {  "model"?: ResetPasswordModel; }, options?: any) {
        return AccountApiFp.resetPassword(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AccountApi - factory interface
 */
export const AccountApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        acceptGcu(options?: any) {
            return AccountApiFp.acceptGcu(options)(fetch, basePath);
        },
        /**
         * <remark>              The token is a jwt token that should be included in the Authorization header as so: Authorization: Bearer [token]              </remark>  <remark>              The token should be stored somewhere secured or in memory, it's TTL is around a typical day of work              </remark>
         * @summary Create and return a token that can be used to authenticate you
         * @param model Model containing an username and a password, the return Url is not used in this context
         */
        createToken(params: {  "model"?: LoginModel; }, options?: any) {
            return AccountApiFp.createToken(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        editClient(params: {  "model"?: ClientModel; }, options?: any) {
            return AccountApiFp.editClient(params, options)(fetch, basePath);
        },
        /**
         *
         * @param currencyId
         */
        editCurrency(params: {  "currencyId"?: number; }, options?: any) {
            return AccountApiFp.editCurrency(params, options)(fetch, basePath);
        },
        /**
         *
         * @param defaultQuotationContactId
         */
        editDefaultQuotationContact(params: {  "defaultQuotationContactId"?: number; }, options?: any) {
            return AccountApiFp.editDefaultQuotationContact(params, options)(fetch, basePath);
        },
        /**
         *
         * @param currencyId
         */
        editInlandCurrency(params: {  "currencyId"?: number; }, options?: any) {
            return AccountApiFp.editInlandCurrency(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        editPassword(params: {  "model"?: EditPasswordModel; }, options?: any) {
            return AccountApiFp.editPassword(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        editQuotationClientSettings(params: {  "model"?: QuotationClientSettingsModel; }, options?: any) {
            return AccountApiFp.editQuotationClientSettings(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get the current user as well as it's token expiration date
         */
        getCurrentUser(options?: any) {
            return AccountApiFp.getCurrentUser(options)(fetch, basePath);
        },
        /**
         *
         */
        logout(options?: any) {
            return AccountApiFp.logout(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        requestResetPassword(params: {  "model"?: RequestResetPasswordModel; }, options?: any) {
            return AccountApiFp.requestResetPassword(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        resetPassword(params: {  "model"?: ResetPasswordModel; }, options?: any) {
            return AccountApiFp.resetPassword(params, options)(fetch, basePath);
        },
    };
};


/**
 * AgentApi - fetch parameter creator
 */
export const AgentApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    agentSearchs(params: {  "model"?: SearchAgentSearchModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Agent/AgentSearchs`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    chargesAgent(options?: any): FetchArgs {
        const baseUrl = `/api/Agent/ChargesAgent`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    contactsAgent(options?: any): FetchArgs {
        const baseUrl = `/api/Agent/ContactsAgent`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createCharge(params: {  "model"?: ChargeAgentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Agent/CreateCharge`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    createContact(params: {  "model"?: ContactModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Agent/CreateContact`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param chargeId
     */
    deleteCharge(params: {  "chargeId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Agent/DeleteCharge`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "chargeId": params["chargeId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param contactId
     */
    deleteContact(params: {  "contactId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Agent/DeleteContact`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "contactId": params["contactId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    editCharge(params: {  "model"?: ChargeAgentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Agent/EditCharge`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    editContact(params: {  "model"?: ContactModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Agent/EditContact`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AgentApi - functional programming interface
 */
export const AgentApiFp = {
    /**
     *
     * @param model
     */
    agentSearchs(params: { "model"?: SearchAgentSearchModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AgentSearchModel>> {
        const fetchArgs = AgentApiFetchParamCreator.agentSearchs(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    chargesAgent(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ChargeAgentModel>> {
        const fetchArgs = AgentApiFetchParamCreator.chargesAgent(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    contactsAgent(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ContactModel>> {
        const fetchArgs = AgentApiFetchParamCreator.contactsAgent(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createCharge(params: { "model"?: ChargeAgentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ChargeAgentModel> {
        const fetchArgs = AgentApiFetchParamCreator.createCharge(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    createContact(params: { "model"?: ContactModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactModel> {
        const fetchArgs = AgentApiFetchParamCreator.createContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param chargeId
     */
    deleteCharge(params: { "chargeId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AgentApiFetchParamCreator.deleteCharge(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param contactId
     */
    deleteContact(params: { "contactId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AgentApiFetchParamCreator.deleteContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    editCharge(params: { "model"?: ChargeAgentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ChargeAgentModel> {
        const fetchArgs = AgentApiFetchParamCreator.editCharge(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    editContact(params: { "model"?: ContactModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactModel> {
        const fetchArgs = AgentApiFetchParamCreator.editContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AgentApi - object-oriented interface
 */
export class AgentApi extends BaseAPI {
    /**
     *
     * @param model
     */
    agentSearchs(params: {  "model"?: SearchAgentSearchModel; }, options?: any) {
        return AgentApiFp.agentSearchs(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    chargesAgent(options?: any) {
        return AgentApiFp.chargesAgent(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    contactsAgent(options?: any) {
        return AgentApiFp.contactsAgent(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createCharge(params: {  "model"?: ChargeAgentModel; }, options?: any) {
        return AgentApiFp.createCharge(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    createContact(params: {  "model"?: ContactModel; }, options?: any) {
        return AgentApiFp.createContact(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param chargeId
     */
    deleteCharge(params: {  "chargeId"?: number; }, options?: any) {
        return AgentApiFp.deleteCharge(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param contactId
     */
    deleteContact(params: {  "contactId"?: number; }, options?: any) {
        return AgentApiFp.deleteContact(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    editCharge(params: {  "model"?: ChargeAgentModel; }, options?: any) {
        return AgentApiFp.editCharge(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    editContact(params: {  "model"?: ContactModel; }, options?: any) {
        return AgentApiFp.editContact(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AgentApi - factory interface
 */
export const AgentApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        agentSearchs(params: {  "model"?: SearchAgentSearchModel; }, options?: any) {
            return AgentApiFp.agentSearchs(params, options)(fetch, basePath);
        },
        /**
         *
         */
        chargesAgent(options?: any) {
            return AgentApiFp.chargesAgent(options)(fetch, basePath);
        },
        /**
         *
         */
        contactsAgent(options?: any) {
            return AgentApiFp.contactsAgent(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createCharge(params: {  "model"?: ChargeAgentModel; }, options?: any) {
            return AgentApiFp.createCharge(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        createContact(params: {  "model"?: ContactModel; }, options?: any) {
            return AgentApiFp.createContact(params, options)(fetch, basePath);
        },
        /**
         *
         * @param chargeId
         */
        deleteCharge(params: {  "chargeId"?: number; }, options?: any) {
            return AgentApiFp.deleteCharge(params, options)(fetch, basePath);
        },
        /**
         *
         * @param contactId
         */
        deleteContact(params: {  "contactId"?: number; }, options?: any) {
            return AgentApiFp.deleteContact(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        editCharge(params: {  "model"?: ChargeAgentModel; }, options?: any) {
            return AgentApiFp.editCharge(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        editContact(params: {  "model"?: ContactModel; }, options?: any) {
            return AgentApiFp.editContact(params, options)(fetch, basePath);
        },
    };
};


/**
 * CarrierCarrierRatingApi - fetch parameter creator
 */
export const CarrierCarrierRatingApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierCarrierRating/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: CarrierCarrierRatingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierCarrierRating/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/CarrierCarrierRating/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: CarrierCarrierRatingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierCarrierRating/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CarrierCarrierRatingApi - functional programming interface
 */
export const CarrierCarrierRatingApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CarrierCarrierRatingApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: CarrierCarrierRatingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierCarrierRatingModel> {
        const fetchArgs = CarrierCarrierRatingApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierCarrierRatingModel>> {
        const fetchArgs = CarrierCarrierRatingApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: CarrierCarrierRatingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierCarrierRatingModel> {
        const fetchArgs = CarrierCarrierRatingApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CarrierCarrierRatingApi - object-oriented interface
 */
export class CarrierCarrierRatingApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return CarrierCarrierRatingApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: CarrierCarrierRatingModel; }, options?: any) {
        return CarrierCarrierRatingApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return CarrierCarrierRatingApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: CarrierCarrierRatingModel; }, options?: any) {
        return CarrierCarrierRatingApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CarrierCarrierRatingApi - factory interface
 */
export const CarrierCarrierRatingApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return CarrierCarrierRatingApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: CarrierCarrierRatingModel; }, options?: any) {
            return CarrierCarrierRatingApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return CarrierCarrierRatingApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: CarrierCarrierRatingModel; }, options?: any) {
            return CarrierCarrierRatingApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * CarrierRatesOfferInformationApi - fetch parameter creator
 */
export const CarrierRatesOfferInformationApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesOfferInformation/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: CarrierRatesOfferInformationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesOfferInformation/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesOfferInformation/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: CarrierRatesOfferInformationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesOfferInformation/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CarrierRatesOfferInformationApi - functional programming interface
 */
export const CarrierRatesOfferInformationApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CarrierRatesOfferInformationApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: CarrierRatesOfferInformationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierRatesOfferInformationModel> {
        const fetchArgs = CarrierRatesOfferInformationApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierRatesOfferInformationModel>> {
        const fetchArgs = CarrierRatesOfferInformationApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: CarrierRatesOfferInformationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierRatesOfferInformationModel> {
        const fetchArgs = CarrierRatesOfferInformationApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CarrierRatesOfferInformationApi - object-oriented interface
 */
export class CarrierRatesOfferInformationApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return CarrierRatesOfferInformationApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: CarrierRatesOfferInformationModel; }, options?: any) {
        return CarrierRatesOfferInformationApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return CarrierRatesOfferInformationApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: CarrierRatesOfferInformationModel; }, options?: any) {
        return CarrierRatesOfferInformationApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CarrierRatesOfferInformationApi - factory interface
 */
export const CarrierRatesOfferInformationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return CarrierRatesOfferInformationApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: CarrierRatesOfferInformationModel; }, options?: any) {
            return CarrierRatesOfferInformationApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return CarrierRatesOfferInformationApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: CarrierRatesOfferInformationModel; }, options?: any) {
            return CarrierRatesOfferInformationApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * CarrierRatesSettingsApi - fetch parameter creator
 */
export const CarrierRatesSettingsApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesSettings/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Delete seting
     * @param model
     */
    _delete_1(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesSettings/Delete`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Create new setting for carrier platform
     * @param model
     */
    create(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesSettings/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesSettings/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Returns all available platforms
     */
    getPlatforms(options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesSettings/GetPlatforms`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Returns all activated settings associated with account
     */
    getSettings(options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesSettings/GetSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Update existing setting for carrier platform
     * @param model
     */
    update(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRatesSettings/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CarrierRatesSettingsApi - functional programming interface
 */
export const CarrierRatesSettingsApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CarrierRatesSettingsApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Delete seting
     * @param model
     */
    _delete_1(params: { "model"?: CarrierRatesSettingsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CarrierRatesSettingsApiFetchParamCreator._delete_1(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Create new setting for carrier platform
     * @param model
     */
    create(params: { "model"?: CarrierRatesSettingsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierRatesSettingsModel> {
        const fetchArgs = CarrierRatesSettingsApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierRatesSettingsModel>> {
        const fetchArgs = CarrierRatesSettingsApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Returns all available platforms
     */
    getPlatforms(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierRatesSettingsModel>> {
        const fetchArgs = CarrierRatesSettingsApiFetchParamCreator.getPlatforms(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Returns all activated settings associated with account
     */
    getSettings(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierRatesSettingsModelBase>> {
        const fetchArgs = CarrierRatesSettingsApiFetchParamCreator.getSettings(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Update existing setting for carrier platform
     * @param model
     */
    update(params: { "model"?: CarrierRatesSettingsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierRatesSettingsModel> {
        const fetchArgs = CarrierRatesSettingsApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CarrierRatesSettingsApi - object-oriented interface
 */
export class CarrierRatesSettingsApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return CarrierRatesSettingsApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Delete seting
     * @param model
     */
    _delete_1(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any) {
        return CarrierRatesSettingsApiFp._delete_1(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Create new setting for carrier platform
     * @param model
     */
    create(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any) {
        return CarrierRatesSettingsApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return CarrierRatesSettingsApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Returns all available platforms
     */
    getPlatforms(options?: any) {
        return CarrierRatesSettingsApiFp.getPlatforms(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Returns all activated settings associated with account
     */
    getSettings(options?: any) {
        return CarrierRatesSettingsApiFp.getSettings(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Update existing setting for carrier platform
     * @param model
     */
    update(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any) {
        return CarrierRatesSettingsApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CarrierRatesSettingsApi - factory interface
 */
export const CarrierRatesSettingsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return CarrierRatesSettingsApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Delete seting
         * @param model
         */
        _delete_1(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any) {
            return CarrierRatesSettingsApiFp._delete_1(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Create new setting for carrier platform
         * @param model
         */
        create(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any) {
            return CarrierRatesSettingsApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return CarrierRatesSettingsApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns all available platforms
         */
        getPlatforms(options?: any) {
            return CarrierRatesSettingsApiFp.getPlatforms(options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns all activated settings associated with account
         */
        getSettings(options?: any) {
            return CarrierRatesSettingsApiFp.getSettings(options)(fetch, basePath);
        },
        /**
         *
         * @summary Update existing setting for carrier platform
         * @param model
         */
        update(params: {  "model"?: CarrierRatesSettingsModel; }, options?: any) {
            return CarrierRatesSettingsApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * CarrierRatingApi - fetch parameter creator
 */
export const CarrierRatingApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRating/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: CarrierRatingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRating/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRating/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: CarrierRatingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/CarrierRating/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CarrierRatingApi - functional programming interface
 */
export const CarrierRatingApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CarrierRatingApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: CarrierRatingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierRatingModel> {
        const fetchArgs = CarrierRatingApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierRatingModel>> {
        const fetchArgs = CarrierRatingApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: CarrierRatingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierRatingModel> {
        const fetchArgs = CarrierRatingApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CarrierRatingApi - object-oriented interface
 */
export class CarrierRatingApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return CarrierRatingApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: CarrierRatingModel; }, options?: any) {
        return CarrierRatingApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return CarrierRatingApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: CarrierRatingModel; }, options?: any) {
        return CarrierRatingApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CarrierRatingApi - factory interface
 */
export const CarrierRatingApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return CarrierRatingApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: CarrierRatingModel; }, options?: any) {
            return CarrierRatingApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return CarrierRatingApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: CarrierRatingModel; }, options?: any) {
            return CarrierRatingApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * CriteriaApi - fetch parameter creator
 */
export const CriteriaApiFetchParamCreator = {
    /**
     * The criteria code here is not used, it will be generated server-side
     * @summary Create and return criteria that can be used through it's code to search offers on Okargo
     * @param model
     */
    createCriteria(params: {  "model"?: CriteriaModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Criteria/CreateCriteria`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Get criteria from a criteria code
     * @param code
     */
    getCriteria(params: {  "code"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Criteria/GetCriteria`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "code": params["code"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    recentCriteria(params: { }, options?: any): FetchArgs {
        const baseUrl = `/api/Criteria/RecentCriteria`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CriteriaApi - functional programming interface
 */
export const CriteriaApiFp = {
    /**
     * The criteria code here is not used, it will be generated server-side
     * @summary Create and return criteria that can be used through it's code to search offers on Okargo
     * @param model
     */
    createCriteria(params: { "model"?: CriteriaModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CriteriaModel> {
        const fetchArgs = CriteriaApiFetchParamCreator.createCriteria(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Get criteria from a criteria code
     * @param code
     */
    getCriteria(params: { "code"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CriteriaModel> {
        const fetchArgs = CriteriaApiFetchParamCreator.getCriteria(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    recentCriteria(params: { "code"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CriteriaModel[]> {
        const fetchArgs = CriteriaApiFetchParamCreator.recentCriteria(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CriteriaApi - object-oriented interface
 */
export class CriteriaApi extends BaseAPI {
    /**
     * The criteria code here is not used, it will be generated server-side
     * @summary Create and return criteria that can be used through it's code to search offers on Okargo
     * @param model
     */
    createCriteria(params: {  "model"?: CriteriaModel; }, options?: any) {
        return CriteriaApiFp.createCriteria(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get criteria from a criteria code
     * @param code
     */
    getCriteria(params: {  "code"?: string; }, options?: any) {
        return CriteriaApiFp.getCriteria(params, options)(this.fetch, this.basePath);
    }
    recentCriteria(params: {}, options?: any) {
        return CriteriaApiFp.recentCriteria(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CriteriaApi - factory interface
 */
export const CriteriaApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * The criteria code here is not used, it will be generated server-side
         * @summary Create and return criteria that can be used through it's code to search offers on Okargo
         * @param model
         */
        createCriteria(params: {  "model"?: CriteriaModel; }, options?: any) {
            return CriteriaApiFp.createCriteria(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get criteria from a criteria code
         * @param code
         */
        getCriteria(params: {  "code"?: string; }, options?: any) {
            return CriteriaApiFp.getCriteria(params, options)(fetch, basePath);
        },
        recentCriteria(params: {  }, options?: any) {
            return CriteriaApiFp.recentCriteria(params, options)(fetch, basePath);
        },
    };
};


/**
 * DocumentApi - fetch parameter creator
 */
export const DocumentApiFetchParamCreator = {
    /**
     *
     * @param firmDocumentId
     */
    downloadDocument(params: {  "firmDocumentId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Document/DownloadDocument`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "firmDocumentId": params["firmDocumentId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
 *
 * @param workdocumentId
 */
    downloadWorkodocumentDocument(params: { "workdocumentId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Document/DownloadWordocumentDocuments`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workdocumentId": params["workdocumentId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },

    /**
     *
     */
    getFirmDocuments(options?: any): FetchArgs {
        const baseUrl = `/api/Document/GetFirmDocuments`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param externalDocument
     */
    uploadDocument(params: {  "externalDocument"?: ExternalDocumentsUpload; }, options?: any): FetchArgs {
        const baseUrl = `/api/Document/UploadDocument`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["externalDocument"]) {
            fetchOptions.body = JSON.stringify(params["externalDocument"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DocumentApi - functional programming interface
 */
export const DocumentApiFp = {
    /**
     *
     * @param firmDocumentId
     */
    downloadDocument(params: { "firmDocumentId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentApiFetchParamCreator.downloadDocument(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },

    /**
 *
 * @param workdocumentId
 */
    downloadWorkodocumentDocument(params: { "workdocumentId"?: number; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentApiFetchParamCreator.downloadWorkodocumentDocument(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },

    /**
     *
     */
    getFirmDocuments(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FirmDocumentModel>> {
        const fetchArgs = DocumentApiFetchParamCreator.getFirmDocuments(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param externalDocument
     */
    uploadDocument(params: { "externalDocument"?: ExternalDocumentsUpload;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DocumentApiFetchParamCreator.uploadDocument(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DocumentApi - object-oriented interface
 */
export class DocumentApi extends BaseAPI {
    /**
     *
     * @param firmDocumentId
     */
    downloadDocument(params: {  "firmDocumentId"?: number; }, options?: any) {
        return DocumentApiFp.downloadDocument(params, options)(this.fetch, this.basePath);
    }
    /**
 *
 * @param workdocumentId
 */
    downloadWorkodocumetDocument(params: { "workdocumentId"?: number; }, options?: any) {
        return DocumentApiFp.downloadWorkodocumentDocument(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getFirmDocuments(options?: any) {
        return DocumentApiFp.getFirmDocuments(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param externalDocument
     */
    uploadDocument(params: {  "externalDocument"?: ExternalDocumentsUpload; }, options?: any) {
        return DocumentApiFp.uploadDocument(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DocumentApi - factory interface
 */
export const DocumentApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param firmDocumentId
         */
        downloadDocument(params: {  "firmDocumentId"?: number; }, options?: any) {
            return DocumentApiFp.downloadDocument(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getFirmDocuments(options?: any) {
            return DocumentApiFp.getFirmDocuments(options)(fetch, basePath);
        },
        /**
         *
         * @param externalDocument
         */
        uploadDocument(params: {  "externalDocument"?: ExternalDocumentsUpload; }, options?: any) {
            return DocumentApiFp.uploadDocument(params, options)(fetch, basePath);
        },
    };
};


/**
 * DownloadApi - fetch parameter creator
 */
export const DownloadApiFetchParamCreator = {
    /**
     *
     */
    downloadApp(options?: any): FetchArgs {
        const baseUrl = `/api/Download/DownloadApp`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    downloadChargesGlossary(options?: any): FetchArgs {
        const baseUrl = `/api/Download/DownloadChargesGlossary`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param lang
     */
    downloadGuideMultiport(params: {  "lang"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Download/DownloadGuideMultiport`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lang": params["lang"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param lang
     */
    downloadGuideOcq(params: {  "lang"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Download/DownloadGuideOcq`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lang": params["lang"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    downloadGuideQuotation(options?: any): FetchArgs {
        const baseUrl = `/api/Download/DownloadGuideQuotation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    downloadPortsAndZones(options?: any): FetchArgs {
        const baseUrl = `/api/Download/DownloadPortsAndZones`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param lang
     */
    downloadQuickstart(params: {  "lang"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Download/DownloadQuickstart`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lang": params["lang"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param lang
     */
    downloadTos(params: {  "lang"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Download/DownloadTos`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lang": params["lang"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DownloadApi - functional programming interface
 */
export const DownloadApiFp = {
    /**
     *
     */
    downloadApp(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DownloadApiFetchParamCreator.downloadApp(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    downloadChargesGlossary(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DownloadApiFetchParamCreator.downloadChargesGlossary(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param lang
     */
    downloadGuideMultiport(params: { "lang"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DownloadApiFetchParamCreator.downloadGuideMultiport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param lang
     */
    downloadGuideOcq(params: { "lang"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DownloadApiFetchParamCreator.downloadGuideOcq(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    downloadGuideQuotation(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DownloadApiFetchParamCreator.downloadGuideQuotation(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    downloadPortsAndZones(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DownloadApiFetchParamCreator.downloadPortsAndZones(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param lang
     */
    downloadQuickstart(params: { "lang"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DownloadApiFetchParamCreator.downloadQuickstart(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param lang
     */
    downloadTos(params: { "lang"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DownloadApiFetchParamCreator.downloadTos(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DownloadApi - object-oriented interface
 */
export class DownloadApi extends BaseAPI {
    /**
     *
     */
    downloadApp(options?: any) {
        return DownloadApiFp.downloadApp(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    downloadChargesGlossary(options?: any) {
        return DownloadApiFp.downloadChargesGlossary(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param lang
     */
    downloadGuideMultiport(params: {  "lang"?: string; }, options?: any) {
        return DownloadApiFp.downloadGuideMultiport(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param lang
     */
    downloadGuideOcq(params: {  "lang"?: string; }, options?: any) {
        return DownloadApiFp.downloadGuideOcq(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    downloadGuideQuotation(options?: any) {
        return DownloadApiFp.downloadGuideQuotation(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    downloadPortsAndZones(options?: any) {
        return DownloadApiFp.downloadPortsAndZones(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param lang
     */
    downloadQuickstart(params: {  "lang"?: string; }, options?: any) {
        return DownloadApiFp.downloadQuickstart(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param lang
     */
    downloadTos(params: {  "lang"?: string; }, options?: any) {
        return DownloadApiFp.downloadTos(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DownloadApi - factory interface
 */
export const DownloadApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        downloadApp(options?: any) {
            return DownloadApiFp.downloadApp(options)(fetch, basePath);
        },
        /**
         *
         */
        downloadChargesGlossary(options?: any) {
            return DownloadApiFp.downloadChargesGlossary(options)(fetch, basePath);
        },
        /**
         *
         * @param lang
         */
        downloadGuideMultiport(params: {  "lang"?: string; }, options?: any) {
            return DownloadApiFp.downloadGuideMultiport(params, options)(fetch, basePath);
        },
        /**
         *
         * @param lang
         */
        downloadGuideOcq(params: {  "lang"?: string; }, options?: any) {
            return DownloadApiFp.downloadGuideOcq(params, options)(fetch, basePath);
        },
        /**
         *
         */
        downloadGuideQuotation(options?: any) {
            return DownloadApiFp.downloadGuideQuotation(options)(fetch, basePath);
        },
        /**
         *
         */
        downloadPortsAndZones(options?: any) {
            return DownloadApiFp.downloadPortsAndZones(options)(fetch, basePath);
        },
        /**
         *
         * @param lang
         */
        downloadQuickstart(params: {  "lang"?: string; }, options?: any) {
            return DownloadApiFp.downloadQuickstart(params, options)(fetch, basePath);
        },
        /**
         *
         * @param lang
         */
        downloadTos(params: {  "lang"?: string; }, options?: any) {
            return DownloadApiFp.downloadTos(params, options)(fetch, basePath);
        },
    };
};


/**
 * ExportApi - fetch parameter creator
 */
export const ExportApiFetchParamCreator = {
    /**
     * The request can be long and can be subject to timeout, use the skip/take to split the request if it does.    The total number of routes corresponding to the port + traffic is returned to know if you have everything, it does not match the number of offers.    Ex: if you made a Take of 1000 and Skip of 0 and the returned RoutesCount is 1250, you need to make a Skip of 1000 and Take of 250 or superior to get the rest.
     * @summary Export offers from a port and a traffic type (Import or Export)
     * @param model The criteria used to export your rates
     */
    exportBulk(params: {  "model"?: ExportBulkModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/ExportBulk`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    exportToTms(params: {  "model"?: ExportOfferToTmsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/ExportToTms`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Get available fetchers
     */
    getAvailableRatesFetchers(options?: any): FetchArgs {
        const baseUrl = `/api/Export/GetAvailableRatesFetchers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from the criteria sent by post in JSON
     * @param model
     */
    getContractCarrierOffers(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/GetContractCarrierOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    getOnlineCarrierOffers(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/GetOnlineCarrierOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo online quotes system from the criteria sent by post in JSON
     * @param model
     */
    getOnlineCarrierOffersV2(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/v2/GetOnlineCarrierOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Get the list of ports
     */
    ports(options?: any): FetchArgs {
        const baseUrl = `/api/Export/Ports`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    transferOffer(params: {  "model"?: ExportOfferModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/TransferOffer`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ExportApi - functional programming interface
 */
export const ExportApiFp = {
    /**
     * The request can be long and can be subject to timeout, use the skip/take to split the request if it does.    The total number of routes corresponding to the port + traffic is returned to know if you have everything, it does not match the number of offers.    Ex: if you made a Take of 1000 and Skip of 0 and the returned RoutesCount is 1250, you need to make a Skip of 1000 and Take of 250 or superior to get the rest.
     * @summary Export offers from a port and a traffic type (Import or Export)
     * @param model The criteria used to export your rates
     */
    exportBulk(params: { "model"?: ExportBulkModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExportBulkResultModel> {
        const fetchArgs = ExportApiFetchParamCreator.exportBulk(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    exportToTms(params: { "model"?: ExportOfferToTmsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExportDataResultModel> {
        const fetchArgs = ExportApiFetchParamCreator.exportToTms(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Get available fetchers
     */
    getAvailableRatesFetchers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierRatesPlatformModel>> {
        const fetchArgs = ExportApiFetchParamCreator.getAvailableRatesFetchers(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from the criteria sent by post in JSON
     * @param model
     */
    getContractCarrierOffers(params: { "model"?: OnlineCarrierOffersExportCriteriaModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OnlineCarrierOffersExportModel> {
        const fetchArgs = ExportApiFetchParamCreator.getContractCarrierOffers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    getOnlineCarrierOffers(params: { "model"?: OnlineCarrierOffersExportCriteriaModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ExportApiFetchParamCreator.getOnlineCarrierOffers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo online quotes system from the criteria sent by post in JSON
     * @param model
     */
    getOnlineCarrierOffersV2(params: { "model"?: OnlineCarrierOffersExportCriteriaModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OnlineCarrierOffersExportModel> {
        const fetchArgs = ExportApiFetchParamCreator.getOnlineCarrierOffersV2(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Get the list of ports
     */
    ports(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LocationModel>> {
        const fetchArgs = ExportApiFetchParamCreator.ports(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    transferOffer(params: { "model"?: ExportOfferModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExportDataResultModel> {
        const fetchArgs = ExportApiFetchParamCreator.transferOffer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ExportApi - object-oriented interface
 */
export class ExportApi extends BaseAPI {
    /**
     * The request can be long and can be subject to timeout, use the skip/take to split the request if it does.    The total number of routes corresponding to the port + traffic is returned to know if you have everything, it does not match the number of offers.    Ex: if you made a Take of 1000 and Skip of 0 and the returned RoutesCount is 1250, you need to make a Skip of 1000 and Take of 250 or superior to get the rest.
     * @summary Export offers from a port and a traffic type (Import or Export)
     * @param model The criteria used to export your rates
     */
    exportBulk(params: {  "model"?: ExportBulkModel; }, options?: any) {
        return ExportApiFp.exportBulk(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    exportToTms(params: {  "model"?: ExportOfferToTmsModel; }, options?: any) {
        return ExportApiFp.exportToTms(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get available fetchers
     */
    getAvailableRatesFetchers(options?: any) {
        return ExportApiFp.getAvailableRatesFetchers(options)(this.fetch, this.basePath);
    }
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from the criteria sent by post in JSON
     * @param model
     */
    getContractCarrierOffers(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any) {
        return ExportApiFp.getContractCarrierOffers(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    getOnlineCarrierOffers(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any) {
        return ExportApiFp.getOnlineCarrierOffers(params, options)(this.fetch, this.basePath);
    }
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo online quotes system from the criteria sent by post in JSON
     * @param model
     */
    getOnlineCarrierOffersV2(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any) {
        return ExportApiFp.getOnlineCarrierOffersV2(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get the list of ports
     */
    ports(options?: any) {
        return ExportApiFp.ports(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    transferOffer(params: {  "model"?: ExportOfferModel; }, options?: any) {
        return ExportApiFp.transferOffer(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ExportApi - factory interface
 */
export const ExportApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * The request can be long and can be subject to timeout, use the skip/take to split the request if it does.    The total number of routes corresponding to the port + traffic is returned to know if you have everything, it does not match the number of offers.    Ex: if you made a Take of 1000 and Skip of 0 and the returned RoutesCount is 1250, you need to make a Skip of 1000 and Take of 250 or superior to get the rest.
         * @summary Export offers from a port and a traffic type (Import or Export)
         * @param model The criteria used to export your rates
         */
        exportBulk(params: {  "model"?: ExportBulkModel; }, options?: any) {
            return ExportApiFp.exportBulk(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        exportToTms(params: {  "model"?: ExportOfferToTmsModel; }, options?: any) {
            return ExportApiFp.exportToTms(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get available fetchers
         */
        getAvailableRatesFetchers(options?: any) {
            return ExportApiFp.getAvailableRatesFetchers(options)(fetch, basePath);
        },
        /**
         * Can be used to look for offers on a specific pol/pod
         * @summary Retrieve the result of an Okargo search from the criteria sent by post in JSON
         * @param model
         */
        getContractCarrierOffers(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any) {
            return ExportApiFp.getContractCarrierOffers(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        getOnlineCarrierOffers(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any) {
            return ExportApiFp.getOnlineCarrierOffers(params, options)(fetch, basePath);
        },
        /**
         * Can be used to look for offers on a specific pol/pod
         * @summary Retrieve the result of an Okargo online quotes system from the criteria sent by post in JSON
         * @param model
         */
        getOnlineCarrierOffersV2(params: {  "model"?: OnlineCarrierOffersExportCriteriaModel; }, options?: any) {
            return ExportApiFp.getOnlineCarrierOffersV2(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get the list of ports
         */
        ports(options?: any) {
            return ExportApiFp.ports(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        transferOffer(params: {  "model"?: ExportOfferModel; }, options?: any) {
            return ExportApiFp.transferOffer(params, options)(fetch, basePath);
        },
    };
};


/**
 * ExternalFetchApi - fetch parameter creator
 */
export const ExternalFetchApiFetchParamCreator = {
    /**
     *
     * @param fetcherCode
     * @param take
     */
    popularRoutes(params: {  "fetcherCode"?: string; "take"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/ExternalFetch/PopularRoutes`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "fetcherCode": params["fetcherCode"],
            "take": params["take"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    uploadRates(params: {  "model"?: ExternalRatesModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/ExternalFetch/UploadRates`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ExternalFetchApi - functional programming interface
 */
export const ExternalFetchApiFp = {
    /**
     *
     * @param fetcherCode
     * @param take
     */
    popularRoutes(params: { "fetcherCode"?: string; "take"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExternalFetchRouteModel>> {
        const fetchArgs = ExternalFetchApiFetchParamCreator.popularRoutes(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    uploadRates(params: { "model"?: ExternalRatesModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ExternalFetchApiFetchParamCreator.uploadRates(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ExternalFetchApi - object-oriented interface
 */
export class ExternalFetchApi extends BaseAPI {
    /**
     *
     * @param fetcherCode
     * @param take
     */
    popularRoutes(params: {  "fetcherCode"?: string; "take"?: number; }, options?: any) {
        return ExternalFetchApiFp.popularRoutes(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    uploadRates(params: {  "model"?: ExternalRatesModel; }, options?: any) {
        return ExternalFetchApiFp.uploadRates(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ExternalFetchApi - factory interface
 */
export const ExternalFetchApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param fetcherCode
         * @param take
         */
        popularRoutes(params: {  "fetcherCode"?: string; "take"?: number; }, options?: any) {
            return ExternalFetchApiFp.popularRoutes(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        uploadRates(params: {  "model"?: ExternalRatesModel; }, options?: any) {
            return ExternalFetchApiFp.uploadRates(params, options)(fetch, basePath);
        },
    };
};


/**
 * HistoryApi - fetch parameter creator
 */
export const HistoryApiFetchParamCreator = {
    /**
     *
     * @param offerMemoId
     */
    downloadMemo(params: {  "offerMemoId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/History/DownloadMemo`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "offerMemoId": params["offerMemoId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchCriteriaHistory(params: {  "model"?: SearchCriteriaHistoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/History/SearchCriteriaHistory`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchOfferMemoHistory(params: {  "model"?: SearchOfferMemoHistoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/History/SearchOfferMemoHistory`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HistoryApi - functional programming interface
 */
export const HistoryApiFp = {
    /**
     *
     * @param offerMemoId
     */
    downloadMemo(params: { "offerMemoId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = HistoryApiFetchParamCreator.downloadMemo(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchCriteriaHistory(params: { "model"?: SearchCriteriaHistoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CriteriaModel>> {
        const fetchArgs = HistoryApiFetchParamCreator.searchCriteriaHistory(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchOfferMemoHistory(params: { "model"?: SearchOfferMemoHistoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<OfferMemoModel>> {
        const fetchArgs = HistoryApiFetchParamCreator.searchOfferMemoHistory(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HistoryApi - object-oriented interface
 */
export class HistoryApi extends BaseAPI {
    /**
     *
     * @param offerMemoId
     */
    downloadMemo(params: {  "offerMemoId"?: number; }, options?: any) {
        return HistoryApiFp.downloadMemo(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchCriteriaHistory(params: {  "model"?: SearchCriteriaHistoryModel; }, options?: any) {
        return HistoryApiFp.searchCriteriaHistory(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchOfferMemoHistory(params: {  "model"?: SearchOfferMemoHistoryModel; }, options?: any) {
        return HistoryApiFp.searchOfferMemoHistory(params, options)(this.fetch, this.basePath);
    }
};

/**
 * HistoryApi - factory interface
 */
export const HistoryApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param offerMemoId
         */
        downloadMemo(params: {  "offerMemoId"?: number; }, options?: any) {
            return HistoryApiFp.downloadMemo(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchCriteriaHistory(params: {  "model"?: SearchCriteriaHistoryModel; }, options?: any) {
            return HistoryApiFp.searchCriteriaHistory(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchOfferMemoHistory(params: {  "model"?: SearchOfferMemoHistoryModel; }, options?: any) {
            return HistoryApiFp.searchOfferMemoHistory(params, options)(fetch, basePath);
        },
    };
};


/**
 * HubApi - fetch parameter creator
 */
export const HubApiFetchParamCreator = {
    /**
     *
     */
    hubUsers(options?: any): FetchArgs {
        const baseUrl = `/api/Hub/HubUsers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HubApi - functional programming interface
 */
export const HubApiFp = {
    /**
     *
     */
    hubUsers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<OkargoHubUserModel>> {
        const fetchArgs = HubApiFetchParamCreator.hubUsers(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HubApi - object-oriented interface
 */
export class HubApi extends BaseAPI {
    /**
     *
     */
    hubUsers(options?: any) {
        return HubApiFp.hubUsers(options)(this.fetch, this.basePath);
    }
};

/**
 * HubApi - factory interface
 */
export const HubApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        hubUsers(options?: any) {
            return HubApiFp.hubUsers(options)(fetch, basePath);
        },
    };
};


/**
 * ImageApi - fetch parameter creator
 */
export const ImageApiFetchParamCreator = {
    /**
     *
     * @param firmId
     */
    firmLogo(params: {  "firmId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/Image/FirmLogo`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "firmId": params["firmId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    okargoLogo(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/Image/OkargoLogo`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ImageApi - functional programming interface
 */
export const ImageApiFp = {
    /**
     *
     * @param firmId
     */
    firmLogo(params: { "firmId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ImageApiFetchParamCreator.firmLogo(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    okargoLogo(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ImageApiFetchParamCreator.okargoLogo(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ImageApi - object-oriented interface
 */
export class ImageApi extends BaseAPI {
    /**
     *
     * @param firmId
     */
    firmLogo(params: {  "firmId"?: number; }, options?: any) {
        return ImageApiFp.firmLogo(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    okargoLogo(params: {  "id"?: number; }, options?: any) {
        return ImageApiFp.okargoLogo(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ImageApi - factory interface
 */
export const ImageApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param firmId
         */
        firmLogo(params: {  "firmId"?: number; }, options?: any) {
            return ImageApiFp.firmLogo(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        okargoLogo(params: {  "id"?: number; }, options?: any) {
            return ImageApiFp.okargoLogo(params, options)(fetch, basePath);
        },
    };
};


/**
 * InlandCriteriaApi - fetch parameter creator
 */
export const InlandCriteriaApiFetchParamCreator = {
    /**
     * The criteria code here is not used, it will be generated server-side
     * @summary Create criteria for the inland module, can be used to search inland offers on Okargo
     * @param model Your criteria
     */
    createCriteria(params: {  "model"?: InlandCriteriaModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/InlandCriteria/CreateCriteria`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Get the criteria corresponding to a code
     * @param code The code of your criteria
     */
    getCriteriaByCode(params: {  "code"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/InlandCriteria/GetCriteriaByCode`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "code": params["code"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * InlandCriteriaApi - functional programming interface
 */
export const InlandCriteriaApiFp = {
    /**
     * The criteria code here is not used, it will be generated server-side
     * @summary Create criteria for the inland module, can be used to search inland offers on Okargo
     * @param model Your criteria
     */
    createCriteria(params: { "model"?: InlandCriteriaModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlandCriteriaModel> {
        const fetchArgs = InlandCriteriaApiFetchParamCreator.createCriteria(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Get the criteria corresponding to a code
     * @param code The code of your criteria
     */
    getCriteriaByCode(params: { "code"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlandCriteriaModel> {
        const fetchArgs = InlandCriteriaApiFetchParamCreator.getCriteriaByCode(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * InlandCriteriaApi - object-oriented interface
 */
export class InlandCriteriaApi extends BaseAPI {
    /**
     * The criteria code here is not used, it will be generated server-side
     * @summary Create criteria for the inland module, can be used to search inland offers on Okargo
     * @param model Your criteria
     */
    createCriteria(params: {  "model"?: InlandCriteriaModel; }, options?: any) {
        return InlandCriteriaApiFp.createCriteria(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Get the criteria corresponding to a code
     * @param code The code of your criteria
     */
    getCriteriaByCode(params: {  "code"?: string; }, options?: any) {
        return InlandCriteriaApiFp.getCriteriaByCode(params, options)(this.fetch, this.basePath);
    }
};

/**
 * InlandCriteriaApi - factory interface
 */
export const InlandCriteriaApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * The criteria code here is not used, it will be generated server-side
         * @summary Create criteria for the inland module, can be used to search inland offers on Okargo
         * @param model Your criteria
         */
        createCriteria(params: {  "model"?: InlandCriteriaModel; }, options?: any) {
            return InlandCriteriaApiFp.createCriteria(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get the criteria corresponding to a code
         * @param code The code of your criteria
         */
        getCriteriaByCode(params: {  "code"?: string; }, options?: any) {
            return InlandCriteriaApiFp.getCriteriaByCode(params, options)(fetch, basePath);
        },
    };
};


/**
 * InlandRatesOfferApi - fetch parameter creator
 */
export const InlandRatesOfferApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    search(params: {  "model"?: SearchInlandRatesOffersModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/InlandRatesOffer/Search`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: InlandRatesOfferModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/InlandRatesOffer/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * InlandRatesOfferApi - functional programming interface
 */
export const InlandRatesOfferApiFp = {
    /**
     *
     * @param model
     */
    search(params: { "model"?: SearchInlandRatesOffersModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<InlandRatesOfferModel>> {
        const fetchArgs = InlandRatesOfferApiFetchParamCreator.search(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: InlandRatesOfferModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlandRatesOfferModel> {
        const fetchArgs = InlandRatesOfferApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * InlandRatesOfferApi - object-oriented interface
 */
export class InlandRatesOfferApi extends BaseAPI {
    /**
     *
     * @param model
     */
    search(params: {  "model"?: SearchInlandRatesOffersModel; }, options?: any) {
        return InlandRatesOfferApiFp.search(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: InlandRatesOfferModel; }, options?: any) {
        return InlandRatesOfferApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * InlandRatesOfferApi - factory interface
 */
export const InlandRatesOfferApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        search(params: {  "model"?: SearchInlandRatesOffersModel; }, options?: any) {
            return InlandRatesOfferApiFp.search(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: InlandRatesOfferModel; }, options?: any) {
            return InlandRatesOfferApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * InlandSelectionApi - fetch parameter creator
 */
export const InlandSelectionApiFetchParamCreator = {
    /**
     *
     * @summary Download a zip document containing the relevant files regarding the rates from the id of the inland chargeSet
     * @param inlandChargeSetId The id of the inlandChargeSet
     */
    downloadWorkDocuments(params: {  "inlandChargeSetId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/InlandSelection/DownloadWorkDocuments`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "inlandChargeSetId": params["inlandChargeSetId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary Search and return the offers for a specific criteria previously created in InlandCriteria/CreateCriteria
     * @param code The inland crtieria code
     */
    getSelectionFromCode(params: {  "code"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/InlandSelection/GetSelectionFromCode`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "code": params["code"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * InlandSelectionApi - functional programming interface
 */
export const InlandSelectionApiFp = {
    /**
     *
     * @summary Download a zip document containing the relevant files regarding the rates from the id of the inland chargeSet
     * @param inlandChargeSetId The id of the inlandChargeSet
     */
    downloadWorkDocuments(params: { "inlandChargeSetId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = InlandSelectionApiFetchParamCreator.downloadWorkDocuments(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary Search and return the offers for a specific criteria previously created in InlandCriteria/CreateCriteria
     * @param code The inland crtieria code
     */
    getSelectionFromCode(params: { "code"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlandSelectionModel> {
        const fetchArgs = InlandSelectionApiFetchParamCreator.getSelectionFromCode(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * InlandSelectionApi - object-oriented interface
 */
export class InlandSelectionApi extends BaseAPI {
    /**
     *
     * @summary Download a zip document containing the relevant files regarding the rates from the id of the inland chargeSet
     * @param inlandChargeSetId The id of the inlandChargeSet
     */
    downloadWorkDocuments(params: {  "inlandChargeSetId"?: number; }, options?: any) {
        return InlandSelectionApiFp.downloadWorkDocuments(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary Search and return the offers for a specific criteria previously created in InlandCriteria/CreateCriteria
     * @param code The inland crtieria code
     */
    getSelectionFromCode(params: {  "code"?: string; }, options?: any) {
        return InlandSelectionApiFp.getSelectionFromCode(params, options)(this.fetch, this.basePath);
    }
};

/**
 * InlandSelectionApi - factory interface
 */
export const InlandSelectionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary Download a zip document containing the relevant files regarding the rates from the id of the inland chargeSet
         * @param inlandChargeSetId The id of the inlandChargeSet
         */
        downloadWorkDocuments(params: {  "inlandChargeSetId"?: number; }, options?: any) {
            return InlandSelectionApiFp.downloadWorkDocuments(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary Search and return the offers for a specific criteria previously created in InlandCriteria/CreateCriteria
         * @param code The inland crtieria code
         */
        getSelectionFromCode(params: {  "code"?: string; }, options?: any) {
            return InlandSelectionApiFp.getSelectionFromCode(params, options)(fetch, basePath);
        },
    };
};


/**
 * LocationApi - fetch parameter creator
 */
export const LocationApiFetchParamCreator = {
    /**
     *
     * @param term
     * @param type
     * @param region
     */
    searchLocation(params: {  "term"?: string; "type"?: string; "region"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Location/SearchLocation`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "term": params["term"],
            "type": params["type"],
            "region": params["region"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchLocationsByAddress(params: {  "model"?: SearchByAddressModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Location/SearchLocationsByAddress`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LocationApi - functional programming interface
 */
export const LocationApiFp = {
    /**
     *
     * @param term
     * @param type
     * @param region
     */
    searchLocation(params: { "term"?: string; "type"?: string; "region"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LocationModel>> {
        const fetchArgs = LocationApiFetchParamCreator.searchLocation(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchLocationsByAddress(params: { "model"?: SearchByAddressModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchByAddressResult> {
        const fetchArgs = LocationApiFetchParamCreator.searchLocationsByAddress(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LocationApi - object-oriented interface
 */
export class LocationApi extends BaseAPI {
    /**
     *
     * @param term
     * @param type
     * @param region
     */
    searchLocation(params: {  "term"?: string; "type"?: string; "region"?: string; }, options?: any) {
        return LocationApiFp.searchLocation(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchLocationsByAddress(params: {  "model"?: SearchByAddressModel; }, options?: any) {
        return LocationApiFp.searchLocationsByAddress(params, options)(this.fetch, this.basePath);
    }
};

/**
 * LocationApi - factory interface
 */
export const LocationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param term
         * @param type
         * @param region
         */
        searchLocation(params: {  "term"?: string; "type"?: string; "region"?: string; }, options?: any) {
            return LocationApiFp.searchLocation(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchLocationsByAddress(params: {  "model"?: SearchByAddressModel; }, options?: any) {
            return LocationApiFp.searchLocationsByAddress(params, options)(fetch, basePath);
        },
    };
};


/**
 * LogApi - fetch parameter creator
 */
export const LogApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    logActions(params: {  "model"?: ActionsLogModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Log/LogActions`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    logError(params: {  "model"?: ErrorReportModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Log/LogError`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    logScreen(params: {  "model"?: UserScreenModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Log/LogScreen`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LogApi - functional programming interface
 */
export const LogApiFp = {
    /**
     *
     * @param model
     */
    logActions(params: { "model"?: ActionsLogModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = LogApiFetchParamCreator.logActions(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    logError(params: { "model"?: ErrorReportModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = LogApiFetchParamCreator.logError(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    logScreen(params: { "model"?: UserScreenModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = LogApiFetchParamCreator.logScreen(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LogApi - object-oriented interface
 */
export class LogApi extends BaseAPI {
    /**
     *
     * @param model
     */
    logActions(params: {  "model"?: ActionsLogModel; }, options?: any) {
        return LogApiFp.logActions(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    logError(params: {  "model"?: ErrorReportModel; }, options?: any) {
        return LogApiFp.logError(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    logScreen(params: {  "model"?: UserScreenModel; }, options?: any) {
        return LogApiFp.logScreen(params, options)(this.fetch, this.basePath);
    }
};

/**
 * LogApi - factory interface
 */
export const LogApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        logActions(params: {  "model"?: ActionsLogModel; }, options?: any) {
            return LogApiFp.logActions(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        logError(params: {  "model"?: ErrorReportModel; }, options?: any) {
            return LogApiFp.logError(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        logScreen(params: {  "model"?: UserScreenModel; }, options?: any) {
            return LogApiFp.logScreen(params, options)(fetch, basePath);
        },
    };
};


/**
 * ModeratorApi - fetch parameter creator
 */
export const ModeratorApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    createFirmNews(params: {  "model"?: FirmNewsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/CreateFirmNews`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    deleteDocumentInfo(params: {  "model"?: OfferInformationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/DeleteDocumentInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param firmDocumentId
     */
    deleteFirmDocument(params: {  "firmDocumentId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/DeleteFirmDocument`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "firmDocumentId": params["firmDocumentId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param firmNewsId
     */
    deleteFirmNews(params: {  "firmNewsId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/DeleteFirmNews`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "firmNewsId": params["firmNewsId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    editDocumentInfo(params: {  "model"?: OfferInformationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/EditDocumentInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    editFirmNews(params: {  "model"?: FirmNewsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/EditFirmNews`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    editRatesOffer(params: {  "model"?: RatesOfferModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/EditRatesOffer`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param workDocumentsId
     * @param value
     */
    editWorkDocumentsEnabled(params: {  "workDocumentsId"?: number; "value"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/EditWorkDocumentsEnabled`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "workDocumentsId": params["workDocumentsId"],
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getFirmDocuments(options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/GetFirmDocuments`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getFirmNews(options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/GetFirmNews`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchRatesOffers(params: {  "model"?: SearchRatesOffersModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/SearchRatesOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchWorkDocuments(params: {  "model"?: SearchWorkDocumentsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Moderator/SearchWorkDocuments`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ModeratorApi - functional programming interface
 */
export const ModeratorApiFp = {
    /**
     *
     * @param model
     */
    createFirmNews(params: { "model"?: FirmNewsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FirmNewsModel> {
        const fetchArgs = ModeratorApiFetchParamCreator.createFirmNews(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    deleteDocumentInfo(params: { "model"?: OfferInformationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OfferInformationModel> {
        const fetchArgs = ModeratorApiFetchParamCreator.deleteDocumentInfo(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param firmDocumentId
     */
    deleteFirmDocument(params: { "firmDocumentId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ModeratorApiFetchParamCreator.deleteFirmDocument(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param firmNewsId
     */
    deleteFirmNews(params: { "firmNewsId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ModeratorApiFetchParamCreator.deleteFirmNews(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    editDocumentInfo(params: { "model"?: OfferInformationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OfferInformationModel> {
        const fetchArgs = ModeratorApiFetchParamCreator.editDocumentInfo(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    editFirmNews(params: { "model"?: FirmNewsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FirmNewsModel> {
        const fetchArgs = ModeratorApiFetchParamCreator.editFirmNews(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    editRatesOffer(params: { "model"?: RatesOfferModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RatesOfferModel> {
        const fetchArgs = ModeratorApiFetchParamCreator.editRatesOffer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param workDocumentsId
     * @param value
     */
    editWorkDocumentsEnabled(params: { "workDocumentsId"?: number; "value"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ModeratorApiFetchParamCreator.editWorkDocumentsEnabled(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getFirmDocuments(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FirmDocumentModel>> {
        const fetchArgs = ModeratorApiFetchParamCreator.getFirmDocuments(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getFirmNews(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FirmNewsModel>> {
        const fetchArgs = ModeratorApiFetchParamCreator.getFirmNews(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchRatesOffers(params: { "model"?: SearchRatesOffersModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RatesOfferModel>> {
        const fetchArgs = ModeratorApiFetchParamCreator.searchRatesOffers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchWorkDocuments(params: { "model"?: SearchWorkDocumentsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkDocumentsModel>> {
        const fetchArgs = ModeratorApiFetchParamCreator.searchWorkDocuments(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ModeratorApi - object-oriented interface
 */
export class ModeratorApi extends BaseAPI {
    /**
     *
     * @param model
     */
    createFirmNews(params: {  "model"?: FirmNewsModel; }, options?: any) {
        return ModeratorApiFp.createFirmNews(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    deleteDocumentInfo(params: {  "model"?: OfferInformationModel; }, options?: any) {
        return ModeratorApiFp.deleteDocumentInfo(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param firmDocumentId
     */
    deleteFirmDocument(params: {  "firmDocumentId"?: number; }, options?: any) {
        return ModeratorApiFp.deleteFirmDocument(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param firmNewsId
     */
    deleteFirmNews(params: {  "firmNewsId"?: number; }, options?: any) {
        return ModeratorApiFp.deleteFirmNews(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    editDocumentInfo(params: {  "model"?: OfferInformationModel; }, options?: any) {
        return ModeratorApiFp.editDocumentInfo(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    editFirmNews(params: {  "model"?: FirmNewsModel; }, options?: any) {
        return ModeratorApiFp.editFirmNews(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    editRatesOffer(params: {  "model"?: RatesOfferModel; }, options?: any) {
        return ModeratorApiFp.editRatesOffer(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param workDocumentsId
     * @param value
     */
    editWorkDocumentsEnabled(params: {  "workDocumentsId"?: number; "value"?: boolean; }, options?: any) {
        return ModeratorApiFp.editWorkDocumentsEnabled(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getFirmDocuments(options?: any) {
        return ModeratorApiFp.getFirmDocuments(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getFirmNews(options?: any) {
        return ModeratorApiFp.getFirmNews(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchRatesOffers(params: {  "model"?: SearchRatesOffersModel; }, options?: any) {
        return ModeratorApiFp.searchRatesOffers(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchWorkDocuments(params: {  "model"?: SearchWorkDocumentsModel; }, options?: any) {
        return ModeratorApiFp.searchWorkDocuments(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ModeratorApi - factory interface
 */
export const ModeratorApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        createFirmNews(params: {  "model"?: FirmNewsModel; }, options?: any) {
            return ModeratorApiFp.createFirmNews(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        deleteDocumentInfo(params: {  "model"?: OfferInformationModel; }, options?: any) {
            return ModeratorApiFp.deleteDocumentInfo(params, options)(fetch, basePath);
        },
        /**
         *
         * @param firmDocumentId
         */
        deleteFirmDocument(params: {  "firmDocumentId"?: number; }, options?: any) {
            return ModeratorApiFp.deleteFirmDocument(params, options)(fetch, basePath);
        },
        /**
         *
         * @param firmNewsId
         */
        deleteFirmNews(params: {  "firmNewsId"?: number; }, options?: any) {
            return ModeratorApiFp.deleteFirmNews(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        editDocumentInfo(params: {  "model"?: OfferInformationModel; }, options?: any) {
            return ModeratorApiFp.editDocumentInfo(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        editFirmNews(params: {  "model"?: FirmNewsModel; }, options?: any) {
            return ModeratorApiFp.editFirmNews(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        editRatesOffer(params: {  "model"?: RatesOfferModel; }, options?: any) {
            return ModeratorApiFp.editRatesOffer(params, options)(fetch, basePath);
        },
        /**
         *
         * @param workDocumentsId
         * @param value
         */
        editWorkDocumentsEnabled(params: {  "workDocumentsId"?: number; "value"?: boolean; }, options?: any) {
            return ModeratorApiFp.editWorkDocumentsEnabled(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getFirmDocuments(options?: any) {
            return ModeratorApiFp.getFirmDocuments(options)(fetch, basePath);
        },
        /**
         *
         */
        getFirmNews(options?: any) {
            return ModeratorApiFp.getFirmNews(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchRatesOffers(params: {  "model"?: SearchRatesOffersModel; }, options?: any) {
            return ModeratorApiFp.searchRatesOffers(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchWorkDocuments(params: {  "model"?: SearchWorkDocumentsModel; }, options?: any) {
            return ModeratorApiFp.searchWorkDocuments(params, options)(fetch, basePath);
        },
    };
};


/**
 * MultiPortApi - fetch parameter creator
 */
export const MultiPortApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    addOffers(params: {  "model"?: CreateMultiPortOffersModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MultiPort/AddOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    currentMultiPort(options?: any): FetchArgs {
        const baseUrl = `/api/MultiPort/CurrentMultiPort`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param multiPortInstance
     */
    deleteMultiPortInstance(params: {  "multiPortInstance"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/MultiPort/DeleteMultiPortInstance`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "multiPortInstance": params["multiPortInstance"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param multiPortOfferId
     */
    deleteOffer(params: {  "multiPortOfferId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/MultiPort/DeleteOffer`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "multiPortOfferId": params["multiPortOfferId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param multiPortInstanceId
     */
    downloadMultiPorts(params: {  "multiPortInstanceId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/MultiPort/DownloadMultiPorts`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "multiPortInstanceId": params["multiPortInstanceId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    downloadRecentMultiPort(params: {  "model"?: DownloadRecentMultiPortModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MultiPort/DownloadRecentMultiPort`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchMultiPorts(params: {  "model"?: SearchMultiPortsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MultiPort/SearchMultiPorts`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    updateMultiPort(params: {  "model"?: UpdateMultiPortModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MultiPort/UpdateMultiPort`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * MultiPortApi - functional programming interface
 */
export const MultiPortApiFp = {
    /**
     *
     * @param model
     */
    addOffers(params: { "model"?: CreateMultiPortOffersModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MultiPortOfferModel>> {
        const fetchArgs = MultiPortApiFetchParamCreator.addOffers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    currentMultiPort(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultMultiPortInstanceModel> {
        const fetchArgs = MultiPortApiFetchParamCreator.currentMultiPort(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param multiPortInstance
     */
    deleteMultiPortInstance(params: { "multiPortInstance"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MultiPortApiFetchParamCreator.deleteMultiPortInstance(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param multiPortOfferId
     */
    deleteOffer(params: { "multiPortOfferId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MultiPortApiFetchParamCreator.deleteOffer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param multiPortInstanceId
     */
    downloadMultiPorts(params: { "multiPortInstanceId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MultiPortApiFetchParamCreator.downloadMultiPorts(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    downloadRecentMultiPort(params: { "model"?: DownloadRecentMultiPortModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MultiPortApiFetchParamCreator.downloadRecentMultiPort(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchMultiPorts(params: { "model"?: SearchMultiPortsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MultiPortInstanceModel>> {
        const fetchArgs = MultiPortApiFetchParamCreator.searchMultiPorts(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    updateMultiPort(params: { "model"?: UpdateMultiPortModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MultiPortApiFetchParamCreator.updateMultiPort(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * MultiPortApi - object-oriented interface
 */
export class MultiPortApi extends BaseAPI {
    /**
     *
     * @param model
     */
    addOffers(params: {  "model"?: CreateMultiPortOffersModel; }, options?: any) {
        return MultiPortApiFp.addOffers(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    currentMultiPort(options?: any) {
        return MultiPortApiFp.currentMultiPort(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param multiPortInstance
     */
    deleteMultiPortInstance(params: {  "multiPortInstance"?: number; }, options?: any) {
        return MultiPortApiFp.deleteMultiPortInstance(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param multiPortOfferId
     */
    deleteOffer(params: {  "multiPortOfferId"?: number; }, options?: any) {
        return MultiPortApiFp.deleteOffer(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param multiPortInstanceId
     */
    downloadMultiPorts(params: {  "multiPortInstanceId"?: number; }, options?: any) {
        return MultiPortApiFp.downloadMultiPorts(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    downloadRecentMultiPort(params: {  "model"?: DownloadRecentMultiPortModel; }, options?: any) {
        return MultiPortApiFp.downloadRecentMultiPort(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchMultiPorts(params: {  "model"?: SearchMultiPortsModel; }, options?: any) {
        return MultiPortApiFp.searchMultiPorts(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    updateMultiPort(params: {  "model"?: UpdateMultiPortModel; }, options?: any) {
        return MultiPortApiFp.updateMultiPort(params, options)(this.fetch, this.basePath);
    }
};

/**
 * MultiPortApi - factory interface
 */
export const MultiPortApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        addOffers(params: {  "model"?: CreateMultiPortOffersModel; }, options?: any) {
            return MultiPortApiFp.addOffers(params, options)(fetch, basePath);
        },
        /**
         *
         */
        currentMultiPort(options?: any) {
            return MultiPortApiFp.currentMultiPort(options)(fetch, basePath);
        },
        /**
         *
         * @param multiPortInstance
         */
        deleteMultiPortInstance(params: {  "multiPortInstance"?: number; }, options?: any) {
            return MultiPortApiFp.deleteMultiPortInstance(params, options)(fetch, basePath);
        },
        /**
         *
         * @param multiPortOfferId
         */
        deleteOffer(params: {  "multiPortOfferId"?: number; }, options?: any) {
            return MultiPortApiFp.deleteOffer(params, options)(fetch, basePath);
        },
        /**
         *
         * @param multiPortInstanceId
         */
        downloadMultiPorts(params: {  "multiPortInstanceId"?: number; }, options?: any) {
            return MultiPortApiFp.downloadMultiPorts(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        downloadRecentMultiPort(params: {  "model"?: DownloadRecentMultiPortModel; }, options?: any) {
            return MultiPortApiFp.downloadRecentMultiPort(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchMultiPorts(params: {  "model"?: SearchMultiPortsModel; }, options?: any) {
            return MultiPortApiFp.searchMultiPorts(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        updateMultiPort(params: {  "model"?: UpdateMultiPortModel; }, options?: any) {
            return MultiPortApiFp.updateMultiPort(params, options)(fetch, basePath);
        },
    };
};


/**
 * QuotationApi - fetch parameter creator
 */
export const QuotationApiFetchParamCreator = {
    /**
     *
     * @param criteriaCode
     * @param model
     */
    createQuotation(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/CreateQuotation`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "criteriaCode": params["criteriaCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param criteriaCode
     * @param model
     */
    createTemplate(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/CreateTemplate`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "criteriaCode": params["criteriaCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quotationId
     */
    deleteTemplate(params: {  "quotationId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/DeleteTemplate`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quotationId": params["quotationId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quotationId
     */
    download(params: {  "quotationId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/Download`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quotationId": params["quotationId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param criteriaCode
     * @param model
     */
    downloadPreview(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/DownloadPreview`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "criteriaCode": params["criteriaCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quotationId
     */
    downloadQuotationExcel(params: {  "quotationId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/DownloadQuotationExcel`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quotationId": params["quotationId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quotationId
     */
    downloadQuotationPdf(params: {  "quotationId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/DownloadQuotationPdf`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quotationId": params["quotationId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    downloadQuotationsCsv(params: {  "model"?: QuotationFilterModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/DownloadQuotationsCsv`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getNewReference(options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/GetNewReference`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quotationId
     */
    getQuotation(params: {  "quotationId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/GetQuotation`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quotationId": params["quotationId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getQuotationTemplates(options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/GetQuotationTemplates`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param reference
     */
    referenceIsValid(params: {  "reference"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/ReferenceIsValid`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "reference": params["reference"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    searchQuotations(params: {  "model"?: QuotationFilterModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/SearchQuotations`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    sendAgentQuotation(params: {  "model"?: SendAgentQuotationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/SendAgentQuotation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quotationId
     */
    sendQuotationEmail(params: {  "quotationId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/SendQuotationEmail`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quotationId": params["quotationId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param name
     */
    templateNameIsValid(params: {  "name"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/TemplateNameIsValid`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "name": params["name"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quotationId
     * @param comment
     */
    updateQuotationComment(params: {  "quotationId"?: number; "comment"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/UpdateQuotationComment`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quotationId": params["quotationId"],
            "comment": params["comment"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param quotationId
     * @param status
     */
    updateQuotationStatus(params: {  "quotationId"?: number; "status"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/UpdateQuotationStatus`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quotationId": params["quotationId"],
            "status": params["status"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param criteriaCode
     * @param model
     */
    updateTemplate(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Quotation/UpdateTemplate`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "criteriaCode": params["criteriaCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * QuotationApi - functional programming interface
 */
export const QuotationApiFp = {
    /**
     *
     * @param criteriaCode
     * @param model
     */
    createQuotation(params: { "criteriaCode"?: string; "model"?: QuotationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationModel> {
        const fetchArgs = QuotationApiFetchParamCreator.createQuotation(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param criteriaCode
     * @param model
     */
    createTemplate(params: { "criteriaCode"?: string; "model"?: QuotationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationModel> {
        const fetchArgs = QuotationApiFetchParamCreator.createTemplate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quotationId
     */
    deleteTemplate(params: { "quotationId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationApiFetchParamCreator.deleteTemplate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quotationId
     */
    download(params: { "quotationId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationApiFetchParamCreator.download(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param criteriaCode
     * @param model
     */
    downloadPreview(params: { "criteriaCode"?: string; "model"?: QuotationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationApiFetchParamCreator.downloadPreview(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quotationId
     */
    downloadQuotationExcel(params: { "quotationId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationApiFetchParamCreator.downloadQuotationExcel(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quotationId
     */
    downloadQuotationPdf(params: { "quotationId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationApiFetchParamCreator.downloadQuotationPdf(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    downloadQuotationsCsv(params: { "model"?: QuotationFilterModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationApiFetchParamCreator.downloadQuotationsCsv(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getNewReference(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultString> {
        const fetchArgs = QuotationApiFetchParamCreator.getNewReference(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quotationId
     */
    getQuotation(params: { "quotationId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationModel> {
        const fetchArgs = QuotationApiFetchParamCreator.getQuotation(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getQuotationTemplates(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuotationModel>> {
        const fetchArgs = QuotationApiFetchParamCreator.getQuotationTemplates(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param reference
     */
    referenceIsValid(params: { "reference"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultBoolean> {
        const fetchArgs = QuotationApiFetchParamCreator.referenceIsValid(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    searchQuotations(params: { "model"?: QuotationFilterModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuotationModel>> {
        const fetchArgs = QuotationApiFetchParamCreator.searchQuotations(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    sendAgentQuotation(params: { "model"?: SendAgentQuotationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationApiFetchParamCreator.sendAgentQuotation(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quotationId
     */
    sendQuotationEmail(params: { "quotationId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationApiFetchParamCreator.sendQuotationEmail(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param name
     */
    templateNameIsValid(params: { "name"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultBoolean> {
        const fetchArgs = QuotationApiFetchParamCreator.templateNameIsValid(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quotationId
     * @param comment
     */
    updateQuotationComment(params: { "quotationId"?: number; "comment"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = QuotationApiFetchParamCreator.updateQuotationComment(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param quotationId
     * @param status
     */
    updateQuotationStatus(params: { "quotationId"?: number; "status"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = QuotationApiFetchParamCreator.updateQuotationStatus(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param criteriaCode
     * @param model
     */
    updateTemplate(params: { "criteriaCode"?: string; "model"?: QuotationModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationModel> {
        const fetchArgs = QuotationApiFetchParamCreator.updateTemplate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * QuotationApi - object-oriented interface
 */
export class QuotationApi extends BaseAPI {
    /**
     *
     * @param criteriaCode
     * @param model
     */
    createQuotation(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any) {
        return QuotationApiFp.createQuotation(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param criteriaCode
     * @param model
     */
    createTemplate(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any) {
        return QuotationApiFp.createTemplate(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quotationId
     */
    deleteTemplate(params: {  "quotationId"?: number; }, options?: any) {
        return QuotationApiFp.deleteTemplate(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quotationId
     */
    download(params: {  "quotationId"?: number; }, options?: any) {
        return QuotationApiFp.download(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param criteriaCode
     * @param model
     */
    downloadPreview(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any) {
        return QuotationApiFp.downloadPreview(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quotationId
     */
    downloadQuotationExcel(params: {  "quotationId"?: number; }, options?: any) {
        return QuotationApiFp.downloadQuotationExcel(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quotationId
     */
    downloadQuotationPdf(params: {  "quotationId"?: number; }, options?: any) {
        return QuotationApiFp.downloadQuotationPdf(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    downloadQuotationsCsv(params: {  "model"?: QuotationFilterModel; }, options?: any) {
        return QuotationApiFp.downloadQuotationsCsv(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getNewReference(options?: any) {
        return QuotationApiFp.getNewReference(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quotationId
     */
    getQuotation(params: {  "quotationId"?: number; }, options?: any) {
        return QuotationApiFp.getQuotation(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getQuotationTemplates(options?: any) {
        return QuotationApiFp.getQuotationTemplates(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param reference
     */
    referenceIsValid(params: {  "reference"?: string; }, options?: any) {
        return QuotationApiFp.referenceIsValid(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    searchQuotations(params: {  "model"?: QuotationFilterModel; }, options?: any) {
        return QuotationApiFp.searchQuotations(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    sendAgentQuotation(params: {  "model"?: SendAgentQuotationModel; }, options?: any) {
        return QuotationApiFp.sendAgentQuotation(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quotationId
     */
    sendQuotationEmail(params: {  "quotationId"?: number; }, options?: any) {
        return QuotationApiFp.sendQuotationEmail(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param name
     */
    templateNameIsValid(params: {  "name"?: string; }, options?: any) {
        return QuotationApiFp.templateNameIsValid(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quotationId
     * @param comment
     */
    updateQuotationComment(params: {  "quotationId"?: number; "comment"?: string; }, options?: any) {
        return QuotationApiFp.updateQuotationComment(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param quotationId
     * @param status
     */
    updateQuotationStatus(params: {  "quotationId"?: number; "status"?: string; }, options?: any) {
        return QuotationApiFp.updateQuotationStatus(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param criteriaCode
     * @param model
     */
    updateTemplate(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any) {
        return QuotationApiFp.updateTemplate(params, options)(this.fetch, this.basePath);
    }
};

/**
 * QuotationApi - factory interface
 */
export const QuotationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param criteriaCode
         * @param model
         */
        createQuotation(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any) {
            return QuotationApiFp.createQuotation(params, options)(fetch, basePath);
        },
        /**
         *
         * @param criteriaCode
         * @param model
         */
        createTemplate(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any) {
            return QuotationApiFp.createTemplate(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quotationId
         */
        deleteTemplate(params: {  "quotationId"?: number; }, options?: any) {
            return QuotationApiFp.deleteTemplate(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quotationId
         */
        download(params: {  "quotationId"?: number; }, options?: any) {
            return QuotationApiFp.download(params, options)(fetch, basePath);
        },
        /**
         *
         * @param criteriaCode
         * @param model
         */
        downloadPreview(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any) {
            return QuotationApiFp.downloadPreview(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quotationId
         */
        downloadQuotationExcel(params: {  "quotationId"?: number; }, options?: any) {
            return QuotationApiFp.downloadQuotationExcel(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quotationId
         */
        downloadQuotationPdf(params: {  "quotationId"?: number; }, options?: any) {
            return QuotationApiFp.downloadQuotationPdf(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        downloadQuotationsCsv(params: {  "model"?: QuotationFilterModel; }, options?: any) {
            return QuotationApiFp.downloadQuotationsCsv(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getNewReference(options?: any) {
            return QuotationApiFp.getNewReference(options)(fetch, basePath);
        },
        /**
         *
         * @param quotationId
         */
        getQuotation(params: {  "quotationId"?: number; }, options?: any) {
            return QuotationApiFp.getQuotation(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getQuotationTemplates(options?: any) {
            return QuotationApiFp.getQuotationTemplates(options)(fetch, basePath);
        },
        /**
         *
         * @param reference
         */
        referenceIsValid(params: {  "reference"?: string; }, options?: any) {
            return QuotationApiFp.referenceIsValid(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        searchQuotations(params: {  "model"?: QuotationFilterModel; }, options?: any) {
            return QuotationApiFp.searchQuotations(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        sendAgentQuotation(params: {  "model"?: SendAgentQuotationModel; }, options?: any) {
            return QuotationApiFp.sendAgentQuotation(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quotationId
         */
        sendQuotationEmail(params: {  "quotationId"?: number; }, options?: any) {
            return QuotationApiFp.sendQuotationEmail(params, options)(fetch, basePath);
        },
        /**
         *
         * @param name
         */
        templateNameIsValid(params: {  "name"?: string; }, options?: any) {
            return QuotationApiFp.templateNameIsValid(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quotationId
         * @param comment
         */
        updateQuotationComment(params: {  "quotationId"?: number; "comment"?: string; }, options?: any) {
            return QuotationApiFp.updateQuotationComment(params, options)(fetch, basePath);
        },
        /**
         *
         * @param quotationId
         * @param status
         */
        updateQuotationStatus(params: {  "quotationId"?: number; "status"?: string; }, options?: any) {
            return QuotationApiFp.updateQuotationStatus(params, options)(fetch, basePath);
        },
        /**
         *
         * @param criteriaCode
         * @param model
         */
        updateTemplate(params: {  "criteriaCode"?: string; "model"?: QuotationModel; }, options?: any) {
            return QuotationApiFp.updateTemplate(params, options)(fetch, basePath);
        },
    };
};


/**
 * QuotationContactApi - fetch parameter creator
 */
export const QuotationContactApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/QuotationContact/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param models
     */
    bulkUpdate(params: {  "models"?: Array<QuotationContactModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/QuotationContact/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: QuotationContactModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/QuotationContact/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param id
     */
    disableContact(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/QuotationContact/DisableContact`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/QuotationContact/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param companyName
     */
    searchContactsByCompanyName(params: {  "companyName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/QuotationContact/SearchContactsByCompanyName`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "companyName": params["companyName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: QuotationContactModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/QuotationContact/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * QuotationContactApi - functional programming interface
 */
export const QuotationContactApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationContactApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param models
     */
    bulkUpdate(params: { "models"?: Array<QuotationContactModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuotationContactModel>> {
        const fetchArgs = QuotationContactApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: QuotationContactModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationContactModel> {
        const fetchArgs = QuotationContactApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param id
     */
    disableContact(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = QuotationContactApiFetchParamCreator.disableContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuotationContactModel>> {
        const fetchArgs = QuotationContactApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param companyName
     */
    searchContactsByCompanyName(params: { "companyName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuotationContactModel>> {
        const fetchArgs = QuotationContactApiFetchParamCreator.searchContactsByCompanyName(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: QuotationContactModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationContactModel> {
        const fetchArgs = QuotationContactApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * QuotationContactApi - object-oriented interface
 */
export class QuotationContactApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return QuotationContactApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param models
     */
    bulkUpdate(params: {  "models"?: Array<QuotationContactModel>; }, options?: any) {
        return QuotationContactApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: QuotationContactModel; }, options?: any) {
        return QuotationContactApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param id
     */
    disableContact(params: {  "id"?: number; }, options?: any) {
        return QuotationContactApiFp.disableContact(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return QuotationContactApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param companyName
     */
    searchContactsByCompanyName(params: {  "companyName"?: string; }, options?: any) {
        return QuotationContactApiFp.searchContactsByCompanyName(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: QuotationContactModel; }, options?: any) {
        return QuotationContactApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * QuotationContactApi - factory interface
 */
export const QuotationContactApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return QuotationContactApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param models
         */
        bulkUpdate(params: {  "models"?: Array<QuotationContactModel>; }, options?: any) {
            return QuotationContactApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: QuotationContactModel; }, options?: any) {
            return QuotationContactApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         * @param id
         */
        disableContact(params: {  "id"?: number; }, options?: any) {
            return QuotationContactApiFp.disableContact(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return QuotationContactApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param companyName
         */
        searchContactsByCompanyName(params: {  "companyName"?: string; }, options?: any) {
            return QuotationContactApiFp.searchContactsByCompanyName(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: QuotationContactModel; }, options?: any) {
            return QuotationContactApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * QuotationSettingsApi - fetch parameter creator
 */
export const QuotationSettingsApiFetchParamCreator = {
    /**
     *
     */
    getQuotationSettings(options?: any): FetchArgs {
        const baseUrl = `/api/QuotationSettings/GetQuotationSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: QuotationSettingsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/QuotationSettings/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * QuotationSettingsApi - functional programming interface
 */
export const QuotationSettingsApiFp = {
    /**
     *
     */
    getQuotationSettings(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationSettingsModel> {
        const fetchArgs = QuotationSettingsApiFetchParamCreator.getQuotationSettings(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: QuotationSettingsModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuotationSettingsModel> {
        const fetchArgs = QuotationSettingsApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * QuotationSettingsApi - object-oriented interface
 */
export class QuotationSettingsApi extends BaseAPI {
    /**
     *
     */
    getQuotationSettings(options?: any) {
        return QuotationSettingsApiFp.getQuotationSettings(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: QuotationSettingsModel; }, options?: any) {
        return QuotationSettingsApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * QuotationSettingsApi - factory interface
 */
export const QuotationSettingsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        getQuotationSettings(options?: any) {
            return QuotationSettingsApiFp.getQuotationSettings(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: QuotationSettingsModel; }, options?: any) {
            return QuotationSettingsApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * RatesFetcherApi - fetch parameter creator
 */
export const RatesFetcherApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    carrierRatesPlatformsStatus(params: {  "model"?: CarrierRatesPlatformsStatusModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/RatesFetcher/CarrierRatesPlatformsStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getAvailableRatesFetchers(options?: any): FetchArgs {
        const baseUrl = `/api/RatesFetcher/GetAvailableRatesFetchers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    getOnlineCarrierOffers(params: {  "model"?: GetOnlineCarrierOffersModel; }, options?: any): FetchArgs {
        const baseUrl = `/v2/GetOnlineCarrierOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    getOnlineCarrierOffersOld(params: {  "model"?: GetOnlineCarrierOffersModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/RatesFetcher/GetOnlineCarrierOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    getOnlineInlandCarrierOffers(params: {  "model"?: GetOnlineInlandCarrierOffersModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/RatesFetcher/GetOnlineInlandCarrierOffers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param chargeSetId
     * @param criteriaCode
     * @param deepLink
     */
    processDeepLink(params: {  "chargeSetId"?: number; "criteriaCode"?: string; "deepLink"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/RatesFetcher/ProcessDeepLink`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "chargeSetId": params["chargeSetId"],
            "criteriaCode": params["criteriaCode"],
            "deepLink": params["deepLink"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RatesFetcherApi - functional programming interface
 */
export const RatesFetcherApiFp = {
    /**
     *
     * @param model
     */
    carrierRatesPlatformsStatus(params: { "model"?: CarrierRatesPlatformsStatusModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierRatesPlatformModel>> {
        const fetchArgs = RatesFetcherApiFetchParamCreator.carrierRatesPlatformsStatus(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getAvailableRatesFetchers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CarrierRatesPlatformModel>> {
        const fetchArgs = RatesFetcherApiFetchParamCreator.getAvailableRatesFetchers(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    getOnlineCarrierOffers(params: { "model"?: GetOnlineCarrierOffersModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierOffersWithErrors> {
        const fetchArgs = RatesFetcherApiFetchParamCreator.getOnlineCarrierOffers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    getOnlineCarrierOffersOld(params: { "model"?: GetOnlineCarrierOffersModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierOffersWithErrors> {
        const fetchArgs = RatesFetcherApiFetchParamCreator.getOnlineCarrierOffersOld(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    getOnlineInlandCarrierOffers(params: { "model"?: GetOnlineInlandCarrierOffersModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlandCarrierOffersWithErrors> {
        const fetchArgs = RatesFetcherApiFetchParamCreator.getOnlineInlandCarrierOffers(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param chargeSetId
     * @param criteriaCode
     * @param deepLink
     */
    processDeepLink(params: { "chargeSetId"?: number; "criteriaCode"?: string; "deepLink"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UriResult> {
        const fetchArgs = RatesFetcherApiFetchParamCreator.processDeepLink(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RatesFetcherApi - object-oriented interface
 */
export class RatesFetcherApi extends BaseAPI {
    /**
     *
     * @param model
     */
    carrierRatesPlatformsStatus(params: {  "model"?: CarrierRatesPlatformsStatusModel; }, options?: any) {
        return RatesFetcherApiFp.carrierRatesPlatformsStatus(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getAvailableRatesFetchers(options?: any) {
        return RatesFetcherApiFp.getAvailableRatesFetchers(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    getOnlineCarrierOffers(params: {  "model"?: GetOnlineCarrierOffersModel; }, options?: any) {
        return RatesFetcherApiFp.getOnlineCarrierOffers(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    getOnlineCarrierOffersOld(params: {  "model"?: GetOnlineCarrierOffersModel; }, options?: any) {
        return RatesFetcherApiFp.getOnlineCarrierOffersOld(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    getOnlineInlandCarrierOffers(params: {  "model"?: GetOnlineInlandCarrierOffersModel; }, options?: any) {
        return RatesFetcherApiFp.getOnlineInlandCarrierOffers(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param chargeSetId
     * @param criteriaCode
     * @param deepLink
     */
    processDeepLink(params: {  "chargeSetId"?: number; "criteriaCode"?: string; "deepLink"?: string; }, options?: any) {
        return RatesFetcherApiFp.processDeepLink(params, options)(this.fetch, this.basePath);
    }
};

/**
 * RatesFetcherApi - factory interface
 */
export const RatesFetcherApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        carrierRatesPlatformsStatus(params: {  "model"?: CarrierRatesPlatformsStatusModel; }, options?: any) {
            return RatesFetcherApiFp.carrierRatesPlatformsStatus(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getAvailableRatesFetchers(options?: any) {
            return RatesFetcherApiFp.getAvailableRatesFetchers(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        getOnlineCarrierOffers(params: {  "model"?: GetOnlineCarrierOffersModel; }, options?: any) {
            return RatesFetcherApiFp.getOnlineCarrierOffers(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        getOnlineCarrierOffersOld(params: {  "model"?: GetOnlineCarrierOffersModel; }, options?: any) {
            return RatesFetcherApiFp.getOnlineCarrierOffersOld(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        getOnlineInlandCarrierOffers(params: {  "model"?: GetOnlineInlandCarrierOffersModel; }, options?: any) {
            return RatesFetcherApiFp.getOnlineInlandCarrierOffers(params, options)(fetch, basePath);
        },
        /**
         *
         * @param chargeSetId
         * @param criteriaCode
         * @param deepLink
         */
        processDeepLink(params: {  "chargeSetId"?: number; "criteriaCode"?: string; "deepLink"?: string; }, options?: any) {
            return RatesFetcherApiFp.processDeepLink(params, options)(fetch, basePath);
        },
    };
};


/**
 * RatesOfferApi - fetch parameter creator
 */
export const RatesOfferApiFetchParamCreator = {
    /**
     *
     * @param term
     * @param carrierId
     */
    getRatesOffersFromTerm(params: {  "term"?: string; "carrierId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/RatesOffer/GetRatesOffersFromTerm`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "term": params["term"],
            "carrierId": params["carrierId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RatesOfferApi - functional programming interface
 */
export const RatesOfferApiFp = {
    /**
     *
     * @param term
     * @param carrierId
     */
    getRatesOffersFromTerm(params: { "term"?: string; "carrierId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RatesOfferModel>> {
        const fetchArgs = RatesOfferApiFetchParamCreator.getRatesOffersFromTerm(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RatesOfferApi - object-oriented interface
 */
export class RatesOfferApi extends BaseAPI {
    /**
     *
     * @param term
     * @param carrierId
     */
    getRatesOffersFromTerm(params: {  "term"?: string; "carrierId"?: number; }, options?: any) {
        return RatesOfferApiFp.getRatesOffersFromTerm(params, options)(this.fetch, this.basePath);
    }
};

/**
 * RatesOfferApi - factory interface
 */
export const RatesOfferApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param term
         * @param carrierId
         */
        getRatesOffersFromTerm(params: {  "term"?: string; "carrierId"?: number; }, options?: any) {
            return RatesOfferApiFp.getRatesOffersFromTerm(params, options)(fetch, basePath);
        },
    };
};


/**
 * RouteRatesPlatformDatasApi - fetch parameter creator
 */
export const RouteRatesPlatformDatasApiFetchParamCreator = {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/RouteRatesPlatformDatas/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: RouteRatesPlatformDataModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/RouteRatesPlatformDatas/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/RouteRatesPlatformDatas/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: RouteRatesPlatformDataModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/RouteRatesPlatformDatas/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RouteRatesPlatformDatasApi - functional programming interface
 */
export const RouteRatesPlatformDatasApiFp = {
    /**
     *
     * @param id
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = RouteRatesPlatformDatasApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: RouteRatesPlatformDataModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierRatesSettingsModel> {
        const fetchArgs = RouteRatesPlatformDatasApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RouteRatesPlatformDataModel>> {
        const fetchArgs = RouteRatesPlatformDatasApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: RouteRatesPlatformDataModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierRatesSettingsModel> {
        const fetchArgs = RouteRatesPlatformDatasApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RouteRatesPlatformDatasApi - object-oriented interface
 */
export class RouteRatesPlatformDatasApi extends BaseAPI {
    /**
     *
     * @param id
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return RouteRatesPlatformDatasApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: RouteRatesPlatformDataModel; }, options?: any) {
        return RouteRatesPlatformDatasApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getEntities(options?: any) {
        return RouteRatesPlatformDatasApiFp.getEntities(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: RouteRatesPlatformDataModel; }, options?: any) {
        return RouteRatesPlatformDatasApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * RouteRatesPlatformDatasApi - factory interface
 */
export const RouteRatesPlatformDatasApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param id
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return RouteRatesPlatformDatasApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: RouteRatesPlatformDataModel; }, options?: any) {
            return RouteRatesPlatformDatasApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getEntities(options?: any) {
            return RouteRatesPlatformDatasApiFp.getEntities(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: RouteRatesPlatformDataModel; }, options?: any) {
            return RouteRatesPlatformDatasApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * SeedApi - fetch parameter creator
 */
export const SeedApiFetchParamCreator = {

    /**
 *
 */
    getChargeNames(options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetChargeNames`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },

    /**
     *
     */
    getCarriers(options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetCarriers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getMinimumVersion(options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetMinimumVersion`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getOptions(options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetOptions`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * This api is cached for a short period so the risk of missing is is very low but does exist
     * @summary Get the data needed to make sens of the id returned in other Apis, such as the containers (sizeTypeId) or code of charge (ChargeNameId)
     */
    getSeed(options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetSeed`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param langCode
     */
    getTexts(params: {  "langCode"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetTexts`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "langCode": params["langCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SeedApi - functional programming interface
 */
export const SeedApiFp = {
    /**
 *
 */
    getChargeNames(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: ChargeNameModel; }> {
        const fetchArgs = SeedApiFetchParamCreator.getChargeNames(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getCarriers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: CarrierModel; }> {
        const fetchArgs = SeedApiFetchParamCreator.getCarriers(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getMinimumVersion(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
        const fetchArgs = SeedApiFetchParamCreator.getMinimumVersion(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getOptions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServicesOptionsModel> {
        const fetchArgs = SeedApiFetchParamCreator.getOptions(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * This api is cached for a short period so the risk of missing is is very low but does exist
     * @summary Get the data needed to make sens of the id returned in other Apis, such as the containers (sizeTypeId) or code of charge (ChargeNameId)
     */
    getSeed(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SeedModel> {
        const fetchArgs = SeedApiFetchParamCreator.getSeed(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param langCode
     */
    getTexts(params: { "langCode"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: string; }> {
        const fetchArgs = SeedApiFetchParamCreator.getTexts(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SeedApi - object-oriented interface
 */
export class SeedApi extends BaseAPI {
    /**
 *
 */
    getChargeNames(options?: any) {
        return SeedApiFp.getChargeNames(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getCarriers(options?: any) {
        return SeedApiFp.getCarriers(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getMinimumVersion(options?: any) {
        return SeedApiFp.getMinimumVersion(options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getOptions(options?: any) {
        return SeedApiFp.getOptions(options)(this.fetch, this.basePath);
    }
    /**
     * This api is cached for a short period so the risk of missing is is very low but does exist
     * @summary Get the data needed to make sens of the id returned in other Apis, such as the containers (sizeTypeId) or code of charge (ChargeNameId)
     */
    getSeed(options?: any) {
        return SeedApiFp.getSeed(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param langCode
     */
    getTexts(params: {  "langCode"?: string; }, options?: any) {
        return SeedApiFp.getTexts(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SeedApi - factory interface
 */
export const SeedApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        getCarriers(options?: any) {
            return SeedApiFp.getCarriers(options)(fetch, basePath);
        },
        /**
         *
         */
        getMinimumVersion(options?: any) {
            return SeedApiFp.getMinimumVersion(options)(fetch, basePath);
        },
        /**
         *
         */
        getOptions(options?: any) {
            return SeedApiFp.getOptions(options)(fetch, basePath);
        },
        /**
         * This api is cached for a short period so the risk of missing is is very low but does exist
         * @summary Get the data needed to make sens of the id returned in other Apis, such as the containers (sizeTypeId) or code of charge (ChargeNameId)
         */
        getSeed(options?: any) {
            return SeedApiFp.getSeed(options)(fetch, basePath);
        },
        /**
         *
         * @param langCode
         */
        getTexts(params: {  "langCode"?: string; }, options?: any) {
            return SeedApiFp.getTexts(params, options)(fetch, basePath);
        },
    };
};


/**
 * SelectionApi - fetch parameter creator
 */
export const SelectionApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    downloadMemo(params: {  "model"?: OfferSelectionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/DownloadMemo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Download the document(s) from a the id of the chargeSet
     * @param chargeSetId
     */
    downloadWorkDocuments(params: {  "chargeSetId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/DownloadWorkDocuments`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "chargeSetId": params["chargeSetId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param routeId
     * @param from
     * @param to
     */
    getBookingForm(params: {  "routeId"?: number; "from"?: Date; "to"?: Date; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/GetBookingForm`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "routeId": params["routeId"],
            "from": params["from"],
            "to": params["to"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param routeId
     * @param from
     * @param to
     */
    getCarrierForm(params: {  "routeId"?: number; "from"?: Date; "to"?: Date; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/GetCarrierForm`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "routeId": params["routeId"],
            "from": params["from"],
            "to": params["to"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * The code of the criteria is only used to know the containers and filters you want applied to this offer
     * @summary Get a specific offer based on the id of the depart, the chargeSet and the code of the criteria
     * @param departId
     * @param chargeSetId
     * @param routeId
     * @param criteriaCode
     */
    getCarrierOffer(params: {  "departId"?: number; "chargeSetId"?: number; "criteriaCode"?: string; "routeId"?: number }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/GetCarrierOffer`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "departId": params["departId"],
            "chargeSetId": params["chargeSetId"],
            "routeId": params["routeId"],
            "criteriaCode": params["criteriaCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from a criteria code (ex: FRFOS-yJGDsx-CNTXG)
     * @param code
     */
    searchSelection(params: {  "code"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/SearchSelection`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "code": params["code"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from the criteria sent by post in JSON
     * @param criteria
     */
    searchSelection_1(params: {  "criteria"?: CriteriaModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/SearchSelection`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["criteria"]) {
            fetchOptions.body = JSON.stringify(params["criteria"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    sendAskBooking(params: {  "model"?: SendAskBookingModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/SendAskBooking`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    sendAskRates(params: {  "model"?: SendAskRatesModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/SendAskRates`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    sendMemo(params: {  "model"?: OfferSelectionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/SendMemo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    setSatisfied(params: {  "model"?: SetSatisfiedModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Selection/SetSatisfied`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SelectionApi - functional programming interface
 */
export const SelectionApiFp = {
    /**
     *
     * @param model
     */
    downloadMemo(params: { "model"?: OfferSelectionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SelectionApiFetchParamCreator.downloadMemo(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Download the document(s) from a the id of the chargeSet
     * @param chargeSetId
     */
    downloadWorkDocuments(params: { "chargeSetId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SelectionApiFetchParamCreator.downloadWorkDocuments(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param routeId
     * @param from
     * @param to
     */
    getBookingForm(params: { "routeId"?: number; "from"?: Date; "to"?: Date;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultFormModel> {
        const fetchArgs = SelectionApiFetchParamCreator.getBookingForm(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param routeId
     * @param from
     * @param to
     */
    getCarrierForm(params: { "routeId"?: number; "from"?: Date; "to"?: Date;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultFormModel> {
        const fetchArgs = SelectionApiFetchParamCreator.getCarrierForm(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * The code of the criteria is only used to know the containers and filters you want applied to this offer
     * @summary Get a specific offer based on the id of the depart, the chargeSet and the code of the criteria
     * @param departId
     * @param chargeSetId
     * @param criteriaCode
     */
    getCarrierOffer(params: { "departId"?: number; "chargeSetId"?: number; "criteriaCode"?: string; "routeId"?: number; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CarrierOfferModel> {
        const fetchArgs = SelectionApiFetchParamCreator.getCarrierOffer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from a criteria code (ex: FRFOS-yJGDsx-CNTXG)
     * @param code
     */
    searchSelection(params: { "code"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SelectionModel> {
        const fetchArgs = SelectionApiFetchParamCreator.searchSelection(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from the criteria sent by post in JSON
     * @param criteria
     */
    searchSelection_1(params: { "criteria"?: CriteriaModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SelectionModel> {
        const fetchArgs = SelectionApiFetchParamCreator.searchSelection_1(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    sendAskBooking(params: { "model"?: SendAskBookingModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SelectionApiFetchParamCreator.sendAskBooking(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    sendAskRates(params: { "model"?: SendAskRatesModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SelectionApiFetchParamCreator.sendAskRates(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    sendMemo(params: { "model"?: OfferSelectionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SelectionApiFetchParamCreator.sendMemo(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    setSatisfied(params: { "model"?: SetSatisfiedModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SelectionApiFetchParamCreator.setSatisfied(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SelectionApi - object-oriented interface
 */
export class SelectionApi extends BaseAPI {
    /**
     *
     * @param model
     */
    downloadMemo(params: {  "model"?: OfferSelectionModel; }, options?: any) {
        return SelectionApiFp.downloadMemo(params, options)(this.fetch, this.basePath);
    }
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Download the document(s) from a the id of the chargeSet
     * @param chargeSetId
     */
    downloadWorkDocuments(params: {  "chargeSetId"?: number; }, options?: any) {
        return SelectionApiFp.downloadWorkDocuments(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param routeId
     * @param from
     * @param to
     */
    getBookingForm(params: {  "routeId"?: number; "from"?: Date; "to"?: Date; }, options?: any) {
        return SelectionApiFp.getBookingForm(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param routeId
     * @param from
     * @param to
     */
    getCarrierForm(params: {  "routeId"?: number; "from"?: Date; "to"?: Date; }, options?: any) {
        return SelectionApiFp.getCarrierForm(params, options)(this.fetch, this.basePath);
    }
    /**
     * The code of the criteria is only used to know the containers and filters you want applied to this offer
     * @summary Get a specific offer based on the id of the depart, the chargeSet and the code of the criteria
     * @param departId
     * @param chargeSetId
     * @param criteriaCode
     */
    getCarrierOffer(params: { "departId"?: number; "chargeSetId"?: number; "routeId"?: number; "criteriaCode"?: string; }, options?: any) {
        return SelectionApiFp.getCarrierOffer(params, options)(this.fetch, this.basePath);
    }
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from a criteria code (ex: FRFOS-yJGDsx-CNTXG)
     * @param code
     */
    searchSelection(params: {  "code"?: string; }, options?: any) {
        return SelectionApiFp.searchSelection(params, options)(this.fetch, this.basePath);
    }
    /**
     * Can be used to look for offers on a specific pol/pod
     * @summary Retrieve the result of an Okargo search from the criteria sent by post in JSON
     * @param criteria
     */
    searchSelection_1(params: {  "criteria"?: CriteriaModel; }, options?: any) {
        return SelectionApiFp.searchSelection_1(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    sendAskBooking(params: {  "model"?: SendAskBookingModel; }, options?: any) {
        return SelectionApiFp.sendAskBooking(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    sendAskRates(params: {  "model"?: SendAskRatesModel; }, options?: any) {
        return SelectionApiFp.sendAskRates(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    sendMemo(params: {  "model"?: OfferSelectionModel; }, options?: any) {
        return SelectionApiFp.sendMemo(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    setSatisfied(params: {  "model"?: SetSatisfiedModel; }, options?: any) {
        return SelectionApiFp.setSatisfied(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SelectionApi - factory interface
 */
export const SelectionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        downloadMemo(params: {  "model"?: OfferSelectionModel; }, options?: any) {
            return SelectionApiFp.downloadMemo(params, options)(fetch, basePath);
        },
        /**
         * Can be used to look for offers on a specific pol/pod
         * @summary Download the document(s) from a the id of the chargeSet
         * @param chargeSetId
         */
        downloadWorkDocuments(params: {  "chargeSetId"?: number; }, options?: any) {
            return SelectionApiFp.downloadWorkDocuments(params, options)(fetch, basePath);
        },
        /**
         *
         * @param routeId
         * @param from
         * @param to
         */
        getBookingForm(params: {  "routeId"?: number; "from"?: Date; "to"?: Date; }, options?: any) {
            return SelectionApiFp.getBookingForm(params, options)(fetch, basePath);
        },
        /**
         *
         * @param routeId
         * @param from
         * @param to
         */
        getCarrierForm(params: {  "routeId"?: number; "from"?: Date; "to"?: Date; }, options?: any) {
            return SelectionApiFp.getCarrierForm(params, options)(fetch, basePath);
        },
        /**
         * The code of the criteria is only used to know the containers and filters you want applied to this offer
         * @summary Get a specific offer based on the id of the depart, the chargeSet and the code of the criteria
         * @param departId
         * @param chargeSetId
         * @param criteriaCode
         */
        getCarrierOffer(params: {  "departId"?: number; "chargeSetId"?: number; "criteriaCode"?: string; "routeId"?:number }, options?: any) {
            return SelectionApiFp.getCarrierOffer(params, options)(fetch, basePath);
        },
        /**
         * Can be used to look for offers on a specific pol/pod
         * @summary Retrieve the result of an Okargo search from a criteria code (ex: FRFOS-yJGDsx-CNTXG)
         * @param code
         */
        searchSelection(params: {  "code"?: string; }, options?: any) {
            return SelectionApiFp.searchSelection(params, options)(fetch, basePath);
        },
        /**
         * Can be used to look for offers on a specific pol/pod
         * @summary Retrieve the result of an Okargo search from the criteria sent by post in JSON
         * @param criteria
         */
        searchSelection_1(params: {  "criteria"?: CriteriaModel; }, options?: any) {
            return SelectionApiFp.searchSelection_1(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        sendAskBooking(params: {  "model"?: SendAskBookingModel; }, options?: any) {
            return SelectionApiFp.sendAskBooking(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        sendAskRates(params: {  "model"?: SendAskRatesModel; }, options?: any) {
            return SelectionApiFp.sendAskRates(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        sendMemo(params: {  "model"?: OfferSelectionModel; }, options?: any) {
            return SelectionApiFp.sendMemo(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        setSatisfied(params: {  "model"?: SetSatisfiedModel; }, options?: any) {
            return SelectionApiFp.setSatisfied(params, options)(fetch, basePath);
        },
    };
};


/**
 * SellingRatesApi - fetch parameter creator
 */
export const SellingRatesApiFetchParamCreator = {
    /**
     *
     * @param sellingRatesId
     */
    _delete(params: {  "sellingRatesId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SellingRates/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "sellingRatesId": params["sellingRatesId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    create(params: {  "model"?: SellingRatesModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SellingRates/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     */
    getAll(options?: any): FetchArgs {
        const baseUrl = `/api/SellingRates/GetAll`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    getByTraffic(params: {  "model"?: CriteriaModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SellingRates/GetByTraffic`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param model
     */
    update(params: {  "model"?: SellingRatesModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SellingRates/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SellingRatesApi - functional programming interface
 */
export const SellingRatesApiFp = {
    /**
     *
     * @param sellingRatesId
     */
    _delete(params: { "sellingRatesId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SellingRatesApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    create(params: { "model"?: SellingRatesModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SellingRatesModel> {
        const fetchArgs = SellingRatesApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     */
    getAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SellingRatesModel>> {
        const fetchArgs = SellingRatesApiFetchParamCreator.getAll(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    getByTraffic(params: { "model"?: CriteriaModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SellingRatesModel>> {
        const fetchArgs = SellingRatesApiFetchParamCreator.getByTraffic(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param model
     */
    update(params: { "model"?: SellingRatesModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SellingRatesModel> {
        const fetchArgs = SellingRatesApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SellingRatesApi - object-oriented interface
 */
export class SellingRatesApi extends BaseAPI {
    /**
     *
     * @param sellingRatesId
     */
    _delete(params: {  "sellingRatesId"?: number; }, options?: any) {
        return SellingRatesApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    create(params: {  "model"?: SellingRatesModel; }, options?: any) {
        return SellingRatesApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     */
    getAll(options?: any) {
        return SellingRatesApiFp.getAll(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    getByTraffic(params: {  "model"?: CriteriaModel; }, options?: any) {
        return SellingRatesApiFp.getByTraffic(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param model
     */
    update(params: {  "model"?: SellingRatesModel; }, options?: any) {
        return SellingRatesApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SellingRatesApi - factory interface
 */
export const SellingRatesApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param sellingRatesId
         */
        _delete(params: {  "sellingRatesId"?: number; }, options?: any) {
            return SellingRatesApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        create(params: {  "model"?: SellingRatesModel; }, options?: any) {
            return SellingRatesApiFp.create(params, options)(fetch, basePath);
        },
        /**
         *
         */
        getAll(options?: any) {
            return SellingRatesApiFp.getAll(options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        getByTraffic(params: {  "model"?: CriteriaModel; }, options?: any) {
            return SellingRatesApiFp.getByTraffic(params, options)(fetch, basePath);
        },
        /**
         *
         * @param model
         */
        update(params: {  "model"?: SellingRatesModel; }, options?: any) {
            return SellingRatesApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * StatsApi - fetch parameter creator
 */
export const StatsApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    getStatsPrice(params: {  "model"?: RequestStatsPriceModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Stats/GetStatsPrice`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * StatsApi - functional programming interface
 */
export const StatsApiFp = {
    /**
     *
     * @param model
     */
    getStatsPrice(params: { "model"?: RequestStatsPriceModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatsPriceModel> {
        const fetchArgs = StatsApiFetchParamCreator.getStatsPrice(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * StatsApi - object-oriented interface
 */
export class StatsApi extends BaseAPI {
    /**
     *
     * @param model
     */
    getStatsPrice(params: {  "model"?: RequestStatsPriceModel; }, options?: any) {
        return StatsApiFp.getStatsPrice(params, options)(this.fetch, this.basePath);
    }
};

/**
 * StatsApi - factory interface
 */
export const StatsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        getStatsPrice(params: {  "model"?: RequestStatsPriceModel; }, options?: any) {
            return StatsApiFp.getStatsPrice(params, options)(fetch, basePath);
        },
    };
};


/**
 * TutorialApi - fetch parameter creator
 */
export const TutorialApiFetchParamCreator = {
    /**
     *
     */
    tutorials(options?: any): FetchArgs {
        const baseUrl = `/api/Tutorial/Tutorials`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @param name
     * @param status
     */
    updateTutorial(params: {  "name"?: string; "status"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Tutorial/UpdateTutorial`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "name": params["name"],
            "status": params["status"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TutorialApi - functional programming interface
 */
export const TutorialApiFp = {
    /**
     *
     */
    tutorials(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TutorialModel>> {
        const fetchArgs = TutorialApiFetchParamCreator.tutorials(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @param name
     * @param status
     */
    updateTutorial(params: { "name"?: string; "status"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TutorialModel> {
        const fetchArgs = TutorialApiFetchParamCreator.updateTutorial(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TutorialApi - object-oriented interface
 */
export class TutorialApi extends BaseAPI {
    /**
     *
     */
    tutorials(options?: any) {
        return TutorialApiFp.tutorials(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param name
     * @param status
     */
    updateTutorial(params: {  "name"?: string; "status"?: string; }, options?: any) {
        return TutorialApiFp.updateTutorial(params, options)(this.fetch, this.basePath);
    }
};

/**
 * TutorialApi - factory interface
 */
export const TutorialApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         */
        tutorials(options?: any) {
            return TutorialApiFp.tutorials(options)(fetch, basePath);
        },
        /**
         *
         * @param name
         * @param status
         */
        updateTutorial(params: {  "name"?: string; "status"?: string; }, options?: any) {
            return TutorialApiFp.updateTutorial(params, options)(fetch, basePath);
        },
    };
};




/**
 * UsersApi - fetch parameter creator
 */
export const UsersApiFetchParamCreator = {

    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/Users/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
 *
 * @param id
 */
    deactivate(params: { "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Users/Deactivate`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
   
  
};

/**
 * UsersApiFp - functional programming interface
 */
export const UsersApiFp = {
   
    /**
     *
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ClientModel>> {
        const fetchArgs = UsersApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
 *
 * @param id
 */
    deactivate(params: { "id"?: number; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientModel> {
        const fetchArgs = UsersApiFetchParamCreator.deactivate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {    
                    return  response.json();
                } else {
                    throw response;
                }
            });
        };
    },



};

/**
 * UsersApi - object-oriented interface
 */
export class UsersApi extends BaseAPI {
  
    /**
     *
     */
    getEntities(options?: any) {
        return UsersApiFp.getEntities(options)(this.fetch, this.basePath);
    }

    /**
 *
 * @param id
 */
    disable(params: { "id"?: number; }, options?: any) {
        return UsersApiFp.deactivate(params, options)(this.fetch, this.basePath);
    }
};

/**
 * UsersApi - factory interface
 */
export const UsersApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
     
        /**
         *
         */
        getEntities(options?: any) {
            return UsersApiFp.getEntities(options)(fetch, basePath);
        },
        /**
 *
 * @param id
 */
        deactivate(params: { "id"?: number; }, options?: any) {
            return UsersApiFp.deactivate(params, options)(fetch, basePath);
        },
 
    };

};



/**
 * WorkdocumentsApi - fetch parameter creator
 */
export const WorkdocumentsUploadApiFetchParamCreator = {

    searchWorkDocuments(params: { "model"?: SearchWorkDocumentsModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/WorkdocumentsUpload/SearchWorkDocuments`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
 *
 * @param id
 */
    deactivate(params: { "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/WorkdocumentsUpload/Deactivate`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
            "value": params["value"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
 *
 * @param workDocumentsId
 * @param value
 */
    downloadTemplate(params: { "model"?: WorkDocumentsClientUploadModel; }, options?: any): FetchArgs {
      
        const baseUrl = `/api/WorkdocumentsUpload/DownloadTemplate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },

};

/**
 * UsersApiFp - functional programming interface
 */
export const WorkdocumentsUploadApiFp = {

    /**
     *
     */
    searchWorkDocuments(params: { "model"?: SearchWorkDocumentsModel; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkDocumentsClientUploadDisplayModel>> {
        const fetchArgs = WorkdocumentsUploadApiFetchParamCreator.searchWorkDocuments(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
 *
 * @param id
 */
    deactivate(params: { "id"?: number; "value": boolean }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = WorkdocumentsUploadApiFetchParamCreator.deactivate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },

    /**
 *
 * @param workDocumentsId
 * @param value
 */
    downloadTemplate(params: { "model"?: WorkDocumentsClientUploadModel; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = WorkdocumentsUploadApiFetchParamCreator.downloadTemplate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },

};

/**
 * WorkDocumentsUpload - object-oriented interface
 */
export class WorkDocumentsUploadApi extends BaseAPI {

    /**
     *
     */
    searchWorkDocuments(params: { "model"?: SearchWorkDocumentsModel; }, options?: any) {
        return WorkdocumentsUploadApiFp.searchWorkDocuments(params, options)(this.fetch, this.basePath);
    }	

    /**
 *
 * 
* @param workDocumentsId
* @param value
*/
    deactivate(params: { "id"?: number; "value":boolean }, options?: any) {
        return WorkdocumentsUploadApiFp.deactivate(params, options)(this.fetch, this.basePath);
    }

    /**
*
* @param workDocumentsId
* @param value
*/
    downloadTemplate(params: { "model"?: WorkDocumentsClientUploadModel; }, options?: any) {
        return WorkdocumentsUploadApiFp.downloadTemplate(params, options)(fetch, this.basePath);
    }

};




/**
 * WorkDocumentsUpload - factory interface
 */
export const WorkDocumentsUploadApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {

        /**
         *
         */

        searchWorkDocuments(params: { "model"?: SearchWorkDocumentsModel; }, options?: any) {
            return WorkdocumentsUploadApiFp.searchWorkDocuments(params, options)(this.fetch, this.basePath);
        },	
        /**
 *
 * @param id
 */
        deactivate(params: { "id"?: number; "value": boolean }, options?: any) {
            return WorkdocumentsUploadApiFp.deactivate(params, options)(fetch, basePath);
        },


        /**
 *
 * @param workDocumentsId
 * @param value
 */
        downloadTemplate(params: { "model"?: WorkDocumentsClientUploadModel; }, options?: any) {
            return WorkdocumentsUploadApiFp.downloadTemplate(params, options)(fetch, basePath);
        }

    };

}
