import React, {useState} from "react";
import {RatesExtractionModel} from "../api/rtkApi";
import {getLocationName} from "../utils/utils";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {carriersSelector} from "../slices/ratesExtraction";
import OkDatePicker from "./DatePicker";
import Moment from "moment";
import {Warning} from "@material-ui/icons";
import Button from "./Button";
import * as Notifications from "react-notification-system-redux";

export function RatesExtractionDownload({ ratesExtraction, onDownload  }
                                        : {
    ratesExtraction: RatesExtractionModel;
    onDownload: (startDate?: Date, endDate?: Date) => Promise<any>;
}) {

    const carriers = useAppSelector(carriersSelector);
    const [startDate, updateStartDate] = useState(Moment().startOf("day").toDate());
    const [endDate, updateEndDate] = useState(Moment().startOf("day").add(1, "month").toDate());
    const dispatch = useAppDispatch();
    
    return (<div style={{
        display: "grid",
        gridTemplateRows: "auto auto auto auto",
        gridTemplateColumns: "auto 1fr auto",
        gap: "calc(0.5vw + 5px)",
    }}>
        <div style={{gridRow: "1 / 2", gridColumn: "1 / 2"}}>
            Name:
        </div>
        <div style={{gridRow: "1 / 2", gridColumn: "2 / 4"}}>
            {ratesExtraction.name}
        </div>

        <div style={{gridRow: "2 / 3", gridColumn: "1 / 2"}}>
            POLs:
        </div>
        <div style={{
            gridRow: "2 / 3",
            gridColumn: "2 / 4",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "calc(0.3vw + 3px)"
        }}>
            {ratesExtraction.ratesExtractionPols
                .map(x => <div key={x.portId}>
                    {getLocationName(x.port)}
                </div>)}
        </div>

        <div style={{gridRow: "3 / 4", gridColumn: "1 / 2"}}>
            PODs:
        </div>
        <div style={{
            gridRow: "3 / 4",
            gridColumn: "2 / 4",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "calc(0.3vw + 3px)"
        }}>
            {ratesExtraction.ratesExtractionPods
                .map(x => <div key={x.portId}>
                    {getLocationName(x.port)}
                </div>)}
        </div>

        <div style={{gridRow: "4 / 5", gridColumn: "1 / 2"}}>
            Carriers:
        </div>
        <div style={{
            gridRow: "4 / 5",
            gridColumn: "2 / 4",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "calc(0.3vw + 3px)"
        }}>
            {ratesExtraction.ratesExtractionCarriers
                .map(x => <div key={x.carrierId}>
                    {carriers[x.carrierId].name}
                </div>)}
        </div>

        <div style={{gridRow: "5 / 6", gridColumn: "1 / 2"}}>
            Container type:
        </div>
        <div style={{
            gridRow: "5 / 6",
            gridColumn: "2 / 4",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "calc(0.3vw + 3px)"
        }}>
            {ratesExtraction.containerType}
        </div>

        <div style={{gridRow: "6 / 7", gridColumn: "1 / 2"}}>
        </div>
        <div style={{
            gridRow: "6 / 7",
            gridColumn: "2 / 4",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
        }}>
            {ratesExtraction.ratesSourceFlag.some(x => x === "Ocq") 
                && startDate < Moment(new Date()).add(-1, "days").toDate()
                && <React.Fragment><Warning/> Looking for past date will not include OCQs</React.Fragment>}
        </div>

        <div style={{gridRow: "7 / 8", gridColumn: "1 / 2"}}>
            Start validity date:
        </div>
        <div style={{
            gridRow: "7 / 8",
            gridColumn: "2 / 4",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "calc(0.3vw + 3px)"
        }}>
            <OkDatePicker value={startDate}
                          minDate={Moment(new Date()).startOf("date").add(-2, "years").toDate()}
                          maxDate={Moment(new Date()).startOf("date").add(0, "months").toDate()}
                          onChange={(v) => updateStartDate(v)}/>
        </div>

        <div style={{gridRow: "8 / 9", gridColumn: "1 / 2"}}>
            End validity date:
        </div>
        <div style={{
            gridRow: "8 / 9",
            gridColumn: "2 / 4",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "calc(0.3vw + 3px)"
        }}>
            <OkDatePicker value={endDate}
                          minDate={Moment(new Date()).startOf("date").add(-2, "years").toDate()}
                          maxDate={Moment(new Date()).startOf("date").add(6, "months").toDate()}
                          onChange={(v) => updateEndDate(v)}/>
        </div>

        <div style={{gridRow: "9 / 10", gridColumn: "1 / 2"}}>
        </div>
        <div style={{
            gridRow: "9 / 10",
            gridColumn: "3 / 4"
        }}>
            <Button type={"button"} onClick={() => onDownload(startDate, endDate)
                .then(x => {
                    if(x && x.error){
                        dispatch(Notifications.error({ message: "Error generating file", title: "Error", position: "tc" }) as any);
                    } else {
                        dispatch(Notifications.success({ message: "The generation request has been submitted", title: "Success", position: "tc" }) as any);
                    }
                })}>Send by email</Button>
        </div>
    </div>);
}